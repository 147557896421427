import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {get} from 'lodash';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzTreeNodeOptions} from 'ng-zorro-antd/tree';
import {Observable, Subscription} from 'rxjs';
import {LocationTypeHttpService} from 'src/app/locations/services/location-type-http.service';
import {LocationHttpService} from '../../../../locations/services/location-http.service';
import {LocationTypeService} from '../../../../locations/services/location-type.service';

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss']
})
export class LocationSelectionComponent implements OnInit, OnDestroy {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();

  public answerFormControl: FormControl;

  public isLoading: boolean = true;

  public locationOptions: NzTreeNodeOptions[];

  public subscriptions: Subscription[] = [];

  public constructor(
    private locationHttpService: LocationHttpService,
    private messageService: NzMessageService,
  ) {
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public ngOnInit(): void {
    this.initializeFormControl();
    this.monitorFormControl();
    this.loadComponentOptions();
  }

  private loadComponentOptions(): void {
    this.isLoading = true;
    this
      .loadTypes()
      .subscribe(
        (types) => {
          this.locationOptions = this.convertEntityToTreeNodes(types);
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.messageService.error('Unable to load location selection question data, please try again later');
          this.isLoading = false;
        },
      );
  }

  private loadTypes(): Observable<ILocationType[]> {
    const allowedLocationTypes: string[] = get(
      this.managedQuestion,
      'question.configuration.locationTypesAllowed',
      [],
    );
    const requiredTags: string[] = get(
      this.managedQuestion,
      'question.configuration.locationSelectionTagIds',
      [],
    );
    return this
      .locationHttpService
      .loadHierarchies({
        injectTags: !!requiredTags.length ? 'true' : 'false',
        ...(allowedLocationTypes.length ? {
          typeIds: allowedLocationTypes.join(',')
        } : {})
      });
  }

  private convertEntityToTreeNodes(
    entities: ILocationType[] | ILocation[],
  ): NzTreeNodeOptions[] {
    // @ts-ignore
    return entities.map(entity => ({
      key: entity.id,
      title: entity.name,
      ...(!!entity.children && !!entity.children.length ? {
        children: this.convertEntityToTreeNodes(entity.children)
      } : {
        isLeaf: true
      }),
      disabled: this.isEntityDisabled(entity),
    }));
  }

  private isEntityDisabled(entity: ILocationType[] | ILocation[]): boolean {
    // @ts-ignore
    const entityIsType = !entity.locationTypeId;

    const requiredTags = get(
      this.managedQuestion,
      'question.configuration.locationSelectionTagIds',
      [],
    );
    const configurationHasMandatoryTags = requiredTags.length;
    // @ts-ignore
    const entityHasSomeMandatoryTags = entity.tags && entity.tags.some(
      tag => requiredTags.some(tagId => tag.id === tagId)
    );

    return entityIsType || (configurationHasMandatoryTags && !entityHasSomeMandatoryTags);
  }

  private initializeFormControl(): void {
    this.answerFormControl = new FormControl();

    if (this.managedQuestion.question.configuration.isRequired) {
      this.answerFormControl.setValidators([Validators.required]);
    }

    if (this.managedQuestion.answers && this.managedQuestion.answers.length) {
      if (this.managedQuestion.question.configuration.allowMultipleAnswers) {
        this.answerFormControl.setValue(this.managedQuestion.answers.map(answer => answer.locationIdAnswer));
      } else {
        this.answerFormControl.setValue(this.managedQuestion.answers[0].locationIdAnswer);
      }
    }
  }

  private monitorFormControl(): void {
    this.subscriptions.push(
      this
        .answerFormControl
        .valueChanges
        .subscribe(answer => {
          if (Array.isArray(answer)) {
            const question = this.managedQuestion.question;

            this.managedQuestion.answers = answer.length ? answer.map(locationId => ({
              questionId: question.id,
              questionType: question.type,
              questionPublicId: question.publicId,
              sectionPublicId: question.section.publicId,
              sectionId: question.sectionId,
              locationIdAnswer: locationId,
            })) : [];
          } else {
            const question = this.managedQuestion.question;

            this.managedQuestion.answers = !!answer ? [{
              questionId: question.id,
              questionType: question.type,
              questionPublicId: question.publicId,
              sectionPublicId: question.section.publicId,
              sectionId: question.sectionId,
              locationIdAnswer: answer,
            }] : [];
          }

          this.valueChange.emit(answer);
        })
    );
  }

}
