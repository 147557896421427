import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormVersionAnswerSheetMappingService {
  public mapAnswerSheetDto(formVersionAnswerSheet: IFormVersionAnswerSheet): IFormVersionAnswerSheet {
    if (!formVersionAnswerSheet) {
      return undefined;
    }

    return {
      id: formVersionAnswerSheet.id,
      formVersionId: formVersionAnswerSheet.formVersionId,
      itemFieldsAnswerSheets: formVersionAnswerSheet.itemFieldsAnswerSheets?.map(ifas => ({
        ...(!!ifas.id ? {id: ifas.id} : {}),
        formVersionAnswerSheetId: ifas.formVersionAnswerSheetId,
        sectionId: ifas.sectionId,
        itemId: ifas.itemId,
        itemName: ifas.itemName,
        itemBarCode: ifas.itemBarCode,
        itemSerialNumber: ifas.itemSerialNumber,
        itemUnitId: ifas.itemUnitId,
        unitId: ifas.unitId,
        unitLabel: ifas.unitLabel,
        itemUnitCode: ifas.itemUnitCode,
        itemUnitQuantity: ifas.itemUnitQuantity,
        itemUnitCost: ifas.itemUnitCost,
        itemUnitPrice: ifas.itemUnitPrice,
        locationId: ifas.locationId,
        locationName: ifas.locationName,
        locationCode: ifas.locationCode,
      })) ?? [],
      questionAnswers: formVersionAnswerSheet.questionAnswers?.map(qa => ({
        formVersionAnswerSheetId: qa.formVersionAnswerSheetId ?? formVersionAnswerSheet.id,
        ...(!!qa.id ? {id: qa.id} : {}),
        questionId: qa.questionId,
        attachmentIdAnswer: qa.attachmentIdAnswer,
        booleanAnswer: qa.booleanAnswer,
        durationAnswer: qa.durationAnswer,
        latitudeAnswer: qa.latitudeAnswer,
        longitudeAnswer: qa.longitudeAnswer,
        numericAnswer: qa.numericAnswer,
        quantityAnswer: qa.quantityAnswer,
        questionOptionIdAnswer: qa.questionOptionIdAnswer,
        signatureIdAnswer: qa.signatureIdAnswer,
        textAnswer: qa.textAnswer,
        timestampAnswer: qa.timestampAnswer,
        timestampAnswerTimezone: qa.timestampAnswerTimezone,
        locationIdAnswer: qa.locationIdAnswer,
        itemAnswerSnapshot: qa.itemAnswerSnapshot,
        itemIdAnswer: qa.itemIdAnswer,
        locationAnswerSnapshot: qa.locationAnswerSnapshot,
        timerIdAnswer: qa.timerIdAnswer,
        timerAnswer: qa.timerAnswer,
        apiCallAnswer: qa.apiCallAnswer,
        barcode: qa.barcode,
        qRCodeValue: qa.qRCodeValue,
        paymentTransactionId: qa.paymentTransactionId,
        capturedAt: qa.capturedAt,
      })) ?? [],
    };
  }
}
