import {QueryFilter} from '@nestjsx/crud-request/lib/types';
import {cloneDeep} from 'lodash';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import {BehaviorSubject} from 'rxjs';
import {IsNullOrUndefined} from '../../core/helpers/type.helpers';
import {FsCascaderServiceInterface} from './fs-cascader.service.interface';

export abstract class FsCascaderAbstractService<T, K> implements FsCascaderServiceInterface<T, K> {
  public options: BehaviorSubject<NzCascaderOption[]> = new BehaviorSubject<NzCascaderOption[]>([]);
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected _optionsBackup: BehaviorSubject<NzCascaderOption[]> = new BehaviorSubject<NzCascaderOption[]>([]);
  protected _showingSearchResults: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected entityTypeFilters: QueryFilter[] = [];
  protected entityFilters: QueryFilter[] = [];

  protected constructor(protected _parentIdSelector: string) {
  }

  public addEntityTypeFilter(filter: {field: keyof T | string} & QueryFilter) {
    if (filter) {
      this.entityTypeFilters.push(filter);
    }
  }

  public removeEntityTypeFilter(field: keyof T | string) {
    const existingFilterIndex = this.entityTypeFilters.findIndex(f => f.field === field);

    if (existingFilterIndex > -1) {
      this.entityTypeFilters.splice(existingFilterIndex, 1);
    }
  }

  public addEntityFilter(filter: {field: keyof K | string} & QueryFilter) {
    if (filter) {
      this.entityFilters.push(filter);
    }
  }

  public removeEntityFilter(field: keyof K | string) {
    const existingFilterIndex = this.entityFilters.findIndex(f => f.field === field);

    if (existingFilterIndex > -1) {
      this.entityFilters.splice(existingFilterIndex, 1);
    }
  }

  public getOptionPath(optionId: string, options: NzCascaderOption[]): string[] {
    const optionPath = this.recursivelyGetOptionMatchingId(options, optionId);

    if (optionId && !optionPath) {
      throw new Error(`Failed to find the correct option matching ID: ${optionId}.`);
    }

    return optionPath.map(i => i.value);
  }

  public convertHierarchicalModelArrayToCascaderOptions(
    hierarchicalItems: any[],
    config?: {
      isDisabled?: boolean,
      isLeafOverride?: boolean
    }
  ): NzCascaderOption[] {
    return hierarchicalItems.map(hierarchicalItem => {
      const clonedData = cloneDeep(hierarchicalItem);
      const isLeafNode = !hierarchicalItem.statistics
        || hierarchicalItem.statistics.childCount === 0;
      const isDisabled = !hierarchicalItem[this._parentIdSelector]
        && (hierarchicalItem.statistics
          && hierarchicalItem.statistics.childCount === 0);

      if (clonedData.statistics) {
        delete clonedData.statistics;
      }

      return {
        value: hierarchicalItem.id,
        label: hierarchicalItem.isActive ? hierarchicalItem.name : hierarchicalItem.name + ' (Archived)',
        isLeaf: !config || IsNullOrUndefined(config.isLeafOverride)
          ? isLeafNode
          : config.isLeafOverride,
        loading: false,
        disabled: !config || IsNullOrUndefined(config.isDisabled)
          ? isDisabled
          : config.isDisabled,
        data: clonedData,
        children: isLeafNode ? null : []
      };
    });
  }

  public backupLocationTypeOptions() {
    this._optionsBackup.next(this.options.getValue());
  }

  public replaceLocationTypeOptions(options: NzCascaderOption[]) {
    this.options.next(options);
  }

  public restoreRootOptionsBackup(
    patchList: boolean = false,
    patchValue: NzCascaderOption | NzCascaderOption[] = []
  ) {
    const options = this._optionsBackup.getValue();

    if (patchList) {
      if (!Array.isArray(patchValue)) {
        const matchingItem = options.find(o => o.value === patchValue.value);

        if (matchingItem) {
          Object.assign(matchingItem, patchValue);
        }
      } else {
        patchValue.forEach(option => {
          const matchingItem = options.find(o => o.value === option.value);

          if (matchingItem) {
            Object.assign(matchingItem, option);
          }
        });
      }

      // Backup the patched list (cached)
      this._optionsBackup.next(options);
    }

    this.options.next(options);
  }

  protected recursivelyGetOptionMatchingId(
    options: NzCascaderOption[] = [],
    optionId: string,
    output: NzCascaderOption[] = [],
    parentId?: string
  ): NzCascaderOption[] {
    options.forEach((option: NzCascaderOption) => {
      if (option.value === optionId) {
        output = [{
          label: option.label,
          value: option.value,
          parentId
        }];
      } else {
        if (option.children && option.children.length) {
          const childOutput = this.recursivelyGetOptionMatchingId(
            option.children,
            optionId,
            output,
            option.value
          );
          const hasItemFromThisLevel = childOutput.findIndex(c => c.parentId === option.value) > -1;

          if (childOutput && childOutput.length && hasItemFromThisLevel) {
            output = [
              {
                label: option.label,
                value: option.value,
                parentId
              },
              ...childOutput
            ];
          }
        }
      }
    });

    return output;
  }


  public setHierarchy(option: NzCascaderOption, isDisabled?: (option: NzCascaderOption) => boolean): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      const parentId = option.data[this._parentIdSelector];
      const optionsBackup = this._optionsBackup.getValue();
      const matchingParent = optionsBackup.find(o => o.value === parentId);
      const returnResult = [parentId, option.value];

      if (matchingParent) {
        if (matchingParent.children) {
          const existingItem = matchingParent.children.findIndex(i => i.value === option.value);

          if (existingItem > -1) {
            this.restoreRootOptionsBackup(true, matchingParent);

            resolve(returnResult);

            return;
          }
        }

        matchingParent.children = [
          ...(matchingParent.children || []),
          option
        ];

        this.restoreRootOptionsBackup(true, matchingParent);
      }

      resolve(returnResult);
    });
  }
}
