import {EventEmitter} from '@angular/core';
import {FormAnswerSheetStateService} from 'src/app/shared/answer-sheet/services/form-answer-sheet-state.service';

export class StateManagedQuestion implements IStateManagedQuestion {
  public isDisplayed: boolean = true;
  public answerUpdated: EventEmitter<any> = new EventEmitter<any>();
  public forceUpdate: EventEmitter<any> = new EventEmitter<any>();
  public questionUpdated: EventEmitter<any> = new EventEmitter<any>();
  public formVersionAnswerSheetId: string;
  public formVersionAnswerSheet: IFormVersionAnswerSheet;

  public constructor(
    public readonly question: IQuestion
  ) {
  }

  private _answers: IQuestionAnswer[] = [];

  public get answers(): IQuestionAnswer[] {
    return this._answers;
  }

  public set answers(answers: IQuestionAnswer[]) {
    this._answers = answers;

    this.answerUpdated.emit(answers);
  }
}
