import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {TenantStateService} from './tenant-state.service';

@Injectable()
export class TenantHeaderInjectorInterceptor implements HttpInterceptor {
  constructor(
    private tenantStorageService: TenantStateService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isSystemOnPublicRoute = window.location.pathname.startsWith('/public');

    const httpRequestUrl = request.urlWithParams;
    const isApiCall = httpRequestUrl.indexOf(environment.fieldServicesAPI) > -1;
    const isApiCallToFetchAvailableTenants = isApiCall && httpRequestUrl.indexOf('users/available-tenants') > -1;

    const injectTenantHeader =
      isApiCall
      && !isApiCallToFetchAvailableTenants
      && !isSystemOnPublicRoute;

    return injectTenantHeader ? this
      .tenantStorageService
      .tenantAsObservable
      .pipe(
        filter(tenant => !!tenant),
        take(1),
        switchMap((tenant: ITenant) => {
          request = request.clone({
            headers: request.headers.set('x-tenant-id', tenant.id)
          });
          return next.handle(request);
        }),
      ) : next.handle(request);
  }
}
