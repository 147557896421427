import {HttpClient, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {CreateQueryParams} from '@nestjsx/crud-request';
import {Observable} from 'rxjs';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerHttpService extends BaseCrudService<ICustomer> {

  constructor(
    public http: HttpClient,
  ) {
    super(http, 'customers');
  }

}
