import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent {

  @Input()
  public isVisible: boolean;

  @Input()
  public isAuthenticating: boolean;

  @Output()
  public handleAuthentication: EventEmitter<string> = new EventEmitter();

  public passwordFormControl: FormControl = new FormControl();

}
