import {isNullOrUndefined} from './truthy.helper';

export function isNotEmpty(list: any[]): boolean {
    return !!list && list.length > 0;
}

export function isEmpty(list: any[]): boolean {
    return !isNotEmpty(list);
}

export const sortByValue = (a: any, b: any) => {
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const naturalSortList = <T extends any>(list: any[] = [], direction: 'asc' | 'desc' = 'asc', property: keyof T) => {
  const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

  list.sort((a, b) => {
    const regulatedValueForA = property ? isNullOrUndefined(a[property]) ? '' : a[property] : isNullOrUndefined(a) ? '' : a;
    const regulatedValueForB = property ? isNullOrUndefined(b[property]) ? '' : b[property] : isNullOrUndefined(b) ? '' : b;

    if (property) {
      if (direction === 'asc') {
        return collator.compare(regulatedValueForA, regulatedValueForB);
      } else {
        return collator.compare(regulatedValueForB, regulatedValueForA);
      }
    } else {
      if (direction === 'asc') {
        return collator.compare(regulatedValueForA, regulatedValueForB);
      } else {
        return collator.compare(regulatedValueForB, regulatedValueForA);
      }
    }
  });
};

export function groupBy<T, Value>(
  array: T[],
  keySelector: (item: T) => string,
  valueSelector: (item: T) => Value | Value[]
): {[key: string]: Value[]} {
  return array.reduce((result: {[key: string]: Value[]}, item: T) => {
    const key = keySelector(item);
    const value = valueSelector(item);

    if (typeof key !== 'string') {
      throw new Error('Could not map the group from the array since the keySelector did not produce a valid string result.');
    }

    if (!result[key]) {
      result[key] = [];
    }

    if (Array.isArray(value)) {
      result[key] = result[key].concat(value);
    } else {
      result[key].push(value);
    }

    return result;
  }, {});
}
