<nz-card [nzBordered]="false" class="container-card">
  <nz-form-item [ngClass]="{'reduced-margin': !!selectedLocation}" nz-row>
    <nz-form-label [nzRequired]="configuration.isRequired" nzSpan="24"> Location </nz-form-label>

    <nz-form-control
      nz-col
      nzSpan="24">
      <app-fs-cascader
        #locationSelector
        inputId="public-survey__location"
        placeholder="Select a Location"
        [(model)]="selectedLocation"
        [isLoading]="locationCascaderService.isLoading"
        [options]="locationCascaderService.options"
        (cascadeLoadData)="locationCascaderService.loadLocationsForParentOption($event)"
        (modelChange)="handleLocationUpdate($event)"
        (searchUpdate)="locationCascaderService.searchLocationTypeOptions($event)"
        (searchItemSelected)="setHierarchyForLocation($event)"
        (clear)="locationCascaderService.restoreRootOptionsBackup()">
      </app-fs-cascader>

      <div class="error form-control-msg" *ngIf="locationSelector.dirty && locationSelector.hasRequiredError">
        Location is required
      </div>

      <div class="error form-control-msg" *ngIf="selectedLocation && selectedLocation.length === 1 && !selectedLocation[0].isLeaf">
        A Location Type cannot be selected, select a Location
      </div>
    </nz-form-control>
  </nz-form-item>
</nz-card>
