import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CondOperator, CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  private urlBase: string = `${environment.fieldServicesAPI}/users`;

  public constructor(
    private http: HttpClient,
  ) {
  }

  public fetchUsers(query?: CreateQueryParams): Observable<IPagination<IUser>> {
    let url = this.urlBase;
    if (query) {
      url += `?${RequestQueryBuilder.create(query).query()}`;
    }
    return this
      .http
      .get<IPagination<IUser>>(url);
  }

  public getUser(id: string): Observable<IUser> {
    let url = this.urlBase;
    if (!id) {
      throw new Error('Could not complete the request to GET user without a valid User ID.');
    }

    const query = {
      filter: {
        field: 'id',
        operator: CondOperator.EQUALS,
        value: id
      }
    };

    url += `?${RequestQueryBuilder.create(query).query()}`;

    return this
      .http
      .get<IPagination<IUser>>(url)
      .pipe(
        map((users: IPagination<IUser>) => (users.data && users.data.length) > 0 ? users.data[0] : null)
      );
  }

  public createUser(user: IUser): Observable<IUser> {
    const url = this.urlBase;
    return this
      .http
      .post<IUser>(
        url,
        user
      );
  }

  public updateUser(user: IUser): Observable<IUser> {
    const url = `${this.urlBase}/${user.id}`;
    return this
      .http
      .patch<IUser>(
        url,
        user
      );
  }

  public getEmailStatus(email: string): Observable<IEmailStatus> {
    const url = `${this.urlBase}/email-status/${email}`;
    return this
      .http
      .get<IEmailStatus>(url);
  }

  public getCodeAvailability(code: string): Observable<IUserCodeAvailability> {
    const url = `${this.urlBase}/code-availability/${code}`;
    return this
      .http
      .get<IUserCodeAvailability>(url);
  }

  public inviteUserToTenant(user: IUser): Observable<IUser> {
    const url = `${this.urlBase}/invite-to-organization`;
    return this
      .http
      .patch<IUser>(url, user);
  }

  public getUserPersonalData(): Observable<IUser> {
    const url = `${this.urlBase}/self`;
    return this.http.get<IUser>(url);
  }

  public resetUserPassword(user: IResetUserPassword): Observable<IResetUserPassword> {
    const url = `${this.urlBase}/reset-user-password`;
    return this.http.post<IResetUserPassword>(url, user);
  }

}
