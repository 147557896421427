import {Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {NzTabChangeEvent, NzTabComponent, NzTabSetComponent} from 'ng-zorro-antd/tabs';
import {FormAnswerSheetStateService} from 'src/app/shared/answer-sheet/services/form-answer-sheet-state.service';
import {IItemSelectionConfig} from './item-fields/services/item-fields-state.interface';
import {TransferSummaryStateService} from './transfer-summary/transfer-summary-state.service';

@Component({
  selector: 'app-answer-sheet',
  templateUrl: './answer-sheet.component.html',
  styleUrls: ['./answer-sheet.component.scss']
})
export class AnswerSheetComponent implements OnInit, OnChanges {
  // Input Params
  @Input()
  public formVersionAnswerSheet: IFormVersionAnswerSheet;

  @Input()
  public formVersion: IFormVersion;

  @Input()
  public excludeSummaryItems: boolean = false;

  @Input()
  public showNavigationButtons: boolean = true;

  @Output()
  public questionAnswered: EventEmitter<IQuestionAnswer[]>;

  @Output()
  public itemFieldsAnswered: EventEmitter<IItemSelectionConfig> = new EventEmitter<IItemSelectionConfig>();

  // Output Events
  @Output()
  public answerSheetUpdated: EventEmitter<IFormVersionAnswerSheet>;

  @Output()
  public answerSheetProgressUpdated: EventEmitter<IFormAnswerSheetProgress>;

  @Output()
  public emptyFormWasLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public formVersionLoaded: EventEmitter<IFormVersion> = new EventEmitter<IFormVersion>();

  @Output()
  public tabsetCreated: EventEmitter<NzTabSetComponent> = new EventEmitter<NzTabSetComponent>();

  @Output()
  public sectionChanged: EventEmitter<NzTabComponent> = new EventEmitter<NzTabComponent>();

  @Output()
  public answerSheetLoaded: EventEmitter<IFormVersionAnswerSheet> = new EventEmitter<IFormVersionAnswerSheet>();

  @ViewChild(NzTabSetComponent)
  public tabset: NzTabSetComponent;

  @ViewChildren(NzTabComponent)
  public tabs: QueryList<NzTabComponent>;

  public SectionType = {
    Form: 'form_field',
    ItemFields: 'item_fields',
    Summary: 'summary',
    TransferSummary: 'transfer_summary'
  };

  public constructor(
    private transferSummaryStateService: TransferSummaryStateService,
    public state: FormAnswerSheetStateService
  ) {
    this.setupEventEmitters();
  }

  // Page events
  public async ngOnInit(): Promise<void> {
    await this.fetchManagedForm();
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.formVersionId
      && changes.formVersionId.previousValue !== changes.formVersionId.currentValue) {
      await this.fetchManagedForm();
    }
  }

  // Public methods
  public navigateToTab(index: number, event: MouseEvent) {
    const tabs = this.tabs.toArray();
    const tab = tabs[index];

    this.tabset.clickNavItem(tab, index, event);

    this.sectionChanged.emit(tab);
  }

  public handleSectionTabChange(event: NzTabChangeEvent) {
    this.sectionChanged.emit(event.tab);

    this.state.activeSectionTab.next(event.tab);
  }

  // Private methods
  private setupEventEmitters(): void {
    this.questionAnswered = this.state.questionAnswered;
    this.answerSheetUpdated = this.state.answerSheetUpdated;
    this.answerSheetProgressUpdated = this.state.answerSheetProgressUpdated;
    this.sectionChanged = this.state.sectionChanged;
  }

  private async fetchManagedForm(): Promise<void> {
    requestAnimationFrame(() => {
      this.state.isLoading = true;
    });

    if (!this.formVersionAnswerSheet) {
      throw new Error('No form version answer sheet was provided to the answer sheet component. Please provide an existing answer sheet or create a new one with FormVersionAnswerSheetFactory.');
    }

    if (!this.formVersion) {
      throw new Error('No form version was provided to the answer sheet component. Please provide a valid form version.');
    }

    await this.state.initializeService(
      this.formVersion,
      this.formVersionAnswerSheet
    );

    if (this.state.currentFormVersion) {
      if (this.state.currentFormVersion.form.stockTransferTypeForm) {
        this.transferSummaryStateService.updateState({
          includeBalance: this.state.currentFormVersion.form.stockTransferTypeForm.includeBalance
        });
      }

      requestAnimationFrame(() => {
        this.state.isLoading = false;
      });

      requestAnimationFrame(() => {
        if (this.tabset) {
          this.tabsetCreated.emit(this.tabset);
        }
      });
    } else {
      this.state.isLoading = false;
    }

    this.notifyIfEmptyForm();
  }

  private notifyIfEmptyForm(): void {
    const isEmptyForm =
      !this.state.managedForm.sections.length
      || this.state.managedForm.sections.every(section => !section.questions.length);

    this.emptyFormWasLoaded.emit(isEmptyForm);
  }

}
