import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-gps',
  templateUrl: './gps.component.html',
  styleUrls: ['./gps.component.scss']
})
export class GpsComponent implements OnInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<GeolocationPosition> = new EventEmitter<GeolocationPosition>();

  public answer: FormControl;

  public constructor(
    private messageService: NzMessageService,
  ) {
  }

  public ngOnInit(): void {
    this.answer = new FormControl(
      !!this.managedQuestion.answers.length ?
        `${this.managedQuestion.answers[0].latitudeAnswer}, ${this.managedQuestion.answers[0].longitudeAnswer}` : '',
      this.managedQuestion.question.configuration.isRequired ? [Validators.required] : [],
    );
  }

  public setAnswerAsCurrentCoordinates(): void {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.answer.setValue(
          `${position.coords.latitude}, ${position.coords.longitude}`
        );

        this.managedQuestion.answers = [{
          questionId: this.managedQuestion.question.id,
          questionPublicId: this.managedQuestion.question.publicId,
          sectionPublicId: this.managedQuestion.question.section.publicId,
          sectionId: this.managedQuestion.question.sectionId,
          questionType: this.managedQuestion.question.type,
          latitudeAnswer: `${position.coords.latitude}`,
          longitudeAnswer: `${position.coords.longitude}`,
        }];

        this.valueChange.emit(position);
      },

      (error) => {
        this.answer.setValue(null);
        this.managedQuestion.answers = [];

        switch (error.code) {
          case error.PERMISSION_DENIED: {
            this.messageService.error('Please enable location access');
            break;
          }
          default: {
            this.messageService.error(
              'The application is not able to access your location, please try a different browser'
            );
            break;
          }
        }
      }
    );
  }

}
