// https://github.com/manfredsteyer/angular-oauth2-oidc/issues/414

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class UnauthorizedRequestRetryInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;

  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) { }

  private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.oauthService.getAccessToken()}`
      }
    });
  }

  private forceImplicitFlow(): void {
    const encodedUri = encodeURIComponent(this.router.url);
    this.oauthService.initImplicitFlow(encodedUri);
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    if (!req.url.startsWith(environment.fieldServicesAPI)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((response: HttpResponse<any>) => {
        if (response && response.status === 401) {
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until token is silently refreshed
            // which means the new token is ready and we can retry the request
            return this.oauthService.events.pipe(
              filter(result => result.type === 'silently_refreshed'),
              take(1),
              switchMap(() => next.handle(this.getAuthRequest(req)))
            );
          } else {
            this.refreshTokenInProgress = true;

            return from(this.oauthService.silentRefresh()).pipe(
              switchMap(() => next.handle(this.getAuthRequest(req))),
              catchError(error => {
                this.forceImplicitFlow();
                return throwError(error);
              }),
              // When the call to silentRefresh completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this.refreshTokenInProgress = false)
            );
          }
        }

        return throwError(response);
      })
    );
  }
}
