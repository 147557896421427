<nz-header class="nz-header">
  <div class="header-container">
    <div class="header-placeholder">
      <ng-content></ng-content>
    </div>
    <div class="wrap-user-details">
      <span id="app__user" class="user">{{ (authenticationService.currentFsUser | async)?.displayName }}</span>
      <span class="tenant">{{ (authenticationService.currentFsUser | async)?.tenant.domain }}</span>
    </div>
    <div class="wrap-user-icon">
        <a nz-dropdown [nzDropdownMenu]="menu">
          <i nz-icon nzType="user" nzTheme="outline"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <button
                nz-button
                nzType="link"
                (click)="handleMyProfileClick()">
                <i nz-icon nzType="user"></i>

                My Profile
              </button>
            </li>
            <li nz-menu-item>
              <button
                nz-button
                nzType="link"
                (click)="handleMyOrganisationSettingsClick()">
                <i nz-icon nzType="setting"></i>

                Organisation Settings
              </button>
            </li>
            <li nz-menu-item *ngIf="showTenantSwitchMenuItem">
              <button
                nz-button
                nzType="link"
                (click)="tenantStorageService.displayTenantSelector()">
                <i nz-icon nzType="team"></i>

                Switch Organisation
              </button>
            </li>
            <li nz-menu-item>
              <button
                nz-button
                nzDanger
                nzType="link"
                (click)="handleLogOutClick()">
                <i nz-icon nzType="logout"></i>

                Logout
              </button>
            </li>
          </ul>
        </nz-dropdown-menu>
    </div>
  </div>
</nz-header>
