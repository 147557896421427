export class TransferSummaryTransaction implements ITransferSummaryTransaction {
  questionAnswerId: string;
  quantity: number;
  location: ILocation;
  createdAt: Date;

  constructor(
    questionAnswerId: string,
    quantity: number = 0,
    location: ILocation = null,
    createdAt: Date = new Date()
  ) {
    this.questionAnswerId = questionAnswerId;
    this.quantity = quantity;
    this.location = location;
    this.createdAt = createdAt;
  }
}
