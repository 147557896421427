import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {AuthenticationEvents} from 'src/app/core/authentication/authentication-events';
import {AuthenticationService} from 'src/app/core/authentication/authentication.service';
import {environment} from 'src/environments/environment';
import {TenantStateService} from './tenant-state.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private tenantStorageService: TenantStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscribeToLogoutEvents();
  }

  public tryInitializingDefaultTenant(): Observable<ITenant | ITenant[]> {
    return this
      .authenticationService
      .currentB2CUserData
      .pipe(
        filter(user => !!user),
        take(1),
        switchMap(() => this.fetchAvailableTenants()),
        take(1),
        tap((tenants: ITenant[]) => {
          const allowedTenants = tenants.filter(t => !!t.isAdminOfficeUser);

          this.tenantStorageService.setAvailableTenants(allowedTenants);

          const storedTenantId = localStorage.getItem('user_tenant_id');
          const userHasAccessToCurrentTenant = allowedTenants.find(tenant => tenant.id === storedTenantId && !!tenant.isAdminOfficeUser);

          if (!this.authenticationService.isPublicUser) {
            if (allowedTenants.length > 1 && userHasAccessToCurrentTenant) {
              this.tenantStorageService.setTenant(allowedTenants.find(tenant => tenant.id === storedTenantId));
            } else if (allowedTenants.length > 1 && !userHasAccessToCurrentTenant) {
              this.tenantStorageService.setShouldDisplayTenantSelector(true);
            } else if (allowedTenants.length === 1) {
              this.tenantStorageService.setTenant(allowedTenants[0]);

              // Only redirect if the current page is the access denied page
              if (this.activatedRoute.snapshot.url.filter(s => s.path.indexOf('access-denied') > -1).length > 0) {
                this.router.navigate(['/']);
              }
            } else if (this.activatedRoute.snapshot.url.filter(s => s.path.indexOf('access-denied') > -1).length === 0) {
              this.authenticationService.activateForbiddenRoute();
            }
          }
        })
      );
  }

  public fetchCurrentTenant(): Observable<ITenant> {
    const url = `${environment.fieldServicesAPI}/tenant/configuration`;
    return this
      .http
      .get<ITenant>(url)
      .pipe(take(1));
  }

  public updateLocationServiceSchedule(configuration: ITenantConfiguration): Observable<void> {
    const url = `${environment.fieldServicesAPI}/tenant/configuration`;
    return this
      .http
      .patch<void>(url, configuration)
      .pipe(take(1));
  }

  public updateTenantLogo(attachmentId: string): Observable<void> {
    const url = `${environment.fieldServicesAPI}/tenant/logo`;
    return this
      .http
      .patch<void>(url, {attachmentId})
      .pipe(take(1));
  }

  private subscribeToLogoutEvents(): void {
    this
      .authenticationService
      .authenticationEvents
      .pipe(
        filter(event => event === AuthenticationEvents.LOGOUT)
      )
      .subscribe(() => this.tenantStorageService.clearTenantData());
  }

  private fetchAvailableTenants(): Observable<ITenant[]> {
    const url = `${environment.fieldServicesAPI}/users/available-tenants`;
    return this
      .http
      .get<ITenant[]>(url)
      .pipe(take(1));
  }
}
