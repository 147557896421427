import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {CreateQueryParams} from '@nestjsx/crud-request';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService extends BaseCrudService<ILocation> {

  constructor(public http: HttpClient) {
    super(http, 'locations');
  }

  public fetchHierarchies(locationTypeId: string, loadArchived: boolean = false) {
    const url = `${this.url}/hierarchies/${locationTypeId}?loadArchived=${loadArchived}`;
    return this.execute(new HttpRequest('GET', url));
  }

  public fetchLocationWithFullHierarchicalContext(locationId: string) {
    const url = `${this.url}/${locationId}/fullHierarchicalContext`;
    return this.execute(new HttpRequest('GET', url));
  }

  public loadHierarchies(params?: ISimpleParamWrapper): Observable<ILocationType[]> {
    let queryString = '';

    if (params) {
      const httpParams = new HttpParams({ fromObject: params });

      queryString = '?' + httpParams.toString();
    }

    const url = `${this.url}/all-hierarchies${queryString}`;

    return this.execute(new HttpRequest('GET', url));
  }

  public fetchSuperHierarchies(
    locationId: string,
    useTypeAsRoot: boolean = false,
  ): Observable<ILocation | ILocationType> {
    const url = `${this.url}/superhierarchies/${locationId}`;
    const params = new HttpParams({
      fromObject: { useTypeAsRoot: useTypeAsRoot ? 'true' : 'false' }
    });
    const request = new HttpRequest('GET', url, { params });
    return this.execute(request);
  }

}
