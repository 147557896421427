import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from '../../core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionAnswerHttpService extends BaseCrudService<IQuestionAnswer> {

  constructor(
    public http: HttpClient,
  ) {
    super(http, 'question-answers');
  }

}
