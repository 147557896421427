import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<number> = new EventEmitter<number>();

  public answer: FormControl;
  public min: number = Number.MIN_VALUE;
  public max: number = Number.MAX_VALUE;

  public ngOnInit(): void {
    const questionConfig = this.managedQuestion.question.configuration;
    const validators = [];

    if (questionConfig.minNumber === 0 || !!questionConfig.minNumber) {
      validators.push(Validators.min(questionConfig.minNumber));
      this.min = questionConfig.minNumber;
    }

    if (questionConfig.maxNumber === 0 || !!questionConfig.maxNumber) {
      validators.push(Validators.max(questionConfig.maxNumber));
      this.max = questionConfig.maxNumber;
    }

    if (questionConfig.isRequired) {
      validators.push(Validators.required);
    }

    this.answer = new FormControl(
      !!this.managedQuestion.answers.length ? this.managedQuestion.answers[0].numericAnswer : undefined,
      validators,
    );

    this.managedQuestion
      .answerUpdated
      .subscribe(value => {
        let answer = value?.numericAnswer ?? value;

        if (Array.isArray(value) && value.length) {
          answer = value[0].numericAnswer ?? null;
        }

        this.answer.patchValue(answer);
      });

    this
      .answer
      .valueChanges
      .pipe(debounceTime(300))
      .subscribe((answerValue) => {
        const answerArray = [];
        if (this.answer.valid && typeof answerValue === 'number') {
          const question = this.managedQuestion.question;

          answerArray.push({
            questionId: question.id,
            questionType: question.type,
            questionPublicId: question.publicId,
            sectionPublicId: question.section.publicId,
            sectionId: question.sectionId,
            numericAnswer: answerValue,
          });
        }
        this.managedQuestion.answers = answerArray;

        this.valueChange.emit(answerValue);
      });

  }

}
