import { Component, EventEmitter, Input, Output } from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {FsPaging, IFsPaging} from '../fs-table/fs.paging';

@Component({
  selector: 'app-fs-select',
  templateUrl: './fs-select.component.html',
  styleUrls: ['./fs-select.component.scss']
})
export class FsSelectComponent {
  /**
   * Indicates whether the control is used by reactive forms or template-driven forms
   */
  @Input() isReactive: boolean = false;
  /**
   * The form control name (Only required if isReactive is set to true)
   */
  @Input() form: FormGroup = null;
  /**
   * The bound select option (Only required if isReactive is set to false)
   */
  @Input() model: string | string[] = null;
  /**
   * Indicates whether the control is disabled
   */
  @Input() isDisabled: boolean = false;
  /**
   * The select list options that should be used
   */
  @Input() options: ISelectOption[] = [];
  /**
   * Whether the data is lazy-loaded or eager-loaded from server.
   * Enabling this will enable/expect backend pagination/sorting/filtering
   */
  @Input() lazyLoaded: boolean = false;
  /**
   * Indicator for whether the lazy load function is processing
   */
  @Input() isLoading: boolean = false;
  /**
   * Paging Object created and passed in from the consuming parent context - Default to new Paging object (disabled by default!)
   */
  @Input() paging: IFsPaging = new FsPaging(false);
  /**
   * The placeholder text to use when nothing is selected
   */
  @Input() placeholder: string = '';
  /**
   * The ID to pass to the inner input
   */
  @Input() inputId: string = '';
  /**
   * The Form Control name to use to get the control from the @Input Form
   */
  @Input() controlName: string = '';
  /**
   * The size (nzSize) of the component
   */
  @Input() size: 'large' | 'small' | 'default' = 'large';
  /**
   * The mode (nzMode) of the component
   */
  @Input() mode: 'multiple' | 'tags' | 'default' = 'default';

  /**
   * The method that fires on ngModel change with option id
   */
  @Output() modelChange: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();
  /**
   * This EventEmitter outputs the changed select options
   */
  @Output() optionsChange: EventEmitter<ISelectOption[]> = new EventEmitter<ISelectOption[]>();
  /**
   * This EventEmitter enables two-way binding on the paging @Input value. E.g. [(paging)]
   */
  @Output() pagingChange: EventEmitter<IFsPaging> = new EventEmitter<IFsPaging>();
  /**
   * This EventEmitter outputs the isLoading value back to the consumer
   */
  @Output() isLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * The event to fire when the lazy-loaded select needs to fetch new data (required is lazyLoaded is true).
   * This emits void because we are two-way binding the paging object, so it is not necessary to pass paging info back here.
   */
  @Output() lazyUpdate: EventEmitter<string> = new EventEmitter<string>();
  /**
   * The event to fire when a lazy-loaded search is done to fetch data matching a specific criteria
   */
  @Output() searchUpdate: EventEmitter<string> = new EventEmitter<string>();

  private _search: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _initialized: boolean = false;

  constructor() {
    this._search
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        if (this.lazyLoaded && this._initialized) {
          this.search(value);
        }

        if (!this._initialized) {
          this._initialized = true;
        }
      });
  }

  get formControl(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  public loadMore() {
    if (!this.paging.nextPage() || !this.lazyLoaded) {
      return;
    }

    this.isLoadingChange.emit(true);

    this.lazyUpdate.emit(this._search.getValue());
  }

  public search(search: string) {
    this.paging.pageIndex = 1;

    this.isLoadingChange.emit(true);

    if (!search) {
      this.lazyUpdate.emit();
    }

    this.searchUpdate.emit(this._search.getValue());
  }

  setSearchValue(search: string) {
    // send every value from the inner to the subject
    this._search.next(search);
  }
}
