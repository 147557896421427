import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-yes-or-no',
  templateUrl: './yes-or-no.component.html',
  styleUrls: ['./yes-or-no.component.scss']
})
export class YesOrNoComponent implements OnInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public answer: FormControl;

  public constructor() {
  }

  public ngOnInit(): void {
    this.answer = new FormControl(
      !!this.managedQuestion.answers.length ? this.managedQuestion.answers[0].booleanAnswer : undefined,
      this.managedQuestion.question.configuration.isRequired ? [Validators.required] : [],
    );

    this
      .answer
      .valueChanges
      .subscribe((answer) => {
        this.managedQuestion.answers = [{
          questionId: this.managedQuestion.question.id,
          questionPublicId: this.managedQuestion.question.publicId,
          sectionPublicId: this.managedQuestion.question.section.publicId,
          sectionId: this.managedQuestion.question.sectionId,
          questionType: this.managedQuestion.question.type,
          booleanAnswer: answer,
        }];

        this.valueChange.emit(answer);
      });
  }

}
