import {Injectable} from '@angular/core';
import {CondOperator, CreateQueryParams} from '@nestjsx/crud-request';
import {QueryFilter} from '@nestjsx/crud-request/lib/types';
import {ComparisonOperator} from '@nestjsx/crud-request/lib/types/request-query.types';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import {map, tap} from 'rxjs/operators';
import {FsCascaderAbstractService} from '../../shared/fs-cascader/fs-cascader-abstract.service';
import {CascadeLoadDataRequest} from '../../shared/fs-cascader/fs-cascader.component';
import {CustomerHttpService} from './customer-http.service';
import {CustomerTypeHttpService} from './customer-type-http.service';

@Injectable()
export class CustomerCascaderService extends FsCascaderAbstractService<ICustomerType, ICustomer> {
  constructor(
    private customerTypeHttpService: CustomerTypeHttpService,
    private customerHttpService: CustomerHttpService,
  ) {
    super('customerTypeId');
  }


  public loadCustomerTypeOptions(): Promise<NzCascaderOption[]> {
    const query: CreateQueryParams = {
      filter: [{
        field: 'isActive',
        operator: CondOperator.EQUALS,
        value: true,
      }],
      sort: {field: 'name', order: 'ASC'},
    };
    const extraParams: ISimpleParamWrapper = {
      injectStatistics: 'true'
    };

    if (this.isEditing.getValue()) {
      delete query.filter[0];
    }

    if (this.entityTypeFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityTypeFilters
      ];
    }

    this.isLoading.next(true);

    return this.customerTypeHttpService
      .get(query, extraParams)
      .pipe(
        map((response: IPagination<ICustomerType>) => {
          return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
        }),
        tap((options: NzCascaderOption[]) => {
          if (options) {
            // Set the location type options
            this.replaceLocationTypeOptions(options);

            // Backup options for reverting after search
            this.backupLocationTypeOptions();

            this.isLoading.next(false);
          }
        }))
      .toPromise();
  }

  public loadCustomersForCustomerTypeOption(request: CascadeLoadDataRequest): Promise<NzCascaderOption[]> {
    const option = request.option;
    const query: CreateQueryParams = {
      filter: [
        {
          field: 'customerTypeId',
          operator: CondOperator.EQUALS,
          value: option.value,
        },
        {
          field: 'isActive',
          operator: CondOperator.EQUALS,
          value: true,
        }
      ],
      sort: {field: 'name', order: 'ASC'},
      join: [
        {field: 'tags'}
      ]
    };

    if (this.isEditing.getValue()) {
      delete query.filter[1];
    }

    if (!option || !option.value) {
      if (option.loading) {
        requestAnimationFrame(() => {
          option.loading = false;
          request.callback();
        });
      }

      return;
    }

    if (this.entityFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityFilters
      ];
    }

    return this.customerHttpService
      .get(query)
      .pipe(
        map((response: IPagination<ICustomer>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          option.children = options;

          if (!options.length) {
            option.disabled = true;
          } else if (option.disabled) {
            option.disabled = false;
          }

          if (request.callback) {
            request.callback(options);
          }
        }))
      .toPromise();
  }

  public searchCustomerTypeOptions(search: string): Promise<NzCascaderOption[]> {
    // Private flag _showingSearchResults used to prevent customer types trees from being overwritten unnecessarily
    if (!search && this._showingSearchResults.getValue()) {
      this.restoreRootOptionsBackup();

      this.isLoading.next(false);
      this._showingSearchResults.next(false);

      return;
    }

    this._showingSearchResults.next(true);

    const query: CreateQueryParams = {
      filter: [{
        field: 'name',
        operator: CondOperator.CONTAINS,
        value: search,
      }],
      sort: {field: 'name', order: 'ASC'},
      join: [
        {field: 'tags'}
      ]
    };

    if (this.entityTypeFilters.length) {
      const entityTypeIdsFilter = this.entityTypeFilters.map(f => f.value);
      const fixedIdsFilterArray = Array.isArray(entityTypeIdsFilter[0])
        ? entityTypeIdsFilter[0]
        : entityTypeIdsFilter;

      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...[{
          field: this._parentIdSelector,
          operator: '$in' as ComparisonOperator,
          value: fixedIdsFilterArray
        }]
      ];
    }

    if (this.entityFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityFilters
      ];
    }

    return this.customerHttpService
      .get(query)
      .pipe(
        map((response: IPagination<ICustomer>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          this.isLoading.next(false);

          // Replace location type options with the search results (temporary)
          this.replaceLocationTypeOptions(options);
        }))
      .toPromise();
  }
}
