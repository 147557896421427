<nz-form-control [nzErrorTip]="errorTpl">

  <ng-container *ngIf="['date_time', 'date_only'].includes(managedQuestion.question.configuration.dateInputType); else timeOnly">
    <nz-date-picker
      [nzShowTime]="managedQuestion.question.configuration.dateInputType === 'date_time'"
      [nzFormat]="dateFormat"
      [nzInputReadOnly]="true"
      [formControl]="answer"
      [nzDisabledDate]="validDateEvaluator"
      class="fs-date-input"
      nzSize="large">
    </nz-date-picker>
  </ng-container>

  <ng-template #timeOnly>
    <nz-time-picker
      [id]="'time-picker-question-' + managedQuestion.question.id"
      class="fs-date-timeonly-input"
      nzSize="large"
      [formControl]="answer">
    </nz-time-picker>
  </ng-template>

  <ng-template #errorTpl>
    <div class="error form-control-msg" *ngIf="answer.hasError('required')">
      This question is required
    </div>
  </ng-template>

</nz-form-control>
