import {StateManagedSection} from 'src/app/shared/answer-sheet/services/state-managed-section';

export class StateManagedForm implements IStateManagedForm {
  public completion: number = 0;

  public constructor(
    public readonly formVersionId: string,
    public readonly formTitle: string,
    public readonly formVersionNumber: number,
    public sections: IStateManagedSection[] = [],
    public readonly formVersionAnswerSheetId?: string,
  ) {
  }

  public get isComplete() {
    return this.completion >= 1;
  }
}
