import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {CreateQueryParams} from '@nestjsx/crud-request';
import {Observable} from 'rxjs';
import { BaseCrudService } from '../../core/http/base.service';

@Injectable({
  providedIn: 'root',
})
export class FormVersionHttpService extends BaseCrudService<IFormVersion> {

  public constructor(public http: HttpClient) {
    super(http, 'form-versions');
  }

  public getFormWithSections(formVersionId: string, formProperty?: string) {
    const joins = {
      join: [
        { field: 'form' },
        { field: 'sections' },
        { field: 'sections.questions' },
        { field: 'sections.questions.options' },
        { field: 'form.currentPublishedFormVersion' },
        { field: 'form.currentDraftFormVersion' },
      ]
    };

    if (formProperty) {
      joins.join.push({ field: `form.${formProperty}` });
    }

    return this.getOne(formVersionId, joins);
  }
}
