import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import {CustomerCascaderService} from '../../customers/services/customer-cascader.service';
import {FsCascaderComponent} from '../../shared/fs-cascader/fs-cascader.component';

@Component({
  selector: 'app-customer-hard-link-selector',
  templateUrl: './customer-hard-link-selector.component.html',
  styleUrls: ['./customer-hard-link-selector.component.scss'],
  providers: [
    CustomerCascaderService
  ]
})
export class CustomerHardLinkSelectorComponent implements OnInit {
  // Input Params
  @Input()
  public configuration: ICustomerHardLinkConfiguration;

  @Input()
  public initialValues?: string[];

  // Output Events
  @Output()
  public customersSelected: EventEmitter<string[]> = new EventEmitter();

  // View Children
  @ViewChild(FsCascaderComponent) cascader: FsCascaderComponent;

  get isValid(): boolean {
    return !this.cascader?.hasRequiredError
      && this.selectedCustomer
      && Array.isArray(this.selectedCustomer)
        ? !this.selectedCustomer[0].isLeaf
        : !(this.selectedCustomer as NzCascaderOption).isLeaf;
  }

  // Public Properties
  public selectedCustomer: NzCascaderOption | Array<NzCascaderOption>;

  public constructor(public customerCascaderService: CustomerCascaderService) {
  }

  // Page Events
  public async ngOnInit(): Promise<void> {
    if (this.configuration.customerTypesAllowed?.length) {
      this.customerCascaderService.addEntityTypeFilter({
        field: 'id',
        operator: '$in',
        value: this.configuration.customerTypesAllowed
      });
    }

    if (this.configuration.customerSelectionTagIds?.length) {
      this.customerCascaderService.addEntityFilter({
        field: 'tags.id',
        operator: '$in',
        value: this.configuration.customerSelectionTagIds
      });
    }

    await this.customerCascaderService.loadCustomerTypeOptions();

    if (this.initialValues) {
      this.selectedCustomer = [...this.initialValues];
    }
  }

  // Public Methods
  public handleCustomerUpdate(item: NzCascaderOption | string | Array<NzCascaderOption | string>): void {
    if (Array.isArray(item)) {
      this.customersSelected.emit(item.map(i => {
        if (typeof i === 'string') {
          return i;
        } else {
          return i.value;
        }
      }));
    } else {
      this.customersSelected.emit([typeof item === 'string' ? item : item.value]);
    }
  }

  public setHierarchyForCustomer(option: NzCascaderOption) {
    this.customerCascaderService.setHierarchy(option)
      .then(selected => {
        this.selectedCustomer = selected;

        this.handleCustomerUpdate(option);
      });
  }
}
