<ul class="attachment-list">
  <ng-container *ngFor="let attachment of attachments">
    <li>
      <app-attachment
        [attachment]="attachment"
        [deletable]="deletableAttachments"
        [allowDeletion]="allowDeletion"
        (deleteAttachment)="deleteAttachment.emit($event)">
      </app-attachment>
    </li>
  </ng-container>
</ul>
