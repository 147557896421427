import {HttpClient, HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CreateQueryParams} from '@nestjsx/crud-request';
import {Observable, of} from 'rxjs';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import {BaseCrudService} from 'src/app/core/http/base.service';
import {SurveyAnswerHttpService} from 'src/app/surveys/services/survey-answer-http.service';
import {SurveyTypeStatusService} from 'src/app/surveys/services/survey-type-status.service';
import {FsPagingToNestQueryAdaptor} from '../../shared/fs-table/fs-paging-to-nest-query.adaptor';
import {IFsTableConfig} from '../../shared/fs-table/fs-table.config.interface';
import {IFsPaging} from '../../shared/fs-table/fs.paging';
import {TableConfigService} from '../../shared/fs-table/table-config.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyHttpService extends BaseCrudService<ISurvey> {

  public constructor(
    public http: HttpClient,
    private surveyAnswerService: SurveyAnswerHttpService,
    private surveyTypeStatusService: SurveyTypeStatusService,
    private tableConfigService: TableConfigService
  ) {
    super(http, 'surveys');
  }

  public generateSurvey(surveyTypeId: string): Observable<ISurvey> {
    const surveyStatusQuery: CreateQueryParams = {
      search: {
        surveyTypeId: {
          $eq: surveyTypeId
        },
        isActive: {
          $eq: true
        }
      },
      sort: [{
        field: 'order',
        order: 'ASC'
      }]
    };
    return this
      .surveyTypeStatusService
      .get(surveyStatusQuery)
      .pipe(
        map(response => response.data),
        switchMap(statuses => {
          const templateSurvey: ISurvey = {
            name: 'Untitled',
            defaultStatusId: statuses[0].id,
            surveyTypeId,
            isAvailableMobile: false,
            isAvailablePublic: false,
            hardLinkConfiguration: {
              items: {
                allowItemHardLink: false,
                itemSelectionTagIds: [],
                itemTypesAllowed: [],
                allowMultipleAnswers: false,
                isRequired: false,
              },
              locations: {
                allowLocationHardLink: false,
                locationSelectionTagIds: [],
                locationTypesAllowed: [],
                allowMultipleAnswers: false,
                isRequired: false,
              },
              customers: {
                allowCustomerHardLink: false,
                customerTypesAllowed: [],
                customerSelectionTagIds: [],
                allowMultipleAnswers: false,
                isRequired: false,
              }
            }
          };
          return this.post(templateSurvey);
        })
      );
  }

  public loadSurveysOfType(
    surveyTypeId: string,
    isArchived: boolean = false,
    paging?: IFsPaging,
    config?: IFsTableConfig
  ): Observable<IPagination<ISurvey>> {
    const fsTableQueryAdaptor = new FsPagingToNestQueryAdaptor(paging);
    const presetColumnJoins = config ? this.tableConfigService.getColumnJoins(config) : null;
    const query: CreateQueryParams = {
      search: {
        surveyTypeId: {
          $eq: surveyTypeId
        },
        isActive: {
          $eq: !isArchived
        }
      },
      join: presetColumnJoins ? presetColumnJoins : []
    };

    const combinedQuery = fsTableQueryAdaptor.query(query);

    return this.get(combinedQuery)
      .pipe(
        tap((response) => {
          fsTableQueryAdaptor.update(response.page, response.total);
        })
      );
  }

  public loadAnswersOfType(
    surveyTypeId: string,
    surveyTypeStatusId?: string,
    paging?: IFsPaging,
    config?: IFsTableConfig
  ): Observable<Partial<IPagination<ISurveyAnswer>>> {
    const fsTableQueryAdaptor = new FsPagingToNestQueryAdaptor(paging);
    const presetColumnJoins = config ? this.tableConfigService.getColumnJoins(config) : null;

    return this
      .loadSurveysOfType(surveyTypeId, false)
      .pipe(
        map((pagination) => pagination.data),
        switchMap((surveys) => {
          if (surveys.length === 0) {
            return of({
              data: [],
              count: 0,
              total: 0,
              page: 1,
              pageCount: 1,
            });
          } else {
            const query: CreateQueryParams = {
              search: {
                $or: surveys.map(survey => ({
                  surveyId: {
                    $eq: survey.id,
                  }
                })),
              },
              join: [
                {
                  field: 'survey',
                  select: ['name']
                },
                ...presetColumnJoins
              ]
            };

            if (surveyTypeStatusId) {
              if (!query.search.$and) {
                query.search.$and = [];
              }

              query.search.$and = [
                ...query.search.$and,
                {
                  surveyTypeStatusId: {
                    $eq: surveyTypeStatusId
                  }
                }
              ];
            }

            const combinedQuery = fsTableQueryAdaptor.query(query);

            return this.surveyAnswerService.get(combinedQuery)
              .pipe(
                tap((response) => {
                  fsTableQueryAdaptor.update(response.page, response.total);
                })
              );
          }
        })
      );
  }

  public restoreSurvey(survey: ISurvey): Observable<Partial<ISurvey>> {
    survey.isActive = true;
    return this.put(survey);
  }

  public archiveSurvey(survey: ISurvey): Observable<Partial<ISurvey>> {
    survey.isActive = false;
    return this.put(survey);
  }

  public getSurvey(surveyId: string, params: CreateQueryParams) {
    const request = new HttpRequest('GET', this.urlWithId(surveyId) + this.parseQueryParameters(params));
    return this.http.request(request)
      .pipe(
        filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
        take(1),
        map((res: any) => res.body)
      );
  }

}
