import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import {TenantStateService} from './tenant-state.service';

@Component({
  selector: 'app-tenant-selector',
  templateUrl: './tenant-selector.component.html',
  styleUrls: ['./tenant-selector.component.scss']
})
export class TenantSelectorComponent implements OnInit {

  public isLoading: boolean = true;

  public tenants: ITenant[] = [];

  public constructor(
    public tenantStorageService: TenantStateService
  ) { }

  public ngOnInit(): void {
    this.subscribeToAvailableTenants();
  }

  private subscribeToAvailableTenants(): void {
    this.isLoading = true;

    this
      .tenantStorageService
      .availableTenants
      .pipe(filter(tenants => !!tenants.length))
      .subscribe(
        tenants => {
          this.tenants = tenants;
          this.isLoading = false;
        },
      );
  }

}
