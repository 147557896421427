<nz-sider class="sider-left-menu">
  <div class="container-left-menu">
    <a class="container-fs-icon" [routerLink]="[ '/' ]">
      <img *ngIf="!appState.logo" src="/assets/atajo-logo.svg" width="70"/>
      <img class="logo" *ngIf="appState.logo" [src]="appState.logo.url" width="70"/>
    </a>

    <nz-divider nzType="horizontal"></nz-divider>

    <ul class="scroll-y-160">
      <ng-container *ngFor="let item of sideNavigationMenuItemProviderService.items">
        <li *ngIf="IsNullOrUndefined(item.showIf) || item.showIf()">
          <a
            class="left-menu-item"
            [id]="item.uri"
            [routerLink]="item.uri"
            routerLinkActive="left-menu-item-selected">
              <i nz-icon nzType="{{ item.icon }}" nzTheme="outline"></i>
              <span>{{ item.text }}</span>
          </a>
        </li>
      </ng-container>
    </ul>

    <nz-divider nzType="horizontal" class="anemic-divider"></nz-divider>

    <div class="version">v.{{version}}</div>
  </div>
</nz-sider>
