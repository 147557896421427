import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TenantStateService } from '../tenant-selector/tenant-state.service';

@Injectable({
  providedIn: 'root'
})
export class SideNavigationMenuItemProviderService {

  public items: ISideNavigationMenuItem[];

  public constructor(
    private tenantStateService: TenantStateService
  ) {
    this.items = [{
      uri: 'job-categories',
      icon: 'solution',
      text: 'Jobs',
    }, {
      uri: 'schedule',
      icon: 'calendar',
      text: 'Schedule',
    }, {
      uri: 'maps',
      icon: 'compass',
      text: 'Map',
    }, {
      uri: 'surveys',
      icon: 'form',
      text: 'Surveys',
    }, {
      uri: 'customer-types',
      icon: 'shopping',
      text: 'Customers',
          }, {
      uri: 'location-types',
      icon: 'environment',
      text: 'Locations',
          }, {
      uri: 'item-types',
      icon: 'appstore',
      text: 'Items',
          }, {
      uri: 'stock-transfer-types',
      icon: 'delivered-procedure',
      text: 'Stock Transfers',
          }, {
      uri: 'payments',
      icon: 'dollar-circle',
      text: 'Payments',
      showIf: () => environment.paymentModuleEnabled
    }, {
      uri: 'users',
      icon: 'user',
      text: 'Users',
          }, {
      uri: 'tenant-reports',
      icon: 'line-chart',
      text: 'Reports',
      showIf: (): boolean => !!this.tenantStateService?.tenantValue?.reportsUrl
    }];
  }

}
