import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public answer: FormControl;

  public constructor() {
  }

  public ngOnInit(): void {
    this.answer = new FormControl(
      !!this.managedQuestion.answers.length ? this.managedQuestion.answers[0].textAnswer : '',
      this.managedQuestion.question.configuration.isRequired ? [Validators.required] : [],
    );

    this
      .answer
      .valueChanges
      .pipe(debounceTime(300))
      .subscribe((answer) => {
        answer = answer.trim();
        const answerArray = [];
        if (!!answer) {
          const question = this.managedQuestion.question;

          answerArray.push({
            questionId: question.id,
            questionType: question.type,
            questionPublicId: question.publicId,
            sectionPublicId: question.section.publicId,
            sectionId: question.sectionId,
            textAnswer: answer,
          });
        }

        this.managedQuestion.answers = answerArray;

        this.valueChange.emit(answerArray);
      });
  }

}
