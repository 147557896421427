import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const oAuthModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [
      environment.fieldServicesAPI,
    ],
    sendAccessToken: true,
  }
};

export const oAuthServiceConfig: AuthConfig = {
  clearHashAfterLogin: false,
  clientId: environment.azureB2CClientId,
  disablePKCE: true,
  issuer: environment.azureB2CIssuer,
  loginUrl: environment.azureB2CAuthorizationEndpoint,
  oidc: true,
  redirectUri: environment.azureB2CRedirectUri,
  responseType: 'token id_token',
  scope: `openid ${environment.azureB2CFieldServicesAPIScope}`,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  skipIssuerCheck: true,
  strictDiscoveryDocumentValidation: false,
  tokenEndpoint: environment.azureB2CTokenEndpoint,
  useSilentRefresh: true,
};

export const discoveryDocumentConfig = {
  url : environment.azureB2CDiscoveryDocument
};

