import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BaseCrudService} from 'src/app/core/http/base.service';
import {FsPagingToNestQueryAdaptor} from '../../shared/fs-table/fs-paging-to-nest-query.adaptor';
import {TableConfigService} from '../../shared/fs-table/table-config.service';

@Injectable({
  providedIn: 'root'
})
export class ItemUnitHttpService extends BaseCrudService<IItemUnit> {
  constructor(
    public http: HttpClient,
    public tableConfigService: TableConfigService
    ) {
    super(http, 'item-units');
  }

  private fsTableQueryAdaptor: FsPagingToNestQueryAdaptor;

  public loadItemUnits(
    combinedQuery
  ): Observable<IPagination<IItemUnit>> {
    return this.get(combinedQuery)
      .pipe(
        tap((response) => {
          this.fsTableQueryAdaptor.update(response.page, response.total);
        })
      );
  }
}
