import {Injectable} from '@angular/core';
import {AbstractStateService} from './core/state-management/abstract-state.service';

@Injectable({providedIn: 'root'})
export class AppStateService extends AbstractStateService<IAppState> {
  public initializeState(): IAppState {
    return {
      isAppLoading: false,
      isPageLoading: false,
      logo: null
    };
  }

  public togglePageLoader(isPageLoading: boolean) {
    this.updateState({isPageLoading});
  }

  public setLogo(logo: IAttachment): void {
    this.updateState({logo});
  }
}
