import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {IsNullOrUndefined} from '../../../core/helpers/type.helpers';
import {IItemFieldsState, IItemSelectionConfig, IItemUnitConfig} from './services/item-fields-state.interface';
import {ItemFieldsStateService} from './services/item-fields-state.service';

@Component({
  selector: 'app-item-fields',
  templateUrl: './item-fields.component.html',
  styleUrls: ['./item-fields.component.scss']
})
export class ItemFieldsComponent implements OnInit {
  @Input()
  public managedSection: IStateManagedSection = null;

  @Output()
  public valueChange: EventEmitter<IItemSelectionConfig> = new EventEmitter<IItemSelectionConfig>();

  public IsNullOrUndefined = IsNullOrUndefined;
  public searchControl: FormControl = new FormControl(null);
  public state: IItemFieldsState;

  // Getters / Setters
  public get availableUnits(): ISelectOption[] {
    return this.stateService.availableUnits;
  }

  public get canLoadMore(): boolean {
    const paging = this.state.itemsPaging;

    return paging.totalItems > (paging.pageIndex * paging.pageSize);
  }

  public get shouldShowAddMoreButton(): boolean {
    const availableUnits = this.availableUnits.length;
    const consumedUnits = this.availableUnits.filter(u => !!u.isDisabled).length;

    return availableUnits && consumedUnits !== availableUnits && consumedUnits > 0;
  }

  constructor(private stateService: ItemFieldsStateService) {
    // Update local state reference when it changes
    this.stateService
      .listener
      .subscribe((state: IItemFieldsState) => this.state = state);

    this.searchControl
      .valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchValue: string) => {
        this.stateService.loadItems.emit({
          paging: this.state.itemsPaging,
          search: searchValue
        });
      });
  }

  // Page Events
  public ngOnInit() {
    if (this.managedSection.questions) {
      this.stateService.updateState({
        questionsBlueprint: this.managedSection.questions
      });
    }

    this.stateService.loadItems.emit({
      paging: this.state.itemsPaging
    });
  }

  // Public Methods
  public addNextUnit() {
    this.stateService.addNewItemUnitConfig();
  }

  public getItemValidity(item: IItem): boolean | undefined {
    return this.stateService.getItemValidity(item);
  }

  public loadMoreItems(): void {
    this.stateService.loadItems.emit({
      paging: this.state.itemsPaging,
      search: this.searchControl.value
    });
  }

  public checkIfDetailsShouldDisplay(searchType: string) {
    this.stateService.checkIfDetailsShouldDisplay(searchType);
  }

  public setSelectedItem(item: IItem): void {
    this.stateService.setSelectedItem(item);
  }
}
