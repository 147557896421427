import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerTypeHttpService extends BaseCrudService<ICustomerType> {

  public typeArchivedSubject: Subject<ICustomerType> = new Subject();

  public typeUnarchivedSubject: Subject<ICustomerType> = new Subject();

  public typeUpdatedSubject: Subject<ICustomerType> = new Subject();

  public typeCreatedSubject: Subject<ICustomerType> = new Subject();

  constructor(
    public http: HttpClient,
  ) {
    super(http, 'customer-types');
  }

  public reorder(body: ReorderingPayload): Observable<void> {
    const url = `${this.url}/reorder`;
    return this.execute(new HttpRequest('PATCH', url, body));
  }

  public archiveType(typeToArchive: ICustomerType): Observable<ICustomerType> {
    typeToArchive.isActive = false;
    return this
      .patch(typeToArchive)
      .pipe(
        tap((typeArchived) => {
          this.typeArchivedSubject.next(typeArchived);
        })
      );
  }

  public unarchiveType(typeToUnarchive: ICustomerType): Observable<ICustomerType> {
    typeToUnarchive.isActive = true;
    return this
      .patch(typeToUnarchive)
      .pipe(
        tap((typeUnarchived) => {
          this.typeUnarchivedSubject.next(typeUnarchived);
        })
      );
  }

  public patch(body: Partial<ICustomerType>, params?: CreateQueryParams): Observable<ICustomerType> {
    return super
      .patch(body, params)
      .pipe(
        tap(typeUpdated => {
          this.typeUpdatedSubject.next(typeUpdated);
        })
      );
  }

  public post(body: ICustomerType, params?: CreateQueryParams): Observable<ICustomerType> {
    return super
      .post(body, params)
      .pipe(
        tap(typeCreated => {
          this.typeCreatedSubject.next(typeCreated);
        })
      );
  }

  public fetchAllHierarchies(params?: ISimpleParamWrapper): Observable<ICustomerType[]> {
    let queryString = '';
    if (params) {
      const httpParams = new HttpParams({ fromObject: params });
      queryString = '?' + httpParams.toString();
    }
    const url = `${this.url}/all-hierarchies${queryString}`;
    return this.execute(new HttpRequest('GET', url));
  }

}
