<ng-container *ngIf="state.isLoading; else content">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-container>

<ng-template #content>
  <div class="title"> Transfer Summary </div>

  <ng-container *ngIf="state.transferSummary.reviewItems.length; else noItemSelected">
    <!-- Transfer Summary (Not Recon) -->
    <ng-container *ngIf="!state.includeBalance; else summaryWithDifference">
      <nz-card class="transaction-summary">
        <!-- Total Expected Quantity -->
        <div nz-row nzJustify="space-between">
          <label nz-col> Total Transfer qty. </label>
          <span nz-col> {{state.transferSummary.totalCountedQuantity}} </span>
        </div>
      </nz-card>
    </ng-container>

    <!-- Transfer Summary (Recon) -->
    <ng-template #summaryWithDifference>
      <nz-card *ngIf="state.includeBalance" class="transaction-summary">
        <!-- Total Expected Quantity -->
        <div nz-row nzJustify="space-between">
          <label nz-col> Total expected qty. </label>
          <span nz-col> {{state.transferSummary.totalExpectedQuantity}} </span>
        </div>

        <!-- Total Counted Quantity -->
        <div nz-row nzJustify="space-between">
          <label nz-col> Total counted qty. </label>
          <span nz-col> {{state.transferSummary.totalCountedQuantity}} </span>
        </div>

        <!-- Difference -->
        <div
          *ngIf="state.transferSummary.totalCountedQuantity !== state.transferSummary.totalExpectedQuantity"
          nz-row
          nzJustify="space-between"
          class="deficit">
          <label nz-col> Difference </label>
          <span nz-col> {{(state.transferSummary.totalCountedQuantity - state.transferSummary.totalExpectedQuantity)}} </span>
        </div>
      </nz-card>
    </ng-template>
  </ng-container>

  <div class="title"> Transfer Items </div>

  <ng-container *ngIf="state.transferSummary.reviewItems.length; else noItemSelected">
    <nz-card
      *ngFor="let summaryItem of state.transferSummary.reviewItems"
      class="transaction-summary-item"
      [ngClass]="{'deficit-border': state.includeBalance && (summaryItem.countedQuantity !== summaryItem.itemUnit?.quantity)}">
      <div class="item-name">
        {{summaryItem.itemName}}
      </div>

      <!-- Unit of Measure -->
      <div nz-row nzJustify="space-between">
        <label nz-col> Unit of measure </label>
        <span nz-col> {{summaryItem.unitName}} </span>
      </div>

      <ng-container *ngIf="!state.includeBalance; else summaryItemWithDifference">
        <!--  Qty. -->
        <div nz-row nzJustify="space-between">
          <label nz-col> Transfer qty. </label>
          <span nz-col> {{summaryItem.countedQuantity}} </span>
        </div>
      </ng-container>

      <ng-template #summaryItemWithDifference>
        <!-- Expected Qty. -->
        <div nz-row nzJustify="space-between">
          <label nz-col> Expected qty. </label>
          <span nz-col> {{summaryItem.expectedQuantity || summaryItem.itemUnit?.quantity}} </span>
        </div>

        <!-- Counted Qty. -->
        <div nz-row nzJustify="space-between">
          <label nz-col> Counted qty. </label>
          <span nz-col> {{summaryItem.countedQuantity}} </span>
        </div>

        <!-- Difference -->
        <div
          *ngIf="summaryItem.countedQuantity !== (summaryItem.expectedQuantity || summaryItem.itemUnit?.quantity)"
          nz-row
          nzJustify="space-between"
          class="deficit">
          <label nz-col> Difference </label>
          <span nz-col> {{(summaryItem.countedQuantity - (summaryItem.expectedQuantity || summaryItem.itemUnit?.quantity))}} </span>
        </div>

        <div nz-row nzJustify="end">
          <button
            *ngIf="enableTransactionsButtons"
            nz-button
            nzSize="large"
            nzType="default"
            (click)="showTransactionsForItem(summaryItem)">
            View Transactions
          </button>
        </div>
      </ng-template>
    </nz-card>

    <app-section-answer-sheet
      *ngIf="!readonlyMode"
      [managedSection]="managedSection"
      [excludeSummaryItems]="true"
      (questionAnswered)="questionAnswered.emit($event)">
    </app-section-answer-sheet>
  </ng-container>

  <ng-template #noItemSelected>
    <div class="no-items-placeholder">
      No items selected
    </div>
  </ng-template>

  <nz-modal
    [(nzVisible)]="state.showTransactions"
    [nzFooter]="modalFooter"
    [nzTitle]="transactionsTitle"
    nzWidth="500px"
    (nzOnCancel)="dismissModal()">
    <ng-container *nzModalContent>
      <nz-list *ngIf="state.activeTransactionItem?.transactions?.length">
        <nz-list-item *ngFor="let transaction of state.activeTransactionItem?.transactions">
          Moved <strong>{{transaction.quantity}}</strong> at <strong>{{transaction.location?.name}}</strong> on
          <strong>{{transaction.createdAt | date : 'medium'}}</strong>
        </nz-list-item>
      </nz-list>

      <div *ngIf="!state.activeTransactionItem?.transactions?.length" class="no-items-placeholder">
        No transactions recorded for this item unit
      </div>
    </ng-container>
  </nz-modal>

  <ng-template #modalFooter>
    <button
      id="transferSummaryForm__closeBtn"
      nz-button
      nzType="link"
      (click)="dismissModal()">
      Close
    </button>
  </ng-template>
</ng-template>
