<nz-card [nzBordered]="false" class="container-card">
  <nz-form-item [ngClass]="{'reduced-margin': !!selectedCustomer}" nz-row>
    <nz-form-label [nzRequired]="configuration.isRequired" nzSpan="24"> Customer </nz-form-label>

    <nz-form-control
      nz-col
      nzSpan="24">
      <app-fs-cascader
        #customerSelector
        inputId="public-survey__customer"
        placeholder="Select a Customer"
        [(model)]="selectedCustomer"
        [options]="customerCascaderService.options"
        [isLoading]="customerCascaderService.isLoading"
        [isRequired]="configuration.isRequired"
        (modelChange)="handleCustomerUpdate($event)"
        (cascadeLoadData)="customerCascaderService.loadCustomersForCustomerTypeOption($event)"
        (searchUpdate)="customerCascaderService.searchCustomerTypeOptions($event)"
        (searchItemSelected)="setHierarchyForCustomer($event)"
        (clear)="customerCascaderService.restoreRootOptionsBackup()">
      </app-fs-cascader>

      <div class="error form-control-msg" *ngIf="customerSelector.dirty && customerSelector.hasRequiredError">
        Customer is required
      </div>

      <div class="error form-control-msg" *ngIf="selectedCustomer && selectedCustomer.length === 1 && !selectedCustomer[0].isLeaf">
        A Customer Type cannot be selected, select a Customer
      </div>
    </nz-form-control>
  </nz-form-item>
</nz-card>
