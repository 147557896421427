import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {TenantStateService} from '../../shared/tenant-selector/tenant-state.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  public constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  async canActivate(): Promise<boolean> {
    const currentUser = await this.authenticationService.currentB2CUserDataAsync;
    const hasUser = !!currentUser;

    if (!hasUser) {
      this.authenticationService.startLoginFlow();
    }

    return hasUser;
  }
}
