import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class ItemTypeHttpService extends BaseCrudService<IItemType> {

  constructor(public http: HttpClient) {
    super(http, 'item-types');
  }

  public reorder(body: ReorderingPayload): Observable<void> {
    const url = `${this.url}/reorder`;
    return this.execute(new HttpRequest('PATCH', url, body));
  }

  public fetchAllCategoryHierarchies(params?: ISimpleParamWrapper): Observable<IItemType[]> {
    let queryString = '';
    if (params) {
      const httpParams = new HttpParams({ fromObject: params });
      queryString = '?' + httpParams.toString();
    }
    const url = `${this.url}/all-category-hierarchies${queryString}`;
    return this.execute(new HttpRequest('GET', url));
  }

}
