import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-navigation-submenu',
  templateUrl: './side-navigation-submenu.component.html',
  styleUrls: ['./side-navigation-submenu.component.scss']
})
export class SideNavigationSubmenuComponent implements OnInit {

  @Input()
  public title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
