<nz-card [nzBordered]="false" class="container-card">
  <!-- Question Title -->
  <div class="title">
    {{ managedQuestion.question.title }}
    <span *ngIf="managedQuestion.question.configuration.isRequired" class="required">*</span>
  </div>

  <!-- Question Description -->
  <div *ngIf="managedQuestion.question.description"
    class="description"
    [ngClass]="{'description-info': managedQuestion.question.type === 'info'}">
    <pre [innerHTML]="getParsedDescription()"></pre>
  </div>

  <!-- Type Inputs -->
  <div class="answer" [ngSwitch]="managedQuestion.question.type">
    <ng-container *ngSwitchCase="'text_input'">
      <app-text-input
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-text-input>
    </ng-container>

    <ng-container *ngSwitchCase="'text_area'">
      <app-text-area
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-text-area>
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <app-number
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-number>
    </ng-container>

    <ng-container *ngSwitchCase="'quantity'">
      <app-quantity
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-quantity>
    </ng-container>

    <ng-container *ngSwitchCase="'scan_qr_barcode'">
      <app-text-input
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-text-input>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <app-date
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-date>
    </ng-container>

    <ng-container *ngSwitchCase="'duration'">
      <app-duration
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-duration>
    </ng-container>

    <ng-container *ngSwitchCase="'yes_or_no'">
      <app-yes-or-no
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-yes-or-no>
    </ng-container>

    <ng-container *ngSwitchCase="'multiple_choice'">
      <app-multiple-choice
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-multiple-choice>
    </ng-container>

    <ng-container *ngSwitchCase="'gps'">
      <app-gps
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-gps>
    </ng-container>

    <ng-container *ngSwitchCase="'attachment'">
      <app-media-attachemnt
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-media-attachemnt>
    </ng-container>

    <ng-container *ngSwitchCase="'take_media'">
      <app-media-attachemnt
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-media-attachemnt>
    </ng-container>

    <ng-container *ngSwitchCase="'signature'">
      <app-signature
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-signature>
    </ng-container>

    <ng-container *ngSwitchCase="'location_selection'">
      <app-location-selection
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-location-selection>
    </ng-container>

    <ng-container *ngSwitchCase="'item_selection'">
      <app-item-selection
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-item-selection>
    </ng-container>

    <ng-container *ngSwitchCase="'timer'">
      <app-timer
        [managedQuestion]="managedQuestion"
        (valueChange)="valueChange.emit($event)">
      </app-timer>
    </ng-container>
  </div>
</nz-card>
