import {ComparisonOperator, QueryFields} from '@nestjsx/crud-request/lib/types/request-query.types';
import {NzTableFilterValue} from 'ng-zorro-antd/table';
import {DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_TOTAL_NO_OF_ITEMS} from './fs-table-paging.defaults';
import {FsColumnDataType} from './fs-table.config.interface';

/***
 * A paging object used to configure and track the paging used within FS components such as the FS Table.
 *
 * This object can also keep track of sorting and filtering.
 */
export interface IFsPaging {
  /* Required */
  isEnabled: boolean; // Is the paging enabled or disabled
  pageIndex: number; // The current page index
  pageSize: number; // The number of items per page
  totalItems: number; // The total number of items including all pages

  /* Optional */
  sortOrder?: string | 'ascend' | 'descend' | null; // Without string type dot notation mapping fails
  sortField?: string; // The field which needs to be used to sort the data
  filter?: FsTableFilter[]; // The filter data used to filter the data
  columns?: QueryFields; // The query fields specific to the query (tightly coupled to @nestjs/crud-request

  /***
   * Clear all filters for this paging object
   */
  resetFilter();

  /***
   * Clear all sorting for this paging object
   */
  resetSort();

  /***
   * Set the state of the object to reflect the next page
   *
   * @returns next page index or false if no next page
   */
  nextPage();
}

export class FsPaging implements IFsPaging {
  filter: FsTableFilter[];
  columns: QueryFields;
  isEnabled: boolean;
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortOrder: string | 'ascend' | 'descend' | null;
  totalItems: number;

  constructor(
    isEnabled: boolean,
    pageIndex: number = DEFAULT_PAGE_INDEX,
    pageSize: number = DEFAULT_PAGE_SIZE,
    totalItems: number = DEFAULT_TOTAL_NO_OF_ITEMS
  ) {
    this.isEnabled = isEnabled;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.totalItems = totalItems;

    this.filter = [];
    this.columns = [];
  }

  resetFilter() {
    this.filter.length = 0;
  }

  resetSort() {
    this.sortField = '';
    this.sortOrder = null;
  }

  nextPage() {
    const numberOfItemsLoaded = this.pageIndex * this.pageSize;

    if (numberOfItemsLoaded >= this.totalItems) {
      return false;
    }

    this.pageIndex++;

    return this.pageIndex;
  }
}

export interface FsTableFilter {
  key: string;
  value: NzTableFilterValue;
  type: FsColumnDataType;
  comparison?: ComparisonOperator;
  meta?: string;
}
