<!-- USE ONLY ONE OF THE COMPONENTS BELOW - TOGGLE formControlName -->
<!-- Template-Driven Forms -->
<nz-cascader
  *ngIf="!controlName"
  [ngModel]="model"
  [id]="inputId"
  [nzOptions]="options | async"
  [nzSize]="size"
  [nzChangeOn]="valueOnChangesProcessor.bind(this)"
  [nzPlaceHolder]="placeholder"
  [nzDisabled]="isDisabled"
  [nzLoadData]="loadData.bind(this)"
  [nzShowSearch]="searchConfig"
  [required]="isRequired"
  [nzAllowClear]="allowClear"
  [nzOptionRender]="isLoading.getValue() ? searching : null"
  (input)="search($event.target)"
  (ngModelChange)="onModelChange($event)"
  class="full-width">
</nz-cascader>

<!-- Reactive Forms -->
<ng-container
  *ngIf="controlName"
  [formGroup]="form">
  <nz-cascader
    [formControlName]="controlName"
    [id]="inputId"
    [nzOptions]="options | async"
    [nzSize]="size"
    [nzChangeOn]="valueOnChangesProcessor.bind(this)"
    [nzPlaceHolder]="placeholder"
    [nzDisabled]="isDisabled"
    [nzLoadData]="loadData.bind(this)"
    [nzShowSearch]="searchConfig"
    [nzAllowClear]="allowClear"
    [nzOptionRender]="isLoading.getValue() ? searching : null"
    (input)="search($event.target)"
    (nzSelectionChange)="onModelChange($event)"
    class="full-width">
  </nz-cascader>
</ng-container>

<ng-template #searching>
  <div class="loader">
    <i nz-icon [nzType]="'loading'"></i> Loading items...
  </div>
</ng-template>
