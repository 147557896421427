<div class="question-list">
    <ng-container *ngIf="hasQuestions; else elseTemplate">
      <div *ngFor="let managedQuestion of visibleQuestions; let i = index">
        <ng-container *ngIf="shouldDisplayQuestion(managedQuestion)">
          <div class="question-item">
            <div class="question-index">
              {{ i + 1 }}
            </div>

            <app-answer-sheet-question-answer
              [managedQuestion]="managedQuestion"
              (valueChange)="questionAnswered.emit($event)">
            </app-answer-sheet-question-answer>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #elseTemplate>
      <p>There are no questions available to display in this section</p>
    </ng-template>
</div>
