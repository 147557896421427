<div
  class="attachment"
  nz-col>
  <div nz-row class="no-flex-wrap no-margin-bottom">
    <img
      [src]="attachmentIcon.url"
      class="attachment__icon"
      [ngClass]="{'attachment__icon': attachmentIcon.isIcon, 'attachment__image': !attachmentIcon.isIcon}"
      nz-col/>

    <div class="attachment__details" nz-col>
      <div nz-row class="attachment__details-container">
        <span nz-col class="attachment__details__name">
          {{ attachment.name }}
        </span>

        <div nz-col class="attachment__actions--md-lg">
          <!-- Preview Image Button -->
          <a
            [href]="this.previewUrl"
            target="_blank"
            nz-button
            nzType="default"
            nzSize="large"
            nzShape="circle"
            class="preview-icon">
            <i nz-icon nzType="cloud-download"></i>
          </a>

          <!-- Delete Image Button -->
          <button
            *ngIf="allowDeletion"
            (click)="confirmDeletion()"
            class="delete-btn"
            nz-button
            nzType="default"
            nzSize="large"
            nzShape="circle">
            <i nz-icon nzType="delete"></i>
          </button>
        </div>
      </div>

      <div class="attachment__extra-details">
        <div nz-col>
          <span class="attachment__details__size">
            {{ attachment.sizeInBytes | filesize }}
          </span>

          <span class="attachment__details__form" *ngIf="attachment.formTitle">
            In "{{ attachment.formTitle }}" form
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="attachment__actions--xs-sm">
    <!-- Preview Image Button -->
    <a
      [href]="this.previewUrl"
      target="_blank"
      nz-button
      nzType="default"
      nzSize="large"
      nzShape="circle"
      class="preview-icon">
      <i nz-icon nzType="cloud-download"></i>
    </a>

    <!-- Delete Image Button -->
    <button
      *ngIf="allowDeletion"
      (click)="confirmDeletion()"
      class="delete-btn"
      nz-button
      nzType="default"
      nzSize="large"
      nzShape="circle">
      <i nz-icon nzType="delete"></i>
    </button>
  </div>
</div>
