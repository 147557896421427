import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormAnswerSheetStateService} from '../services/form-answer-sheet-state.service';

@Component({
  selector: 'app-section-answer-sheet',
  templateUrl: './section-answer-sheet.component.html',
  styleUrls: ['./section-answer-sheet.component.scss']
})
export class SectionAnswerSheetComponent {
  @Input()
  public managedSection: IStateManagedSection;

  @Input()
  public excludeSummaryItems: boolean = false;

  @Output()
  public questionAnswered: EventEmitter<any> = new EventEmitter<any>();

  public isLoading: boolean = false;

  constructor(public formAnswerSheetStateService: FormAnswerSheetStateService) {
  }

  public shouldDisplayQuestion(managedQuestion: IStateManagedQuestion) {
    let shouldDisplay = managedQuestion.isDisplayed;

    if (this.excludeSummaryItems) {
      shouldDisplay = shouldDisplay && !this.isSummaryQuestion(managedQuestion);
    }

    return shouldDisplay;
  }

  public isSummaryQuestion(managedQuestion: IStateManagedQuestion): boolean {
    return (managedQuestion.question.type === 'summary'
      || managedQuestion.question.type === 'info'
      || managedQuestion.question.type === 'transfer_summary');
  }

  public get visibleQuestions(): IStateManagedQuestion[] {
    return this.managedSection?.questions?.filter(q => q.isDisplayed) ?? [];
  }

  public get hasQuestions(): boolean {
    const questions = this.visibleQuestions ?? [];
    return questions.length > 0;
  }
}
