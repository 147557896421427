import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, take, tap} from 'rxjs/operators';
import SignaturePad from 'signature_pad';
import {StateManagedQuestion} from 'src/app/shared/answer-sheet/services/state-managed-question';
import {AttachmentHttpService} from 'src/app/shared/attachments/attachment-http.service';
import {FormAnswerSheetStateService} from '../../services/form-answer-sheet-state.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit, OnDestroy {
  // View Children
  @ViewChild('signaturePadHost')
  public signaturePadHostElement: ElementRef;

  // Input params
  @Input()
  public managedQuestion: StateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<IAttachment> = new EventEmitter<IAttachment>();


  // Public properties
  public signaturePad: SignaturePad;
  public isLoading: boolean = false;
  public displayRetryUploadButton = false;
  public canvasWidth: string = '100%';


  // Private properties
  private signaturePadUpdated: Subject<void>;
  private isPristine: boolean = true;
  private currentSignatureAttachment: IAttachment;
  private _subscriptions: Subscription[] = [];

  public get hasAnswer(): boolean {
    return this.managedQuestion.answers.some(a => !!a.signatureIdAnswer);
  }

  public constructor(
    private attachmentHttpService: AttachmentHttpService,
    private messageService: NzMessageService,
    private formAnswerSheetStateService: FormAnswerSheetStateService
  ) {
    this.signaturePadUpdated = new Subject();
  }

  public get displayQuestionRequiredError(): boolean {
    return (
      !this.isPristine
      && !this.isLoading
      && (
        !this.managedQuestion.answers || !this.managedQuestion.answers.length
      )
    );
  }

  @HostListener('window:resize')
  public updateCanvasInnerSize() {
    const nativeCanvasElement = this.signaturePadHostElement?.nativeElement ?? null;

    this.canvasWidth = '100%';

    requestAnimationFrame(() => {
      this.canvasWidth = nativeCanvasElement?.clientWidth ?? this.canvasWidth;
    });
  }

  public ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadHostElement.nativeElement);
    this.initializePadEventMonitors();
    this.updateCanvasInnerSize();
    this.updateAnswerIfAvailable();
  }

  public ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  public clearSignatureCanvas() {
    this.signaturePad.clear();
    this.signaturePadUpdated.next();
  }

  public updateAnswerIfAvailable() {
    requestAnimationFrame(async () => {
      if (this.managedQuestion?.answers?.length) {
        const firstSignatureAnswer = this.managedQuestion.answers[0];
        let signatureAnswer = firstSignatureAnswer.signatureAnswer;

        if (!signatureAnswer?.url && firstSignatureAnswer?.signatureIdAnswer) {
          signatureAnswer = await this.attachmentHttpService.getOne(firstSignatureAnswer.signatureIdAnswer).toPromise();
        }

        const signatureUrl = signatureAnswer?.url;

        if (signatureUrl) {
          const nativeCanvasElement = this.signaturePadHostElement.nativeElement;

          this.signaturePad.fromDataURL(
            signatureUrl,
            {
              width: nativeCanvasElement.clientWidth,
              height: nativeCanvasElement.clientHeight
            }
          );
        }
      }
    });
  }

  public undoLastLine(): void {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
      this.signaturePadUpdated.next();
    }
  }

  public updateSignatureAttachmentAndEmitAnswer() {
    if (this.isEmptySignature()) {
      if (!!this.currentSignatureAttachment) {
        this.requestRemovalOfSignatureAttachment(this.currentSignatureAttachment);
        this.currentSignatureAttachment = null;
      }

      this.valueChange.emit(this.currentSignatureAttachment);

      this.managedQuestion.answers = [];
      return;
    }

    this.isLoading = true;
    const attachmentDto = this.generateAttachmentCreationDtoFromSignature();

    this._subscriptions.push(
      this
        .attachmentHttpService
        .createAttachmentUsingSimpleRequest(attachmentDto)
        .pipe(take(1))
        .subscribe(
          (attachment: IAttachment) => {
            const question = this.managedQuestion.question;

            this.isLoading = false;
            this.displayRetryUploadButton = false;

            if (!!this.currentSignatureAttachment) {
              this.requestRemovalOfSignatureAttachment(this.currentSignatureAttachment);
            }

            this.currentSignatureAttachment = attachment;

            this.managedQuestion.answers = [{
              questionId: question.id,
              questionType: question.type,
              questionPublicId: question.publicId,
              sectionPublicId: question.section.publicId,
              sectionId: question.sectionId,
              signatureIdAnswer: attachment.id,
              signatureAnswer: attachment,
            }];

            this.valueChange.emit(this.currentSignatureAttachment);
          },

          (error: any) => {
            console.error(error);
            this.isLoading = false;
            this.messageService.error('Your signature could not be saved, please try again later');
            this.managedQuestion.answers = [];
            this.displayRetryUploadButton = true;
          }
        )
    );
  }

  private initializePadEventMonitors(): void {
    const signatureUpdatedObservableDebounceTime = 2.5 * 1000;
    let signatureBeingUpdated: boolean = false;

    this.signaturePad.addEventListener('endStroke', () => {
      this.signaturePadUpdated.next();
      signatureBeingUpdated = false;
    });

    const notifySignatureUpdateUntilLineEnd = () => {
      if (signatureBeingUpdated) {
        this.signaturePadUpdated.next();
        setTimeout(
          notifySignatureUpdateUntilLineEnd,
          (signatureUpdatedObservableDebounceTime - 500),
        );
      }
    };

    this.signaturePad.addEventListener('beginStroke', () => {
      signatureBeingUpdated = true;
      notifySignatureUpdateUntilLineEnd();
    });

    this._subscriptions.push(
      this
        .signaturePadUpdated
        .pipe(
          debounceTime(signatureUpdatedObservableDebounceTime),
          tap(() => this.isPristine = false),
        )
        .subscribe(() => {
          this.updateSignatureAttachmentAndEmitAnswer();
        })
    );

    this._subscriptions.push(
      this.formAnswerSheetStateService
        .sectionChanged
        .subscribe((section) => {
          this.updateCanvasInnerSize();
        })
    );
  }

  private isEmptySignature(): boolean {
    const data = this.signaturePad.toData();
    return !data || !data.length;
  }

  private generateAttachmentCreationDtoFromSignature(): IAttachmentCreationDto {
    const signatureBlob = this.convertSignatureToBlob();
    return {
      name: 'signature.png',
      file: signatureBlob,
    };
  }

  private convertSignatureToBlob(): Blob {
    const dataUrl = this.signaturePad.toDataURL();
    const parts = dataUrl.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  private requestRemovalOfSignatureAttachment(
    signatureAttachment: IAttachment,
  ): void {
    this._subscriptions.push(
      this
        .attachmentHttpService
        .delete(signatureAttachment)
        .subscribe(() => {
        }, console.error)
    );
  }
}
