<div class="fs-multiple-choice-input" [class.row-wrap]="shouldWrapOptions">

  <ng-container *ngIf="managedQuestion.question.configuration.displayMultipleChoiceOptionsAs === 'buttons'; else dropdown">

    <ng-container *ngIf="managedQuestion.question.configuration.allowMultipleAnswers; else singleAnswer">
      <nz-form-control [nzErrorTip]="errorTpl">
        <div class="option" *ngFor="let control of checkboxFormControls; index as i">
          <input
            type="checkbox"
            [formControl]="control"
            [id]="managedQuestion.question.options[i].id"
            [name]="managedQuestion.question.options[i].id">
          <label [for]="managedQuestion.question.options[i].id">
            {{ managedQuestion.question.options[i].title }}
          </label>
        </div>
        <ng-template #errorTpl>
          <div class="error form-control-msg" *ngIf="checkboxFormArray.hasError('required')">
            This question is required
          </div>
        </ng-template>
      </nz-form-control>
    </ng-container>

    <ng-template #singleAnswer>
      <div class="option" *ngFor="let option of managedQuestion.question.options">
        <input
          type="radio"
          [formControl]="radioAndSelectFormControl"
          [id]="option.id"
          [name]="managedQuestion.question.id"
          [value]="option.id">
        <label [for]="option.id">
          {{ option.title }}
        </label>
      </div>
    </ng-template>

  </ng-container>

  <ng-template #dropdown>
    <nz-form-control [nzErrorTip]="errorTpl">
      <nz-select
        nzSize="large"
        [formControl]="radioAndSelectFormControl"
        [nzMode]="managedQuestion.question.configuration.allowMultipleAnswers? 'multiple' : 'default'">
        <nz-option
          *ngFor="let option of managedQuestion.question.options"
          [nzValue]="option.id"
          [nzLabel]="option.title">
        </nz-option>
      </nz-select>
      <ng-template #errorTpl>
        <div class="error form-control-msg" *ngIf="radioAndSelectFormControl.hasError('required')">
          This question is required
        </div>
      </ng-template>
    </nz-form-control>
  </ng-template>

</div>
