export enum QuestionTypes {
  apiCall = 'api_call',
  attachment = 'attachment',
  date = 'date',
  duration = 'duration',
  gps = 'gps',
  itemSelection = 'item_selection',
  locationSelection = 'location_selection',
  multipleChoice = 'multiple_choice',
  number = 'number',
  quantity = 'quantity',
  signature = 'signature',
  takeMedia = 'take_media',
  textArea = 'text_area',
  textInput = 'text_input',
  yesOrNo = 'yes_or_no',
  timer = 'timer',
  info = 'info',
  summary = 'summary',
  scanQrBarcode = 'scan_qr_barcode',
  transferSummary = 'transfer_summary'
}
