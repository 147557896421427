import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import {NzUploadComponent, NzUploadXHRArgs} from 'ng-zorro-antd/upload';
import { Subscription } from 'rxjs';
import { AttachmentHttpService } from 'src/app/shared/attachments/attachment-http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-attachment-uploader',
  templateUrl: './attachment-uploader.component.html',
  styleUrls: ['./attachment-uploader.component.scss']
})
export class AttachmentUploaderComponent implements OnInit {

  @Input()
  public surveyAnswerId: string;

  @Input()
  public jobId: string;

  @Input()
  public allowedAttachmentTypes: AttachmentType[];

  public allowedFileTypes: string[];

  @Output()
  public attachmentCreated: EventEmitter<IAttachment> = new EventEmitter();

  @ViewChild(NzUploadComponent)
  public uploader: NzUploadComponent;

  constructor(
    public attachmentCrudService: AttachmentHttpService,
    private messageService: NzMessageService,
  ) { }

  public ngOnInit(): void {
    this.initializeAllowedFileTypes();
  }

  private initializeAllowedFileTypes(): void {
    if (!this.allowedAttachmentTypes) {
      this.allowedFileTypes = [
        ...environment.imageFileTypes,
        ...environment.audioFileTypes,
        ...environment.videoFileTypes,
        ...environment.documentFileTypes,
        ...environment.archiveFileTypes,
      ];
    } else {
      this.allowedFileTypes = [];
      if (this.allowedAttachmentTypes.includes('image') || this.allowedAttachmentTypes.includes('photo')) {
        this.allowedFileTypes.push(...environment.imageFileTypes);
      }

      if (this.allowedAttachmentTypes.includes('audio')) {
        this.allowedFileTypes.push(...environment.audioFileTypes);
      }

      if (this.allowedAttachmentTypes.includes('video')) {
        this.allowedFileTypes.push(...environment.videoFileTypes);
      }

      if (this.allowedAttachmentTypes.includes('document')) {
        this.allowedFileTypes.push(...environment.documentFileTypes);
      }

      if (this.allowedAttachmentTypes.includes('archive')) {
        this.allowedFileTypes.push(...environment.archiveFileTypes);
      }
    }
  }

  public get antUploadRequest(): (item: NzUploadXHRArgs) => Subscription {
    return (item: NzUploadXHRArgs): Subscription => {
      return this
        .attachmentCrudService
        .assembleCreationHTTPRequest({
          // @ts-ignore
          file: item.file,
          name: item.file.name,
          ...(!!this.jobId ? { jobId: this.jobId } : {}),
          ...(!!this.surveyAnswerId ? { surveyAnswerId: this.surveyAnswerId } : {}),
        })
        .subscribe((event: HttpEvent<{}>) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total > 0) {
              (event as any).percent = event.loaded / event.total * 100;
            }
            item.onProgress(event, item.file);

          } else if (event instanceof HttpResponse) {
            const attachment = event.body as IAttachment;
            const fileReader = new FileReader();

            fileReader.onload = () => {
              attachment.thumbnail = fileReader.result as string;

              this.attachmentCreated.emit(attachment);
              this.messageService.success(`"${attachment.name}" attachment uploaded.`);

              // notify ant upload component it all went well
              item.onSuccess(attachment, item.file, event);
            };

            fileReader.readAsDataURL(item.postFile as Blob);
          }
        }, (err) => {
          this.messageService.error(`"${item.name}" attachment upload failed.`);

          // notify ant upload component of failure
          item.onError(err, item.file);
        });
    };
  }

}
