import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { StateManagedQuestion } from 'src/app/shared/answer-sheet/services/state-managed-question';
import { AttachmentHttpService } from 'src/app/shared/attachments/attachment-http.service';

@Component({
  selector: 'app-media-attachemnt',
  templateUrl: './media-attachment.component.html',
  styleUrls: ['./media-attachment.component.scss']
})
export class MediaAttachmentComponent implements OnInit, OnDestroy {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<IAttachment[]> = new EventEmitter<IAttachment[]>();

  public attachments: IAttachment[] = [];
  public isPristineAttachments: boolean = true;

  private subscriptions: Subscription[] = [];

  public get allowedAttachmentTypes(): AttachmentType[] {
    return this.managedQuestion.question.type === 'attachment' ?
      this.managedQuestion.question.configuration.allowedAttachmentFileTypes
      : this.managedQuestion.question.configuration.allowedTakeMediaFileTypes;
  }

  public constructor(
    private attachmentsHttpService: AttachmentHttpService,
    private messageService: NzMessageService,
  ) { }

  public ngOnInit(): void {
    this.attachments = this
      .managedQuestion
      .answers
      .filter(answer => !!answer.attachmentAnswer)
      .map(answer => answer.attachmentAnswer);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public addAttachment(newAttachment: IAttachment): void {
    this.attachments.push(newAttachment);
    this.updateAttachmentsAnswer();
  }

  private updateAttachmentsAnswer(): void {
    const question = this.managedQuestion.question;

    this.isPristineAttachments = false;

    this.managedQuestion.answers = this.attachments.map((attachment) => ({
      questionId: question.id,
      questionType: question.type,
      questionPublicId: question.publicId,
      sectionPublicId: question.section.publicId,
      sectionId: question.sectionId,
      attachmentIdAnswer: attachment.id,
      attachmentAnswer: attachment
    }));

    this.valueChange.emit(this.attachments);
  }

  public deleteAttachment(attachmentToDelete: IAttachment): void {
    const subscription = this
      .attachmentsHttpService
      .delete(attachmentToDelete)
      .subscribe(
        () => {
          this.attachments = this.attachments.filter(
            (attachment) => attachment !== attachmentToDelete
          );
          this.updateAttachmentsAnswer();
        },
        () => {
          this.messageService.error('Unable to delete attachment, please try again');
        }
      );
    this.subscriptions.push(subscription);
  }

}
