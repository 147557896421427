import {Injectable} from '@angular/core';
import {CondOperator, CreateQueryParams} from '@nestjsx/crud-request';
import {QueryFilter} from '@nestjsx/crud-request/lib/types';
import {ComparisonOperator} from '@nestjsx/crud-request/lib/types/request-query.types';
import {cloneDeep} from 'lodash';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import {map, tap} from 'rxjs/operators';
import {FsCascaderAbstractService} from '../../shared/fs-cascader/fs-cascader-abstract.service';
import {CascadeLoadDataRequest} from '../../shared/fs-cascader/fs-cascader.component';
import {LocationHttpService} from './location-http.service';
import {LocationTypeHttpService} from './location-type-http.service';

@Injectable()
export class LocationCascaderService extends FsCascaderAbstractService<ILocationType, ILocation> {
  constructor(
    private locationTypeHttpService: LocationTypeHttpService,
    private locationHttpService: LocationHttpService
  ) {
    super('locationTypeId');
  }

  public loadLocationTypeOptions(): Promise<NzCascaderOption[]> {
    const query: CreateQueryParams = {
      filter: [{
        field: 'isActive',
        operator: CondOperator.EQUALS,
        value: true,
      }],
      sort: {field: 'name', order: 'ASC'},
    };
    const extraParams: ISimpleParamWrapper = {
      injectStatistics: 'true'
    };

    if (this.isEditing.getValue()) {
      delete query.filter[0];
    }

    if (this.entityTypeFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityTypeFilters
      ];
    }

    this.isLoading.next(true);

    return this.locationTypeHttpService
      .get(query, extraParams)
      .pipe(
        map((response: IPagination<ILocationType>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          // Set the location type options
          this.replaceLocationTypeOptions(options);

          // Backup options for reverting after search
          this.backupLocationTypeOptions();

          this.isLoading.next(false);
        }))
      .toPromise();
  }

  public loadLocationsForLocationType(locationTypeId: string): Promise<NzCascaderOption[]> {
    const query: CreateQueryParams = {
      filter: [
        {
          field: 'locationTypeId',
          operator: CondOperator.EQUALS,
          value: locationTypeId,
        }, {
          field: 'parentId',
          operator: CondOperator.IS_NULL,
        }, {
          field: 'isActive',
          operator: CondOperator.EQUALS,
          value: true,
        }
      ],
      sort: {field: 'name', order: 'ASC'},
      join: [
        {field: 'tags'}
      ]
    };
    const extraParams: ISimpleParamWrapper = {
      injectStatistics: 'true'
    };

    this.isLoading.next(true);

    return this.locationHttpService
      .get(query, extraParams)
      .pipe(
        map((response: IPagination<ILocation>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[] = []) => {
          // Set the location options
          this.replaceLocationTypeOptions(options);

          // Backup options for reverting after search
          this.backupLocationTypeOptions();

          this.isLoading.next(false);
        })
      ).toPromise();
  }

  public loadLocationsForParentOption(request: CascadeLoadDataRequest): Promise<NzCascaderOption[]> {
    const option = request.option;
    const parentData = request.option.data as ILocation;
    let query: CreateQueryParams = {};
    const extraParams: ISimpleParamWrapper = {
      injectStatistics: 'true'
    };

    if (!option || !option.value) {
      if (option.loading) {
        requestAnimationFrame(() => {
          option.loading = false;
          request.callback();
        });
      }

      return;
    }

    if (parentData && !parentData.locationTypeId) {
      query = {
        filter: [
          {
            field: 'locationTypeId',
            operator: CondOperator.EQUALS,
            value: parentData.id,
          }, {
            field: 'parentId',
            operator: CondOperator.IS_NULL,
          }, {
            field: 'isActive',
            operator: CondOperator.EQUALS,
            value: true,
          }
        ],
        sort: {field: 'name', order: 'ASC'},
        join: [
          {field: 'tags'}
        ]
      };

      if (this.isEditing.getValue()) {
        delete query.filter[2];
      }
    } else {
      query = {
        filter: [{
          field: 'parentId',
          operator: CondOperator.EQUALS,
          value: option.value,
        }, {
          field: 'isActive',
          operator: CondOperator.EQUALS,
          value: true,
        }],
        sort: {field: 'name', order: 'ASC'},
        join: [
          {field: 'tags'}
        ]
      };

      if (this.isEditing.getValue()) {
        delete query.filter[1];
      }
    }


    if (this.entityFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityFilters
      ];
    }

    return this.locationHttpService
      .get(query, extraParams)
      .pipe(
        map((response: IPagination<ILocation>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          option.children = options;

          if (!options.length) {
            option.disabled = true;
          } else if (option.disabled) {
            option.disabled = false;
          }

          if (request.callback) {
            request.callback(options);
          }
        }))
      .toPromise();
  }

  public searchLocationTypeOptions(search: string): Promise<NzCascaderOption[]> {
    // Private flag _showingSearchResults used to prevent location types trees from being overwritten unnecessarily
    if (!search && this._showingSearchResults.getValue()) {
      this.restoreRootOptionsBackup();

      this.isLoading.next(false);
      this._showingSearchResults.next(false);

      return;
    }

    const query: CreateQueryParams = {
      filter: [{
        field: 'name',
        operator: CondOperator.CONTAINS_LOW,
        value: search
      }],
      sort: {
        field: 'name',
        order: 'ASC'
      },
      join: [
        {field: 'tags'}
      ]
    };

    this._showingSearchResults.next(true);

    if (this.entityTypeFilters.length) {
      const entityTypeIdsFilter = this.entityTypeFilters.map(f => f.value);
      const fixedIdsFilterArray = Array.isArray(entityTypeIdsFilter[0])
        ? entityTypeIdsFilter[0]
        : entityTypeIdsFilter;

      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...[{
          field: this._parentIdSelector,
          operator: '$in' as ComparisonOperator,
          value: fixedIdsFilterArray
        }]
      ];
    }

    if (this.entityFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityFilters
      ];
    }

    return this.locationHttpService
      .get(query)
      .pipe(
        map((response: IPagination<ILocation>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          this.isLoading.next(false);

          // Replace location type options with the search results (temporary)
          this.replaceLocationTypeOptions(options);
        }))
      .toPromise();
  }

  public setHierarchy(
    option: NzCascaderOption,
    isDisabled?: (location: ILocationType | ILocation) => boolean,
    useTypeAsRoot: boolean = true
  ): Promise<string[]> {
    return this.locationHttpService
      .fetchSuperHierarchies(option.value, useTypeAsRoot)
      .pipe(
        map(hierarchy => {
          const selected = [];
          let node = hierarchy;

          while (node) {
            const clone = cloneDeep(node) as any;

            node = clone.parent;
            delete clone.parent;

            selected.push(clone);
          }

          selected.reverse();

          if (selected.length) {
            const output = this.convertHierarchicalModelArrayToCascaderOptions(
              [selected[0]],
              {
                isDisabled: !!isDisabled ? isDisabled(selected[0]) : false
              })[0];
            let cascaderOptionNode = output;

            for (let i = 0; i < selected.length - 1; i++) {
              const childItem = selected[i + 1];

              cascaderOptionNode.children = this.convertHierarchicalModelArrayToCascaderOptions(
                [childItem],
                {
                  isDisabled: !!isDisabled ? isDisabled(selected[0]) : false
                });

              cascaderOptionNode = cascaderOptionNode.children[0];
            }

            this.restoreRootOptionsBackup(true, output);
          }

          return selected.map(s => s.id);
        })
      )
      .toPromise();
  }
}
