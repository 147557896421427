<nz-form-control [nzErrorTip]="errorTpl">

  <textarea class="fs-textarea-input" rows="4" nz-input [formControl]="answer"></textarea>

  <ng-template #errorTpl>
    <div class="error form-control-msg" *ngIf="answer.hasError('required')">
      This question is required
    </div>
  </ng-template>

</nz-form-control>
