import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { LocationCascaderService } from '../../locations/services/location-cascader.service';
import { FsCascaderComponent } from '../../shared/fs-cascader/fs-cascader.component';

@Component({
  selector: 'app-location-hard-link-selector',
  templateUrl: './location-hard-link-selector.component.html',
  styleUrls: ['./location-hard-link-selector.component.scss'],
  providers: [
    LocationCascaderService
  ]
})
export class LocationHardLinkSelectorComponent implements OnInit {
  // Input Params
  @Input()
  public configuration: ILocationHardLinkConfiguration;

  @Input()
  public initialValues?: string[];

  // Output Events
  @Output()
  public locationsSelected: EventEmitter<string[]> = new EventEmitter();

  // View Children
  @ViewChild(FsCascaderComponent) cascader: FsCascaderComponent;
  // Public Properties
  public selectedLocation: NzCascaderOption | Array<NzCascaderOption>;

  public constructor(public locationCascaderService: LocationCascaderService) {
  }

  get isValid(): boolean {
    return !this.cascader?.hasRequiredError
    && this.selectedLocation
    && Array.isArray(this.selectedLocation)
      ? !this.selectedLocation[0].isLeaf
      : !(this.selectedLocation as NzCascaderOption).isLeaf;
  }

  // Page Events
  public async ngOnInit(): Promise<void> {
    if (this.configuration.locationTypesAllowed?.length) {
      this.locationCascaderService.addEntityTypeFilter({
        field: 'id',
        operator: '$in',
        value: this.configuration.locationTypesAllowed
      });
    }

    if (this.configuration.locationSelectionTagIds?.length) {
      this.locationCascaderService.addEntityFilter({
        field: 'tags.id',
        operator: '$in',
        value: this.configuration.locationSelectionTagIds
      });
    }

    await this.locationCascaderService.loadLocationTypeOptions();

    if (this.initialValues) {
      this.selectedLocation = [...this.initialValues];
    }
  }

  // Public Methods
  public handleLocationUpdate(item: NzCascaderOption | string | Array<NzCascaderOption | string>): void {
    if (Array.isArray(item)) {
      this.locationsSelected.emit(item.map(i => {
        if (typeof i === 'string') {
          return i;
        } else {
          return i.value;
        }
      }));
    } else {
      this.locationsSelected.emit([typeof item === 'string' ? item : item.value]);
    }
  }

  public setHierarchyForLocation(option: NzCascaderOption) {
    this.locationCascaderService.setHierarchy(option)
      .then(selected => {
        this.selectedLocation = selected;

        this.handleLocationUpdate(option);
      });
  }
}
