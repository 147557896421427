export const ParseTextWithUrls = (text: string): string => {
  if (!text) {
    return '';
  }

  const linkRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
  const urls = text.match(linkRegex);
  let output = text;

  if (!urls) {
    return text;
  }

  urls.forEach(url => {
    const originalUrl = url;

    if (url.indexOf('http') < 0) {
      url = `http://${url}`;
    }

    output = output.replace(originalUrl, `<a href="${url}" target="_blank">${url.replace('http://', '').replace('https://', '')}</a>`);
  });

  return output;
};
