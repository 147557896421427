export class FormVersionAnswerSheetFactory {
  protected constructor() {
  }

  public static create(form: IForm, formVersion: IFormVersion): IFormVersionAnswerSheet {
    const allQuestions = formVersion.sections.flatMap(section => {
      return section.questions.map(q => ({...q, section}));
    });

    formVersion.form = form;

    return {
      formVersionId: formVersion.id,
      formVersion,
      itemFieldsAnswerSheets: [],
      questionAnswers: allQuestions.map(q => this.mapToEmptyQuestionAnswer(formVersion, q))
    };
  }

  private static mapToEmptyQuestionAnswer(formVersion: IFormVersion, question: IQuestion): IQuestionAnswer {
    const section = question.section;

    return {
      questionType: question.type,
      questionPublicId: question.publicId,
      questionId: question.id,
      question,
      sectionPublicId: section.publicId,
      sectionId: section.id,
      section,
      formVersion,
      itemFieldsAnswerSheet: section.type === 'item_fields'
        ? { sectionId: section.id }
        : undefined,
    };
  }
}
