import {BehaviorSubject, Observable} from 'rxjs';
import {IAbstractStateService} from './abstract-state.service.interface';

export abstract class AbstractStateService<T> implements IAbstractStateService<T> {
  _state: BehaviorSubject<T>;

  get listener(): Observable<T> {
    return this._state.asObservable();
  }
  get state(): T {
    return this._state.getValue();
  }

  protected constructor() {
    this._state = new BehaviorSubject<T>(this.initializeState());
  }

  abstract initializeState(): T;

  public updateState(state: Partial<T>): void {
    this._state.next({
      ...this.state,
      ...state
    });
  }

  public updateStateAsync(state: Partial<T>): void {
    requestAnimationFrame(() => {
      this._state.next({
        ...this.state,
        ...state
      });
    });
  }

  public reset(except?: (keyof T)[]) {
    const resetObject = this.initializeState();

    if (except) {
      for (const key of except) {
        if (resetObject.hasOwnProperty(key)) {
          delete resetObject[key];
        }
      }
    }

    this.updateState(resetObject);
  }
}
