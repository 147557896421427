<ng-container *ngIf="state.isLoading; else content">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-container>

<ng-template #content>
  <div *ngIf="state.managedForm?.sections?.length" class="form-header">
    {{ state.managedForm.formTitle }} - {{ state.managedForm.completion | percent }}
  </div>

  <nz-tabset [nzAnimated]="false" (nzSelectChange)="handleSectionTabChange($event)">
    <!-- Answer Sheet Sections -->
    <nz-tab
      *ngFor="let managedSection of state.managedForm?.sections; index as sectionIndex"
      nzTitle="{{ (sectionIndex + 1) + '. ' + managedSection.sectionTitle }} - {{ managedSection.completion | percent }}">

      <ng-container *ngIf="state.isTabLoading; else answerSheet">
        <nz-skeleton [nzActive]="true"></nz-skeleton>
      </ng-container>

      <ng-template #answerSheet>
        <app-section-answer-sheet
          *ngIf="managedSection.sectionType === SectionType.Form"
          [managedSection]="managedSection"
          (questionAnswered)="questionAnswered.emit($event)"
          [excludeSummaryItems]="excludeSummaryItems">
        </app-section-answer-sheet>

        <app-item-fields
          *ngIf="managedSection.sectionType === SectionType.ItemFields"
          [managedSection]="managedSection"
          (valueChange)="itemFieldsAnswered.emit($event)">
        </app-item-fields>

        <app-transfer-summary
          *ngIf="managedSection.sectionType === SectionType.TransferSummary"
          [managedSection]="managedSection"
          (questionAnswered)="questionAnswered.emit($event)">
        </app-transfer-summary>
      </ng-template>

      <div *ngIf="showNavigationButtons" nz-row class="tab-footer">
        <div nz-col class="tab-footer-action">
          <button
            *ngIf="sectionIndex > 0"
            id="formAnswerSheet__previousAnswerSheetSectionBtn"
            (click)="navigateToTab(sectionIndex - 1, $event)"
            class="button-lg"
            nz-button
            nzSize="large"
            nzType="default">
            <i nz-icon nzType="left"></i>
            Previous: {{state.managedForm.sections[sectionIndex - 1].sectionTitle}}
          </button>
        </div>

        <div nz-col class="tab-footer-action">
          <button
            *ngIf="(sectionIndex + 1) < state.managedForm.sections.length"
            id="formAnswerSheet__nextAnswerSheetSectionBtn"
            (click)="navigateToTab(sectionIndex + 1, $event)"
            class="button-lg"
            nz-button
            nzSize="large"
            nzType="default">
            <i nz-icon nzType="right"></i>
            Next: {{state.managedForm.sections[sectionIndex + 1].sectionTitle}}
          </button>
        </div>
      </div>
    </nz-tab>
  </nz-tabset>
</ng-template>
