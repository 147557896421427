import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent {
  @Input()
  public attachments: IAttachment[];

  @Input()
  public allowDeletion: boolean = true;

  @Input()
  public deletableAttachments: boolean = false;

  @Output()
  public deleteAttachment: EventEmitter<IAttachment> = new EventEmitter<IAttachment>();
}
