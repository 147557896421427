import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SharedModule } from 'src/app/shared/shared.module';
import { SettingsSideNavigationMenuComponent } from './settings-side-navigation-menu/settings-side-navigation-menu.component';
import { SettingsPageComponent } from './settings.component';
import { SettingsRoutingModule } from './settings.routing.module';

@NgModule({
  declarations: [
    SettingsPageComponent,
    SettingsSideNavigationMenuComponent,
  ],
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    RouterModule,

    // NZ Modules
    NzButtonModule,
    NzLayoutModule,
    NzPageHeaderModule,
    NzCheckboxModule,
    NzTimePickerModule,
    NzDividerModule,
    NzMessageModule,
    NzIconModule,
    NzTypographyModule,

    // Shared Modules
    SharedModule,
    SettingsRoutingModule
  ]
})
export class SettingsPageModule { }
