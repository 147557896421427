import {Injectable} from '@angular/core';
import {AbstractStateService} from '../../../../core/state-management/abstract-state.service';
import {sortByValue} from '../../../../shared/helpers/list.helper';

@Injectable({providedIn: 'root'})
export class WebhookStateService extends AbstractStateService<IWebhookState> {
  public eventMap: Map<string, IWebhookEvent> = new Map<string, IWebhookEvent>();

  public initializeState(): IWebhookState {
    return {
      webhookSettings: [],
      webhookEvents: [],
      webhookTransactions: [],
      showCreationModal: false,
      showJsonResponseModal: false,
      showTransactions: false,
      isSavingWebhook: false,
      isRemovingWebhook: false,
      isRetryingWebhook: false,
      isLoadingWebhooks: true,
      isLoadingTransactions: false,
      selectedWebhookSetting: null,
      selectedWebhookTransaction: null
    };
  }

  public toggleShowCreationModal(showCreationModal: boolean) {
    this.updateState({showCreationModal});
  }

  public toggleShowJsonResponseModal(showJsonResponseModal: boolean) {
    this.updateState({showJsonResponseModal});
  }

  public toggleShowTransactions(showTransactions: boolean) {
    this.updateState({showTransactions});
  }

  public toggleIsLoadingWebhooks(isLoadingWebhooks: boolean) {
    this.updateState({isLoadingWebhooks});
  }

  public toggleIsLoadingTransactions(isLoadingTransactions: boolean) {
    this.updateState({isLoadingTransactions});
  }

  public toggleIsSavingWebhook(isSavingWebhook: boolean) {
    this.updateState({isSavingWebhook});
  }

  public toggleIsRemovingWebhook(isRemovingWebhook: boolean) {
    this.updateState({isRemovingWebhook});
  }

  public toggleIsRetryingWebhook(isRetryingWebhook: boolean) {
    this.updateState({isRetryingWebhook});
  }

  public setWebhookEvents(webhookEvents: IWebhookEvent[] = []) {
    this.updateState({webhookEvents});

    this.eventMap = new Map<string, IWebhookEvent>(
      webhookEvents.map(e => [e.id, e])
    );
  }

  public setWebhookSettings(webhooks: IWebhookSetting[] = []) {
    this.updateState({webhookSettings: webhooks});
  }

  public setWebhookTransactions(webhookTransactions: IWebhookTransaction[] = []) {
    this.updateState({webhookTransactions});
  }

  public setSelectedWebhookSetting(webhookSetting: IWebhookSetting) {
    this.updateState({selectedWebhookSetting: webhookSetting});
  }

  public setSelectedWebhookTransaction(webhookTransaction: IWebhookTransaction) {
    webhookTransaction.parsedLastRequest = webhookTransaction.lastRequest;

    if (webhookTransaction.lastResponse) {
      webhookTransaction.parsedLastResponse = webhookTransaction.lastResponse;
      webhookTransaction.parsedLastResponse.method = 'POST';
    }

    this.updateState({selectedWebhookTransaction: webhookTransaction});
  }

  public resetSelectedWebhookSetting() {
    this.updateState({selectedWebhookSetting: null});
  }

  public resetSelectedWebhookTransaction() {
    this.updateState({selectedWebhookTransaction: null});
  }

  public getWebhookEvent(eventId: string) {
    return this.eventMap.get(eventId) ?? null;
  }

  public addWebhookSetting(webhookSetting: IWebhookSetting) {
    const existingIndex = this.state.webhookSettings
      .findIndex(w => w.id === webhookSetting.id);

    if (existingIndex < 0) {
      this.setWebhookSettings([
        ...this.state.webhookSettings,
        webhookSetting
      ]);
    }
  }

  public updateWebhookSetting(webhookSetting: IWebhookSetting) {
    const existingWebhookSetting = this.state.webhookSettings
      .find(w => w.id === webhookSetting.id);

    if (existingWebhookSetting) {
      this.setWebhookSettings([
        ...this.state.webhookSettings.filter(w => w.id !== webhookSetting.id),
        webhookSetting
      ]);
    }
  }

  public updateWebhookTransaction(webhookTransaction: IWebhookTransaction) {
    const existingWebhookTransaction = this.state.webhookTransactions
      .find(w => w.id === webhookTransaction.id);

    if (this.state.selectedWebhookTransaction?.id === webhookTransaction.id) {
      this.state.selectedWebhookTransaction.lastEventStatusCode = webhookTransaction.lastEventStatusCode;
    }

    if (existingWebhookTransaction) {
      this.setWebhookTransactions([
        ...this.state.webhookTransactions.filter(w => w.id !== webhookTransaction.id),
        webhookTransaction
      ]);

      this.state.webhookTransactions.sort((a, b) => sortByValue(b.createdAt, a.createdAt));
    }
  }

  public removeWebhookSetting(webhook: IWebhookSetting): void {
    const filteredWebhooks = this.state
      .webhookSettings
      .filter(w => w.id !== webhook.id);

    this.setWebhookSettings(filteredWebhooks);
  }
}
