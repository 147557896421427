import {IFsPaging} from '../../../fs-table/fs.paging';

export interface IItemFieldsState {
  /***
   * If there is a location that all items belong to, set it here
   */
  location?: ILocation;

  /***
   * The statuses used to filter items list
   */
  statuses: ISelectOption[];

  /***
   * The list of items
   */
  items: IItem[];

  /***
   * The filtered list of items (with search filter and status applied)
   */
  filteredItems: IItem[];

  /***
   * The list of unit options for the selected item
   */
  unitOptions: ISelectOption[];

  /***
   * This is the list of questions defined on the form and behaves as a blueprint for generating question answers
   * This shouldn't change after being initially set
   */
  questionsBlueprint: IStateManagedQuestion[];

  /***
   * These are the collection of questions per ItemUnit selected
   */
  itemSelectionConfigs: IItemSelectionConfig[];

  /***
   * The questions for the selected ItemUnit
   */
  selectedItemSelectionConfig: IItemSelectionConfig;

  /***
   * Paging object for items list
   */
  itemsPaging: IFsPaging;

  /***
   * The active selected item
   */
  selectedItem: IItem;

  /***
   * The active selected item
   */
  selectedItemUnit: IItemUnit;

  /***
   * The selected status type to filter
   * This matches the mobile applications style of status filter selection
   */
  status: StatusType;

  /***
   * Items loading state
   */
  isLoadingItems: boolean;

  /***
   * ItemUnit collection loading state
   */
  isLoadingItemUnits: boolean;

  /***
   * Subtotal for Item (all contained unit quantities)
   */
  itemSubtotal: number;

  /***
   * Total for all items
   */
  itemsTotal: number;

  /***
   * Structural configuration of the item fields component
   */
  itemFieldsConfig: {
    /***
     * Enabled/Disable the item subtotal
     */
    showLineItemSubtotal: boolean,

    /***
     * Enabled/Disable the item fields total (SUM of subtotals)
     */
    showTotal: boolean,

    /***
     * Enabled/Disable the item unit cost info
     */
    showCostInfo: boolean,

    /***
     * Enabled/Disable the item unit price info
     */
    showPriceInfo: boolean,

    /***
     * Enabled/Disable the item unit available quantity
     */
    showAvailableInfo: boolean
  };
}

export interface IPagedSearchDto {
  /***
   * The search term used for paged search against the @nestjs/crud-request library
   */
  search?: string;

  /***
   * The FS paging object which tracks paging, sorting, and filtering concerns
   */
  paging: IFsPaging;
}

export interface IItemSelectionConfig {
  /***
   * The selected Item
   */
  item: IItem;

  /***
   * The selected location
   */
  location: ILocation;

  /***
   * Whether the item and it's selected item unit configurations are valid or not (validation)
   */
  valid?: boolean;

  /***
   * The contained item unit configurations on the selected item
   */
  itemUnits?: IItemUnitConfig[];
}

export interface IItemUnitConfig {
  /***
   * The form version answer sheet that the item unit question answers apply to
   */
  formVersionAnswerSheetId: string;

  /***
   * The item fields answer sheet that the item unit question answers apply to
   */
  itemFieldsAnswerSheetId?: string;

  /***
   * The unit that the item unit is representing
   */
  unitId: string;

  /***
   * The actual item unit object containing price and quantity information
   */
  itemUnit: IItemUnit;

  /***
   * The calculated subtotal of the item unit selected quantity * price
   */
  subtotal: number;

  /***
   * The questions pertinent to this item unit configuration selection
   */
  questions?: IStateManagedQuestion[];
}

export enum StatusType {
  all = 'all',
  selected = 'selected',
  partiallyComplete = 'partiallyComplete',
  incomplete = 'incomplete'
}
