import {Routes} from '@angular/router';
import {AuthenticationGuard} from './core/authentication/authentication.guard';
import {AccessDeniedComponent} from './core/error-pages/access-denied/access-denied.component';
import {OrganizationSettingsResolver} from './organisation/settings/organization-settings.resolver';
import {PublicSurveyAnswerFormComponent} from './public-survey/public-survey-answer-form.component';

export const ROUTES: Routes = [
  {
    path: 'public',
    children: [
      {
        path: 'survey/:surveyId',
        component: PublicSurveyAnswerFormComponent
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'job-categories'
  },
  {
    path: 'maps',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'job-categories',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule)
  },
  {
    path: 'location-types',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule)
  },
  {
    path: 'stock-transfer-types',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./stock-transfers/stock-transfers.module').then(m => m.StockTransfersModule)
  },
  {
    path: 'payments',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'item-types',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./items/items.module').then(m => m.ItemsModule)
  },
  {
    path: 'customer-types',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'surveys',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule)
  },
  {
    path: 'settings',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'users',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'schedule',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule)
  },
  {
    path: 'my-profile',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'organisation',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./organisation/organisation.module').then(m => m.OrganisationModule),
    resolve: {
      events: OrganizationSettingsResolver
    }
  },
  {
    path: 'tenant-reports',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./tentant-specific-reports/tentant-specific-reports.module').then(m => m.TentantSpecificReportsModule)
  },
  {
    path: 'tenant-reports',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./tentant-specific-reports/tentant-specific-reports.module').then(m => m.TentantSpecificReportsModule)
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: '**',
    redirectTo: 'job-categories'
  },
];
