import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule} from '@angular/router';
import {ROUTES} from './routes';

const routingOptions: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, routingOptions)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
