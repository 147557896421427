<ng-container *ngFor="let itemUnitConfig of state.selectedItemSelectionConfig?.itemUnits; let index = index">
  <div class="item-detail">
    <!-- Unit of Measure -->
    <nz-form-item nz-row>
      <nz-form-label nzRequired nzSpan="24">Unit of measure</nz-form-label>

      <nz-form-control nz-col nzSpan="24">
        <app-fs-select
          [(model)]="itemUnitConfig.unitId"
          [isDisabled]="!state.unitOptions.length"
          [isReactive]="false"
          [lazyLoaded]="true"
          [options]="availableUnits"
          [placeholder]="!!state.unitOptions.length ? 'Select a unit' : 'No units found for item'"
          inputId="stockTransferForm__form"
          size="large"
          (modelChange)="setSelectedItemUnit($event, itemUnitConfig)">
        </app-fs-select>
      </nz-form-control>
    </nz-form-item>

    <ng-container *ngIf="!!itemUnitConfig.unitId">
      <!-- Item Unit Cost and Balance Information -->
      <div nz-row>
        <!-- Price -->
        <div *ngIf="state.itemFieldsConfig?.showPriceInfo" nz-col [nzSpan]="8">
          <div> Price</div>

          <div>
            <strong>{{(itemUnitConfig.itemUnit.price | number : '1.2-2') || '-'}}</strong>
          </div>
        </div>

        <!-- Cost -->
        <div *ngIf="state.itemFieldsConfig?.showCostInfo" nz-col [nzSpan]="8">
          <div> Cost</div>

          <div>
            <strong>{{(itemUnitConfig.itemUnit.cost | number : '1.2-2') || '-'}}</strong>
          </div>
        </div>

        <!-- Avail. -->
        <div *ngIf="state.itemFieldsConfig?.showAvailableInfo" nz-col [nzSpan]="8">
          <div> Avail.</div>

          <div>
            <strong>{{itemUnitConfig.itemUnit.quantity || '-'}}</strong>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!!itemUnitConfig.unitId">
    <!-- Other Questions -->
    <div *ngFor="let question of itemUnitConfig?.questions" class="question-item">
      <app-answer-sheet-question-answer
        [managedQuestion]="question"
        (valueChange)="updateQuestionAnswer($event, itemUnitConfig)">
      </app-answer-sheet-question-answer>
    </div>

    <div
      [ngClass]="{'border-bottom': index < (state.selectedItemSelectionConfig?.itemUnits.length - 1)}"
      class="item-detail"
      nz-row
      nzJustify="space-between">
      <div nz-col>
        <ng-container *ngIf="state.itemFieldsConfig?.showLineItemSubtotal">
          <div class="subtotal"> Subtotal</div>
          <div>
            <strong> {{itemUnitConfig.subtotal | number : '1.2-2'}} </strong>
          </div>
        </ng-container>
      </div>

      <div nz-col class="item-unit-buttons">
        <button
          nz-button
          nzType="primary"
          (click)="clearItemUnitAmounts(itemUnitConfig)">
          <i nz-icon nzType="close"></i>
          Clear
        </button>

        <button
          nz-button
          nzType="danger"
          nzShape="circle"
          (click)="removeItemUnitConfig(itemUnitConfig, index)">
          <i nz-icon nzType="delete"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!itemUnitConfig.unitId" class="select-unit-placeholder">
    Select a unit from the dropdown above
  </div>

  <br>
</ng-container>
