<div nz-row>
  <!-- Item List -->
  <div nz-col [nzSpan]="12">
    <div nz-row [nzGutter]="16" class="filter-container">
      <div nz-col [nzSpan]="12">
        <input
          [formControl]="searchControl"
          placeholder="Type to filter..."
          nz-input
          type="text"
          nzSize="large"/>
      </div>

      <div nz-col [nzSpan]="12">
        <app-fs-select
          [(model)]="state.status"
          [options]="state.statuses"
          (modelChange)="checkIfDetailsShouldDisplay($event)"
          size="large">
        </app-fs-select>
      </div>
    </div>

    <cdk-virtual-scroll-viewport *ngIf="!state.isLoadingItems && state.filteredItems.length" itemSize="50" class="viewport">
      <nz-list>
        <nz-list-item
          *cdkVirtualFor="let item of state.filteredItems"
          [ngClass]="{'active': item.id === state.selectedItem?.id}"
          (click)="setSelectedItem(item)">
          <nz-list-item-meta>
            <nz-list-item-meta-title>
              <span class="item-name">{{item.name}}</span>

              <ng-container *ngIf="!IsNullOrUndefined(getItemValidity(item))">
                <i *ngIf="getItemValidity(item)" nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'"
                   [nzTwotoneColor]="'#52c41a'"></i>
                <i *ngIf="!getItemValidity(item)" nz-icon [nzType]="'warning'" [nzTheme]="'twotone'"
                   [nzTwotoneColor]="'#f2994a'"></i>
              </ng-container>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
        </nz-list-item>

        <div
          *ngIf="canLoadMore"
          class="load-more"
          nz-list-load-more>
          <button
            *ngIf="!state.isLoadingItems"
            nz-button
            (click)="loadMoreItems()">
            Load more
          </button>
        </div>
      </nz-list>
    </cdk-virtual-scroll-viewport>

    <div *ngIf="!state.isLoadingItems && !state.items.length" class="placeholder">
      No items found for this location
    </div>

    <div *ngIf="!state.isLoadingItems && state.items.length && !state.filteredItems.length" class="placeholder">
      No items found
    </div>

    <!-- Item List Loader -->
    <div *ngIf="state.isLoadingItems">
      <nz-skeleton [nzActive]="true" nzParagraph></nz-skeleton>
      <nz-skeleton [nzActive]="true" nzParagraph></nz-skeleton>
      <nz-skeleton [nzActive]="true" nzParagraph></nz-skeleton>
      <nz-skeleton [nzActive]="true" nzParagraph></nz-skeleton>
      <nz-skeleton [nzActive]="true" nzParagraph></nz-skeleton>
      <nz-skeleton [nzActive]="true" nzParagraph></nz-skeleton>
    </div>
  </div>

  <!-- Selected Item Config -->
  <div
    *ngIf="!state.isLoadingItems && state.selectedItem"
    nz-col
    [nzSpan]="12"
    class="item-details">
    <!-- NB!!! Item Fields uses Section Answer Sheets to separate selected Item Units with quantities/answers -->
    <app-item-fields-selection
      [managedSection]="managedSection"
      (valueChange)="valueChange.emit($event)">
    </app-item-fields-selection>

    <div *ngIf="shouldShowAddMoreButton"
         nz-row
         nzJustify="center">
      <a class="add-new-unit-btn"
         nz-button
         nzType="link"
         nzSize="large"
         (click)="addNextUnit()">
        Add another unit
      </a>
    </div>
  </div>

  <!-- No Selected Item Placeholder -->
  <div *ngIf="!state.isLoadingItems && !state.selectedItem" nz-col [nzSpan]="12">
    <div class="select-unit-placeholder">
      Select an item from the list
    </div>
  </div>

  <!-- Selected Item Config Loader -->
  <div *ngIf="state.isLoadingItems" nz-col [nzSpan]="12">
    <nz-skeleton [nzActive]="true"></nz-skeleton>
  </div>
</div>
