import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss']
})
export class QuantityComponent implements OnInit {
  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<number> = new EventEmitter<number>();

  public answer: FormControl;
  public min: number = Number.MIN_VALUE;
  public max: number = Number.MAX_VALUE;

  public ngOnInit(): void {
    this.updateFormControlConstraints();

    this.managedQuestion
      .forceUpdate
      .subscribe(answer => {
        this.answer.patchValue(answer);
      });

    this.managedQuestion
      .questionUpdated
      .subscribe(question => {
        this.managedQuestion.question = {
          ...this.managedQuestion.question,
          ...question
        };

        this.updateFormControlConstraints();
      });

    this
      .answer
      .valueChanges
      .pipe(debounceTime(300))
      .subscribe((answerValue) => {
        const answerArray = [];
        if (this.answer.valid && typeof answerValue === 'number') {
          const question = this.managedQuestion.question;

          answerArray.push({
            questionId: question.id,
            questionType: question.type,
            questionPublicId: question.publicId,
            sectionPublicId: question.section.publicId,
            sectionId: question.sectionId,
            quantityAnswer: answerValue,
          });
        }
        this.managedQuestion.answers = answerArray;

        this.valueChange.emit(answerValue);
      });

  }

  private updateFormControlConstraints(): void {
    const questionConfig = this.managedQuestion.question.configuration;
    const validators = [];

    if (questionConfig.minNumber === 0 || !!questionConfig.minNumber) {
      validators.push(Validators.min(questionConfig.minNumber));
      this.min = questionConfig.minNumber;
    }

    if (questionConfig.maxNumber === 0 || !!questionConfig.maxNumber) {
      validators.push(Validators.max(questionConfig.maxNumber));
      this.max = questionConfig.maxNumber;
    }

    if (questionConfig.isRequired) {
      validators.push(Validators.required);
    }

    if (this.answer) {
      this.answer.setValidators(validators);
    } else {
      this.answer = new FormControl(
        !!this.managedQuestion.answers.length ? this.managedQuestion.answers[0].quantityAnswer : undefined,
        validators,
      );
    }
  }
}
