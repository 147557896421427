import {TransferSummaryItem} from './transfer-summary-item.model';

export class TransferSummary implements ITransferSummary {
  public reviewItems: ITransferSummaryItem[];
  public totalCountedQuantity: number;
  public totalExpectedQuantity: number;

  constructor(
    reviewItems: ITransferSummaryItem[] = [],
    totalCountedQuantity: number = 0,
    totalExpectedQuantity: number = 0
  ) {
    this.reviewItems = reviewItems;
    this.totalCountedQuantity = totalCountedQuantity;
    this.totalExpectedQuantity = totalExpectedQuantity;
  }

  public addTransferSummaryItem(
    countedQuantity: number,
    itemUnit: IItemUnit,
    itemName: string,
    unitName: string,
    transactions: ITransferSummaryTransaction[] = []
  ): ITransferSummaryItem {
    if (itemUnit) {
      const transferSummaryItem = new TransferSummaryItem(
        countedQuantity,
        itemUnit,
        itemName,
        unitName,
        transactions
      );

      this.reviewItems.push(transferSummaryItem);

      return this.reviewItems[this.reviewItems.indexOf(transferSummaryItem)];
    }
  }

  public recalculateTotals() {
    this.totalCountedQuantity = this.reviewItems.reduce((memo, i) => memo += i.countedQuantity, 0);
    this.totalExpectedQuantity = this.reviewItems.reduce((memo, i) => memo += i.itemUnit.quantity, 0);
  }
}
