import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NullValidationHandler, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthenticationEvents } from 'src/app/core/authentication/authentication-events';
import { promesify } from 'src/app/shared/helpers/promise.helper';
import { UserHttpService } from 'src/app/users/services/user-http.service';
import { AuthenticationStateService } from './authentication-state.service';
import { discoveryDocumentConfig, oAuthServiceConfig } from './oauth-configuration';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentB2CUserDataSubject: ReplaySubject<any> = new ReplaySubject(1);

  public get currentB2CUserData(): Observable<any> {
    return this.currentB2CUserDataSubject.asObservable();
  }

  public get currentB2CUserDataAsync(): Promise<any> {
    return promesify(this.currentB2CUserDataSubject);
  }

  public get showForbidden(): BehaviorSubject<boolean> {
    return this.authenticationStateService.forbiddenState;
  }

  private currentFsUserSubject: ReplaySubject<IUser> = new ReplaySubject(1);

  public get currentFsUser(): Observable<IUser> {
    return this.currentFsUserSubject.asObservable();
  }

  public authenticationEvents: Subject<AuthenticationEvents> = new Subject();

  private _isPublicUser: boolean = false;

  public get isPublicUser() {
    return this._isPublicUser;
  }

  public constructor(
    private oAuthService: OAuthService,
    private readonly authenticationStateService: AuthenticationStateService,
    public userHttpService: UserHttpService,
    private router: Router,
    private angularFireAuth: AngularFireAuth
  ) {
    this.setup();
    this.authenticationStateService.unauthorizedState.subscribe(res => this.handleUnauthorized(res));
  }

  public setIsPublicUser() {
    this._isPublicUser = true;
  }

  private async handleUnauthorized(isUnauthorized: boolean) {
    if (isUnauthorized) {
      this.authenticationEvents.next(AuthenticationEvents.REFRESH_START);
      this
        .oAuthService
        .silentRefresh()
        .then(info => {
          this.authenticationEvents.next(AuthenticationEvents.REFRESH_SUCCESS);
        })
        .catch(err => {
          console.error('refresh error', err);
          this.authenticationEvents.next(AuthenticationEvents.REFRESH_FAILURE);
          // if fails to silentRefresh, logout the user
          this.startLogoutFlow();
        });
    }
  }

  private async setup(): Promise<void> {
    this.oAuthService.configure(oAuthServiceConfig);
    this.oAuthService.tokenValidationHandler = new NullValidationHandler();
    this.oAuthService.silentRefreshRedirectUri = window.location.origin + '/silent-refresh.html';

    try {
      await this.angularFireAuth.signInAnonymously();
    } catch (error) {
      console.error('Unable sign in anonymously into firebase', error);
      this.panic();
    }

    try {
      await this.oAuthService.loadDiscoveryDocument(discoveryDocumentConfig.url);
    } catch (error) {
      console.error('Unable to bootstrap authentication service', error);
      this.panic();
    }

    try {
      this.authenticationEvents.next(AuthenticationEvents.AUTHENTICATION_START);
      await this.oAuthService.tryLoginImplicitFlow();
    } catch (error) {
      this.authenticationEvents.next(AuthenticationEvents.AUTHENTICATION_FAILURE);
      console.error('Unable to start session', error);
    }

    this.oAuthService.setupAutomaticSilentRefresh();

    this.bindOAuthEvents();
    this.refreshB2CAndFsUserData();
    this.authenticationEvents.next(AuthenticationEvents.AUTHENTICATION_SUCCESS);
  }

  private panic(): void {
    // TODO
    window.alert('System setucurrentUserSubjectp has failed, please reload the application');
  }

  private bindOAuthEvents() {
    this.oAuthService.events.subscribe(
      (event: OAuthEvent) => {
        const eventsOfInterest = ['token_received', 'token_refreshed', 'silently_refreshed'];
        if (eventsOfInterest.includes(event.type)) {
          this.refreshB2CAndFsUserData();
        }
      }
    );
  }

  private refreshB2CAndFsUserData() {
    this.refreshB2CUserData();
    this.refreshFsUserData();
  }

  private refreshB2CUserData() {
    const b2cUserData = this.oAuthService.getIdentityClaims();
    this.currentB2CUserDataSubject.next(b2cUserData);
  }

  public refreshFsUserData() {
    this
      .userHttpService
      .getUserPersonalData()
      .pipe(take(1))
      .subscribe(user => this.currentFsUserSubject.next(user));
  }

  public startLoginFlow(customState?: string): void {
    this.oAuthService.initImplicitFlow(customState);
  }

  public async startLogoutFlow(): Promise<void> {
    await this.angularFireAuth.signOut();
    this.oAuthService.logOut();
    this.authenticationEvents.next(AuthenticationEvents.LOGOUT);
  }

  public activateForbiddenRoute(): void {
    this.showForbidden.next(true);
    this.router.navigate(['access-denied']);
  }
}
