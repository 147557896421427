import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../authentication/authentication.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {
  public forbiddenMessage: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.forbiddenMessage = 'You do not currently have access to the Admin Office.\n\r' +
      'Please contact your System Administrator or support for further assistance.';
  }

  ngOnInit(): void {
    if (!this.authenticationService.showForbidden.getValue()) {
      this.router.navigate(['/']);
    }
  }

  handleLogOutClick() {
    this.authenticationService.startLogoutFlow();
  }

}
