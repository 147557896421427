import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {AccessDeniedComponent} from './access-denied/access-denied.component';

@NgModule({
  declarations: [
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    NzIconModule,
    NzButtonModule,
    FormsModule,
  ],
  exports: [
    AccessDeniedComponent
  ]
})
export class ErrorPagesModule {
}
