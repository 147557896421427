import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyAnswerHttpService extends BaseCrudService<ISurveyAnswer> {

  public constructor(public http: HttpClient) {
    super(http, 'survey-answers');
  }

  public exportXls(id: string, surveyStatusId: string, searchField: string, dateRange: Date[] = []): Observable<any> {
    const url = `${this.url}/${id}/export-xls`;
    const params = new HttpParams()
      .set('surveyStatusId', surveyStatusId)
      .set('searchField', searchField)
      .set('dateRange', dateRange.map(d => moment(d).utc().format()).join(','));

    const request = new HttpRequest<Blob>('GET', url, { responseType: 'blob', params });

    return this.http.request(request).pipe(
      filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
      take(1),
    );
  }

  public updateTags(
    surveyAnswerId: string,
    tagIds: string[],
  ): Observable<ITag[]> {
    const url = `${this.url}/${surveyAnswerId}/tags`;
    return this
      .http
      .patch<ITag[]>(url, tagIds)
      .pipe(take(1));
  }

  public getPdf(id: string, pdfExportSettings: ISurveyPdfExportSettings): Observable<any> {
    const url = `${this.url}/${id}/export-pdf`;
    const request = new HttpRequest<ISurveyPdfExportSettings>('POST', url, pdfExportSettings, { responseType: 'blob' });

    return this.http.request<Blob>(request).pipe(
      filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
      take(1),
    );
  }
}
