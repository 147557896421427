<ng-container *ngIf="tenantStorageService.shouldDisplayTenantSelector | async">
  <ng-container *ngIf="isLoading; else content">
    <div class="full-page-spinner">
      <nz-spin nzSimple nzSize="large"></nz-spin>
    </div>
  </ng-container>

  <ng-template #content>
    <div class="wrapper">
      <div class="page-title">
        <img src="/assets/atajo-logo-white.svg"/>
      </div>
      <div class="tenant-selector">
        <div class="title">Select Organisation</div>

        <div *ngFor="let tenant of tenants | filter : 'isAdminOfficeUser' : true" class="tenant"
             (click)="tenantStorageService.setTenant(tenant)">
          <div class="name">{{ tenant.name }}</div>
          <div class="domain">{{ tenant.domain }}</div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
