import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public answers: FormArray;
  public showOneOfMultipleAnswersRequired: boolean = false;
  public touched: boolean;

  public constructor() {
  }

  public get answerControls(): FormControl[] {
    return this.answers.controls as FormControl[];
  }

  public get allowMultipleAnswers(): boolean {
    return this.managedQuestion.question.configuration.allowMultipleAnswers;
  }

  public ngOnInit(): void {
    const answerControls = this.managedQuestion.answers.length > 0 ?
      this.managedQuestion.answers.map(answer =>
        this.generateBasicFormControl(answer.textAnswer),
      ) : [this.generateBasicFormControl()];

    this.answers = new FormArray(answerControls, (array: FormArray) => {
      const required = this.managedQuestion.question.configuration.isRequired || false;
      if (!required) {
        return null;
      }
      const hasMinimumOneAnswer =
        (array.controls as Array<FormControl>).some(control =>
          !!control.value && control.value.trim() !== ''
        );

      const errors = hasMinimumOneAnswer ? null : {required: true};

      if (errors) {
        if (array.controls.length === 1) {
          array.controls[0].setErrors(errors);
        } else {
          this.showOneOfMultipleAnswersRequired = true;
        }
      } else {
        if (this.showOneOfMultipleAnswersRequired) {
          this.showOneOfMultipleAnswersRequired = false;
        }

        if (array.controls.length === 1 && array.controls[0].getError('required')) {
          delete array.controls[0].errors.required;
        }
      }

      return errors;
    });

    this
      .answers
      .valueChanges
      .pipe(debounceTime(300))
      .subscribe((answers: string[]) => {
        let answerArray = [];

        if (this.answers.valid) {
          const question = this.managedQuestion.question;

          answerArray = answers
            .filter(answer => !!answer.trim())
            .map(answer => ({
              questionId: question.id,
              questionType: question.type,
              questionPublicId: question.publicId,
              sectionPublicId: question.section.publicId,
              sectionId: question.sectionId,
              textAnswer: answer,
            }));
        }

        if (!this.touched) {
          this.touched = true;
        }

        this.managedQuestion.answers = answerArray;

        this.valueChange.emit(answers);
      });
  }

  public addAnswer(): void {
    this.answers.push(
      this.generateBasicFormControl(),
    );
  }

  public removeAnswer(index: number): void {
    this.answers.removeAt(index);
  }

  getAddOnBefore() {
    return this.managedQuestion.question.configuration.addPrefix ?
      this.managedQuestion.question.configuration.prefix : '';
  }

  getAddOnAfter() {
    return this.managedQuestion.question.configuration.addSuffix ?
      this.managedQuestion.question.configuration.suffix : '';
  }

  private generateBasicFormControl(answer: string = ''): FormControl {
    return new FormControl(
      answer,
      this.managedQuestion.question.configuration.inputValidationType === 'email' ?
        [Validators.email] : []
    );
  }

}
