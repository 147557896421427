import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CreateQueryParams} from '@nestjsx/crud-request';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Observable} from 'rxjs';
import {catchError, finalize, take, tap} from 'rxjs/operators';
import {SurveyAnswerHttpService} from 'src/app/surveys/services/survey-answer-http.service';
import {SurveyHttpService} from 'src/app/surveys/services/survey-http.service';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {FormVersionHttpService} from '../jobs/services/form-version-http.service';
import {FormVersionAnswerSheetMappingService} from '../shared/answer-sheet/services/form-version-answer-sheet-mapping.service';
import {FormVersionAnswerSheetFactory} from '../shared/answer-sheet/services/form-version-answer-sheet.factory';

@Component({
  selector: 'app-public-survey-answer-form',
  templateUrl: './public-survey-answer-form.component.html',
  styleUrls: ['./public-survey-answer-form.component.scss']
})
export class PublicSurveyAnswerFormComponent implements OnInit {
  // Public Properties
  public survey: ISurvey;
  public allowHardLinks: boolean = false;
  public allowLocationHardLink: boolean = false;
  public allowItemHardLink: boolean = false;
  public allowCustomerHardLink: boolean = false;
  public locationsSelected: string[] = [];
  public itemsSelected: string[] = [];
  public customersSelected: string[] = [];
  public answerSheet: IFormVersionAnswerSheet;
  public stickTabs: boolean = false;
  public tabsetOffsetTop: number = 0;
  public isValidQuestionnaire: boolean = false;
  public isSavingSurvey: boolean = false;
  public surveySubmitted: boolean = false;
  public showPasswordModal: boolean = false;
  public showLoading: boolean = true;
  public showSubmitBtn: boolean = false;
  public isAuthenticating: boolean = false;
  public showNotPublished: boolean = false;
  public passwordFieldDirty: boolean = false;

  // View Children
  @ViewChild('header')
  public header: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyHttpService,
    private surveyAnswerService: SurveyAnswerHttpService,
    private messageService: NzMessageService,
    private authenticationService: AuthenticationService,
    private formVersionService: FormVersionHttpService,
    private formVersionAnswerSheetMappingService: FormVersionAnswerSheetMappingService,
    private hostRef: ElementRef
  ) {
    this.authenticationService.setIsPublicUser();
  }

  // Getters / Setters
  public get isValidHardLinks(): boolean {
    const {
      customers: customersHardLinkConfig,
      locations: locationsHardLinkConfig,
      items: itemsHardLinkConfig
    } = this.survey.hardLinkConfiguration;
    return (
      (!this.allowLocationHardLink || !locationsHardLinkConfig.isRequired || this.locationsSelected.length > 0)
      && (!this.allowItemHardLink || !itemsHardLinkConfig.isRequired || this.itemsSelected.length > 0)
      && (!this.allowCustomerHardLink || !customersHardLinkConfig.isRequired || this.customersSelected.length > 0)
    );
  }

  public get isValidSurvey(): boolean {
    return this.isValidHardLinks && this.isValidQuestionnaire;
  }

  @HostListener('scroll', ['$event.target.scrollTop'])
  checkAndSetStickyTabs(scrollTop: number) {
    if (this.header) {
      const headerClientHeight = (this.header.nativeElement as HTMLElement).clientHeight;

      this.stickTabs = (scrollTop + headerClientHeight) > this.tabsetOffsetTop;
    }
  }

  // Page Events
  public ngOnInit(): void {
    this.performAuthAndFetchSurvey(null);
  }

  // Public Methods
  public performAuthAndFetchSurvey(password: string): void {
    const publicAuth = {
      surveyId: this.route.snapshot.paramMap.get('surveyId'),
      ...(!!password ? {surveyPassword: password} : {})
    };
    sessionStorage.setItem('public-auth-header-values', JSON.stringify(publicAuth));

    this.isAuthenticating = true;
    this.showLoading = true;
    this.passwordFieldDirty = password !== null;
    this
      .fetchSurvey()
      .pipe(
        take(1),
        catchError(error => {
          if (error && error.status === 403) {
            if (this.passwordFieldDirty) {
              this.messageService.error('Invalid survey/password combination');
            }
            this.showPasswordModal = true;
          }
          this.showLoading = true;

          return error;
        }),
        finalize(() => {
          this.isAuthenticating = false;
        })
      )
      .subscribe((survey: ISurvey) => {
        const cacheData = window.localStorage.getItem(this.surveyAnswerCacheKey);
        const surveyAnswer: ISurveyAnswer = {
          surveyId: survey.id,
          customerIds: [],
          itemIds: [],
          locationIds: [],
          tags: [],
          attachments: [],
          surveyTypeStatusId: survey.defaultStatusId,
          formVersionAnswerSheet: FormVersionAnswerSheetFactory.create(
            survey.form,
            survey.form.currentPublishedFormVersion
          )
        };

        if (cacheData) {
          try {
            const parsedCachedSurveyAnswer = JSON.parse(cacheData) as ISurveyAnswer;

            this.tryUpdateSurveyAnswer(surveyAnswer, parsedCachedSurveyAnswer);
          } catch (error) {
            console.error(error);
          }
        }

        this.showNotPublished = false;
        this.showPasswordModal = false;

        if (!survey || !survey.form || !survey.form.currentPublishedFormVersionId) {
          this.showNotPublished = true;
        }

        this.showLoading = false;

        // Since we may be loading question answers from the cache
        // they can affect the validity of the form, which is used
        // to determine whether the submit button can be enabled
        // these checks may result in a ExpressionChangedAfterItHasBeenCheckedError
        // so the simplest solution is to move showing the submit button
        // only after the form validity has been computed
        window.setTimeout(() => this.showSubmitBtn = true, 0);
      });
  }

  public handleSaveSurveyAnswer(): void {
    const surveyAnswer = this.assembleSurveyAnswerForSubmission();
    this.isSavingSurvey = true;
    this
      .surveyAnswerService
      .post(surveyAnswer)
      .pipe(
        catchError(error => {
          this.messageService.error('Failed to save survey, please try again later');

          return error;
        }),
        finalize(() => this.isSavingSurvey = false)
      )
      .subscribe(
        () => {
          this.surveySubmitted = true;

          // removes cached answers so users can answer the survey again
          this.clearSurveyAnswerCache();
        }
      );
  }

  private assembleSurveyAnswerForStorage(fullSelectors: boolean = false): ISurveyAnswer {
    // TODO: Chris - Fix the mapping below when multiple entity selections are possible again
    const customerIds = Array.isArray(this.customersSelected) && this.customersSelected.length > 0
      ? fullSelectors ? this.customersSelected : [this.customersSelected[this.customersSelected.length - 1]]
      : null;
    const locationIds = Array.isArray(this.locationsSelected) && this.locationsSelected.length > 0
      ? fullSelectors ? this.locationsSelected : [this.locationsSelected[this.locationsSelected.length - 1]]
      : null;
    const itemIds = Array.isArray(this.itemsSelected) && this.itemsSelected.length > 0
      ? fullSelectors ? this.itemsSelected : [this.itemsSelected[this.itemsSelected.length - 1]]
      : null;

    return {
      surveyId: this.survey.id,
      customerIds,
      locationIds,
      itemIds,
      formVersionAnswerSheet: this.formVersionAnswerSheetMappingService.mapAnswerSheetDto(this.answerSheet)
    };
  }

  private assembleSurveyAnswerForSubmission(fullSelectors: boolean = false): ISurveyAnswer {
    const surveyAnswer = this.assembleSurveyAnswerForStorage(fullSelectors);
    const { formVersionAnswerSheet: answerSheet} = surveyAnswer;

    delete answerSheet.formVersion;
    answerSheet.questionAnswers.forEach(qa => {
      delete qa.section;
      delete qa.attachmentAnswer;
    });

    return surveyAnswer;
  }

  public assignTabsetElementRef() {
    setTimeout(() => {
      const tabset = document.querySelector('.ant-tabs-nav') as HTMLElement;

      this.tabsetOffsetTop = tabset.offsetTop;
    });
  }

  public setScrollTopToTabset() {
    this.hostRef.nativeElement.scrollTop = this.tabsetOffsetTop;
  }

  // Private Methods
  private fetchSurvey(): Observable<ISurvey> {
    const query: CreateQueryParams = {
      join: [
        {field: 'form'},
        {field: 'form.currentPublishedFormVersion'},
        {field: 'form.currentPublishedFormVersion.sections'},
        {field: 'form.currentPublishedFormVersion.sections.questions'},
        {field: 'form.currentPublishedFormVersion.sections.questions.options'}
      ]
    };
    const surveyId = this.route.snapshot.paramMap.get('surveyId');
    return this
      .surveyService
      .getSurvey(surveyId, query)
      .pipe(
        tap(survey => {
          if (!!survey && !!survey.form && !!survey.form.currentPublishedFormVersionId) {
            this.survey = survey;

            const {
              customers: customersHardLinkConfig,
              locations: locationsHardLinkConfig,
              items: itemsHardLinkConfig
            } = this.survey.hardLinkConfiguration;

            this.allowCustomerHardLink = customersHardLinkConfig.allowCustomerHardLink;
            this.allowItemHardLink = itemsHardLinkConfig.allowItemHardLink;
            this.allowLocationHardLink = locationsHardLinkConfig.allowLocationHardLink;

            this.allowHardLinks =
              this.allowCustomerHardLink
              || this.allowItemHardLink
              || this.allowLocationHardLink;
          }
        })
      );
  }

  /**
   * The following "surveyAnswerCache" functions are used
   * to cache answers while an user is answering a survey. This is used
   * to restore answers in case an user decides to close or reload the page
   * before submiting the survey answer.
   *
   * The cache should be cleared as soon as the survey answer is submited.
   */
  private get surveyAnswerCacheKey(): string {
    return `v2-survey-answer-form-version-${this.survey.form.currentPublishedFormVersionId}`;
  }

  public updateSurveyAnswerCache(): void {
    const surveyAnswer = this.assembleSurveyAnswerForStorage(true);
    window.localStorage.setItem(this.surveyAnswerCacheKey, JSON.stringify(surveyAnswer));
  }

  public clearSurveyAnswerCache(): void {
    window.localStorage.removeItem(this.surveyAnswerCacheKey);
  }

  public tryUpdateSurveyAnswer(surveyAnswer: ISurveyAnswer, cacheData: ISurveyAnswer): ISurveyAnswer {
    if (!cacheData) {
      return null;
    }

    if (!cacheData.locationIds) {
      surveyAnswer.locationIds = cacheData.locationIds;
    }

    if (!cacheData.itemIds) {
      surveyAnswer.itemIds = cacheData.itemIds;
    }

    if (!cacheData.customerIds) {
      surveyAnswer.customerIds = cacheData.customerIds;
    }

    if (cacheData.formVersionAnswerSheet?.questionAnswers?.length > 0) {
      const questionAnswers = cacheData.formVersionAnswerSheet.questionAnswers;

      questionAnswers.forEach((questionAnswer: IQuestionAnswer) => {
        if (questionAnswer.questionId) {
          const matchingQuestionAnswer = surveyAnswer.formVersionAnswerSheet.questionAnswers
            .find(qa => qa.questionId === questionAnswer.id);

          if (matchingQuestionAnswer) {
            matchingQuestionAnswer.attachmentIdAnswer = questionAnswer.attachmentIdAnswer
              ?? matchingQuestionAnswer.attachmentIdAnswer;
            matchingQuestionAnswer.booleanAnswer = questionAnswer.booleanAnswer
              ?? matchingQuestionAnswer.booleanAnswer;
            matchingQuestionAnswer.durationAnswer = questionAnswer.durationAnswer
              ?? matchingQuestionAnswer.durationAnswer;
            matchingQuestionAnswer.latitudeAnswer = questionAnswer.latitudeAnswer
              ?? matchingQuestionAnswer.latitudeAnswer;
            matchingQuestionAnswer.longitudeAnswer = questionAnswer.longitudeAnswer
              ?? matchingQuestionAnswer.longitudeAnswer;
            matchingQuestionAnswer.numericAnswer = questionAnswer.numericAnswer
              ?? matchingQuestionAnswer.numericAnswer;
            matchingQuestionAnswer.quantityAnswer = questionAnswer.quantityAnswer
              ?? matchingQuestionAnswer.quantityAnswer;
            matchingQuestionAnswer.questionOptionIdAnswer = questionAnswer.questionOptionIdAnswer
              ?? matchingQuestionAnswer.questionOptionIdAnswer;
            matchingQuestionAnswer.signatureIdAnswer = questionAnswer.signatureIdAnswer
              ?? matchingQuestionAnswer.signatureIdAnswer;
            matchingQuestionAnswer.textAnswer = questionAnswer.textAnswer
              ?? matchingQuestionAnswer.textAnswer;
            matchingQuestionAnswer.timestampAnswer = questionAnswer.timestampAnswer
              ?? matchingQuestionAnswer.timestampAnswer;
            matchingQuestionAnswer.timestampAnswerTimezone = questionAnswer.timestampAnswerTimezone
              ?? matchingQuestionAnswer.timestampAnswerTimezone;
            matchingQuestionAnswer.locationIdAnswer = questionAnswer.locationIdAnswer
              ?? matchingQuestionAnswer.locationIdAnswer;
            matchingQuestionAnswer.itemIdAnswer = questionAnswer.itemIdAnswer
              ?? matchingQuestionAnswer.itemIdAnswer;
            matchingQuestionAnswer.itemAnswerSnapshot = questionAnswer.itemAnswerSnapshot
              ?? matchingQuestionAnswer.itemAnswerSnapshot;
            matchingQuestionAnswer.timerIdAnswer = questionAnswer.timerIdAnswer
              ?? matchingQuestionAnswer.timerIdAnswer;
            matchingQuestionAnswer.barcode = questionAnswer.barcode
              ?? matchingQuestionAnswer.barcode;
            matchingQuestionAnswer.qRCodeValue = questionAnswer.qRCodeValue
              ?? matchingQuestionAnswer.qRCodeValue;
            matchingQuestionAnswer.paymentTransactionId = questionAnswer.paymentTransactionId
              ?? matchingQuestionAnswer.paymentTransactionId;
            matchingQuestionAnswer.cashOutId = questionAnswer.cashOutId
              ?? matchingQuestionAnswer.cashOutId;
            matchingQuestionAnswer.answer = questionAnswer.answer
              ?? matchingQuestionAnswer.answer;
            matchingQuestionAnswer.customFieldsJobId = questionAnswer.customFieldsJobId
              ?? matchingQuestionAnswer.customFieldsJobId;
            matchingQuestionAnswer.surveyId = questionAnswer.surveyId
              ?? matchingQuestionAnswer.surveyId;
            matchingQuestionAnswer.surveyAnswerId = questionAnswer.surveyAnswerId
              ?? matchingQuestionAnswer.surveyAnswerId;
            matchingQuestionAnswer.itemFieldsAnswerSheetId = questionAnswer.itemFieldsAnswerSheetId
              ?? matchingQuestionAnswer.itemFieldsAnswerSheetId;
            matchingQuestionAnswer.formId = questionAnswer.formId
              ?? matchingQuestionAnswer.formId;
            matchingQuestionAnswer.formVersionId = questionAnswer.formVersionId
              ?? matchingQuestionAnswer.formVersionId;
            matchingQuestionAnswer.formVersionAnswerSheetId = questionAnswer.formVersionAnswerSheetId
              ?? matchingQuestionAnswer.formVersionAnswerSheetId;
            matchingQuestionAnswer.customFieldsLocationId = questionAnswer.customFieldsLocationId
              ?? matchingQuestionAnswer.customFieldsLocationId;
            matchingQuestionAnswer.customFieldsItemId = questionAnswer.customFieldsItemId
              ?? matchingQuestionAnswer.customFieldsItemId;
            matchingQuestionAnswer.customFieldsCustomerId = questionAnswer.customFieldsCustomerId
              ?? matchingQuestionAnswer.customFieldsCustomerId;
            matchingQuestionAnswer.stockTransferId = questionAnswer.stockTransferId
              ?? matchingQuestionAnswer.stockTransferId;
            matchingQuestionAnswer.customFieldsStockTransferId = questionAnswer.customFieldsStockTransferId
              ?? matchingQuestionAnswer.customFieldsStockTransferId;
          }
        }
      });
    }
  }
}
