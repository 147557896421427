<nz-card [nzBordered]="false" class="container-card">
  <nz-form-item [ngClass]="{'reduced-margin': !!selectedItem}" nz-row>
    <nz-form-label [nzRequired]="configuration.isRequired" nzSpan="24"> Item </nz-form-label>

    <nz-form-control
      nz-col
      nzSpan="24">
      <app-fs-cascader
        #itemSelector
        inputId="public-survey__item"
        placeholder="Select a Item"
        [(model)]="selectedItem"
        [options]="itemCascaderService.options"
        [isLoading]="itemCascaderService.isLoading"
        [isRequired]="configuration.isRequired"
        (cascadeLoadData)="itemCascaderService.loadItemsForItemTypeOption($event)"
        (modelChange)="handleItemUpdate($event)"
        (searchUpdate)="itemCascaderService.searchItemTypeOptions($event)"
        (searchItemSelected)="setHierarchyForItem($event)"
        (clear)="itemCascaderService.restoreRootOptionsBackup()">
      </app-fs-cascader>

      <div class="error form-control-msg" *ngIf="itemSelector.dirty && itemSelector.hasRequiredError">
        Item is required
      </div>

      <div class="error form-control-msg" *ngIf="selectedItem && selectedItem.length === 1 && !selectedItem[0].isLeaf">
        An Item Type cannot be selected, select an Item
      </div>
    </nz-form-control>
  </nz-form-item>
</nz-card>
