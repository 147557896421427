import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IsNullOrUndefined} from '../../../../core/helpers/type.helpers';
import {FormAnswerSheetStateService} from '../../services/form-answer-sheet-state.service';
import {IItemFieldsState, IItemSelectionConfig, IItemUnitConfig} from '../services/item-fields-state.interface';
import {ItemFieldsStateService} from '../services/item-fields-state.service';

@Component({
  selector: 'app-item-fields-selection',
  templateUrl: './item-fields-selection.component.html',
  styleUrls: ['./item-fields-selection.component.scss']
})
export class ItemFieldsSelectionComponent {
  @Input()
  public managedSection: IStateManagedSection = null;

  @Output()
  public valueChange: EventEmitter<IItemSelectionConfig> = new EventEmitter<IItemSelectionConfig>();

  // Public Properties
  public state: IItemFieldsState;

  public get availableUnits(): ISelectOption[] {
    return this.stateService.availableUnits;
  }

  constructor(
    private stateService: ItemFieldsStateService,
    public formStateService: FormAnswerSheetStateService
  ) {
    // Update local state reference when it changes
    this.stateService
      .listener
      .subscribe((state: IItemFieldsState) => this.state = state);
  }

  // Public Methods
  public updateQuestionAnswer(value: any, itemUnitConfig: IItemUnitConfig) {
    this.updateItemSubtotal(itemUnitConfig);
  }

  public updateItemSubtotal(itemUnitConfig: IItemUnitConfig) {
    const quantityQuestions = itemUnitConfig.questions
      .filter(q => q.question.type === 'quantity');
    const quantities = quantityQuestions
      .reduce((memo, q) => memo = memo.concat(q.answers), [])
      .reduce((memo, answer) => memo += answer.quantityAnswer ?? 0, 0) ?? 0;

    if (!IsNullOrUndefined(quantities)) {
      const itemUnitPrice = +itemUnitConfig.itemUnit?.price ?? 0;

      itemUnitConfig.subtotal = itemUnitPrice * quantities;
    }

    this.state.itemsTotal = this.state.selectedItemSelectionConfig
      .itemUnits
      .reduce((memo, iuc) => memo += iuc.subtotal, 0);

    this.updateValidState();
  }

  public clearItemUnitAmounts(itemUnitConfig: IItemUnitConfig) {
    const quantityQuestions = itemUnitConfig.questions
      .filter(q => q.question.type === 'quantity');

    quantityQuestions.forEach(q => {
      const quantityAnswers = q.answers
        .filter(a => !!a.quantityAnswer);

      q.forceUpdate.emit(null);
      quantityAnswers.forEach(a => a.quantityAnswer = null);
    });

    this.updateItemSubtotal(itemUnitConfig);
  }

  public setSelectedItemUnit(unitId: string, itemUnitConfig: IItemUnitConfig): void {
    const selectedItem = this.state.selectedItem;
    const matchingItemUnit = selectedItem.itemUnits.find(iu => iu.unitId === unitId);
    const unitIdIsNull = IsNullOrUndefined(unitId);

    if (!unitId) {
      const itemUnitConfigIndex = this.state.selectedItemSelectionConfig?.itemUnits?.indexOf(itemUnitConfig) ?? -1;

      if (itemUnitConfigIndex > -1) {
        this.state.selectedItemSelectionConfig.itemUnits.splice(itemUnitConfigIndex, 1);
      }

      if (!this.state.selectedItemSelectionConfig?.itemUnits?.length) {
        const itemSelectionIndex = this.state.itemSelectionConfigs.findIndex(isc => isc.item?.id === selectedItem.id);

        if (itemSelectionIndex > -1) {
          this.state.itemSelectionConfigs.splice(itemSelectionIndex, 1);
          this.stateService.setSelectedItem(this.state.items[0]);
        }
      }
    }

    if (matchingItemUnit) {
      itemUnitConfig.itemUnit = matchingItemUnit;

      const selectedUnitQuestions = this.stateService.setSelectedItemUnitWithQuestions(itemUnitConfig, matchingItemUnit);

      this.applyMaxLimitToQuantity(itemUnitConfig);
      this.stateService.updateState({selectedItemUnit: matchingItemUnit});

      this.valueChange.emit(selectedUnitQuestions);
    }

    this.updateValidState(unitIdIsNull);
  }

  public removeItemUnitConfig(itemUnitConfig: IItemUnitConfig, index: number) {
    if (index > 0) {
      this.stateService.removeItemUnitConfig(itemUnitConfig);
    } else {
      this.setSelectedItemUnit(null, itemUnitConfig);
    }
  }

  // Private Methods
  private applyMaxLimitToQuantity(itemUnitConfig: IItemUnitConfig) {
    this.state.selectedItemSelectionConfig
      .itemUnits
      .forEach(iu => {
        const managedQuestion = iu.questions
          .find(q => q.question.type === 'quantity');
        const selectedItemUnit = itemUnitConfig.itemUnit;
        const formVersion = this.formStateService.currentFormVersion;

        // Don't apply a max value if the form should Include Balance (Reconcile stock), because a deficit should be possible
        if (managedQuestion && !formVersion?.form?.stockTransferTypeForm.includeBalance) {
          managedQuestion.question.configuration.maxNumber = selectedItemUnit.quantity;

          setTimeout(() => {
            managedQuestion.questionUpdated.emit(managedQuestion.question);
          });
        }
      });
  }

  private updateValidState(resetState: boolean = false) {
    const selectedItem = this.state.selectedItem;
    const matchingItemSelectionConfig = this.state.itemSelectionConfigs
      .find(i => i.item?.id === selectedItem.id);

    if (resetState) {
      matchingItemSelectionConfig.valid = null;

      return;
    }

    if (selectedItem) {
      matchingItemSelectionConfig.valid = true;

      this.state.selectedItemSelectionConfig
        .itemUnits
        .forEach(iu => {
          iu.questions
            .forEach((item: IStateManagedQuestion) => {
              if (item.question.configuration.isRequired && (!item.answers || !item.answers.length)) {
                matchingItemSelectionConfig.valid = false;
              }
            });
        });
    }
  }
}
