import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {CondOperator, CreateQueryParams} from '@nestjsx/crud-request';
import {Observable} from 'rxjs';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class FormVersionAnswerSheetHttpService extends BaseCrudService<IFormVersionAnswerSheet> {
  private _joins: {field: string}[] = [
    {field: 'formVersion'},
    {field: 'formVersion.form'},
    {field: 'formVersion.sections'},
    {field: 'formVersion.sections.questions'},
    {field: 'formVersion.sections.questions.options'},
    {field: 'itemFieldsAnswerSheets'},
    {field: 'questionAnswers'},
    {field: 'questionAnswers.section'},
    {field: 'questionAnswers.itemFieldsAnswerSheet'},
    {field: 'questionAnswers.itemFieldsAnswerSheet.itemUnit'},
    {field: 'questionAnswers.itemFieldsAnswerSheet.itemUnit.item'},
    {field: 'questionAnswers.itemFieldsAnswerSheet.itemUnit.item.itemUnits'},
    {field: 'questionAnswers.itemFieldsAnswerSheet.itemUnit.unit'},
    {field: 'questionAnswers.itemFieldsAnswerSheet.itemUnit.location'},
    {field: 'questionAnswers.attachmentAnswer'},
    {field: 'questionAnswers.signatureAnswer'},
    {field: 'questionAnswers.questionOptionAnswer'},
    {field: 'questionAnswers.question'},
    {field: 'questionAnswers.timerAnswer'},
    {field: 'questionAnswers.itemAnswer'},
    {field: 'questionAnswers.locationAnswer'},
  ];
  private _sorts: {field: string, order: 'ASC' | 'DESC'}[] = [{
    field: 'formVersion.sections.order',
    order: 'ASC',
  }, {
    field: 'formVersion.sections.questions.order',
    order: 'ASC',
  }, {
    field: 'formVersion.sections.questions.options.order',
    order: 'ASC',
  }];

  constructor(
    public http: HttpClient,
  ) {
    super(http, 'form-version-answer-sheets');
  }

  public getManyAnswerSheets(answerSheetIds: string[]): Observable<IPagination<IFormVersionAnswerSheet>> {
    const queryParams: CreateQueryParams = {
      join: this._joins,
      sort: this._sorts,
      filter: {
        field: 'id',
        operator: CondOperator.IN,
        value: answerSheetIds
      }
    };

    return this.get(queryParams);
  }

  public getOneAnswerSheet(answerSheetId: string): Observable<IFormVersionAnswerSheet> {
    const queryParams: CreateQueryParams = {
      join: this._joins,
      sort: this._sorts
    };

    return this.getOne(answerSheetId, queryParams);
  }
}
