<nz-form-control>

  <div class="loading-placeholder" *ngIf="isLoading; else content">
    <nz-spin></nz-spin>
  </div>

  <ng-template #content>
    <nz-tree-select
      class="fs-location-input"
      nzPlaceHolder="Location(s)"
      [formControl]="answerFormControl"
      [nzNodes]="locationOptions"
      [nzDefaultExpandAll]="true"
      [nzAllowClear]="true"
      [nzMultiple]="managedQuestion.question.configuration.allowMultipleAnswers"
      nzSize="large">
    </nz-tree-select>

    <div class="error form-control-msg" *ngIf="answerFormControl.dirty && answerFormControl.hasError('required')">
      This question is required
    </div>
  </ng-template>

</nz-form-control>
