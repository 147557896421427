import {Injectable} from '@angular/core';
import {CondOperator} from '@nestjsx/crud-request';
import {forkJoin} from 'rxjs';
import {AbstractStateService} from '../../../core/state-management/abstract-state.service';
import {ItemUnitHttpService} from '../../../items/services/item-unit-http.service';
import {QuestionAnswerHttpService} from '../../../surveys/services/question-answer-http.service';
import {QuestionTypes} from '../../form-builder/question/question-types';
import {groupBy, sortByValue} from '../../helpers/list.helper';
import {IItemSelectionConfig, IItemUnitConfig} from '../item-fields/services/item-fields-state.interface';
import {ITransferSummaryState} from './transfer-summary-state.interface';
import {TransferSummary} from './transfer-summary.model';

@Injectable({providedIn: 'root'})
export class TransferSummaryStateService extends AbstractStateService<ITransferSummaryState> {
  constructor(
    private itemUnitHttpService: ItemUnitHttpService,
    private questionAnswerHttpService: QuestionAnswerHttpService
  ) {
    super();
  }

  public initializeState(): ITransferSummaryState {
    return {
      isLoading: false,
      transferSummary: new TransferSummary(),
      includeBalance: false,
      showTransactions: false,
      activeTransactionItem: null
    };
  }

  public toggleIsLoading(isLoading: boolean) {
    this.updateState({isLoading});
  }

  public async buildTransferSummary(
    locationId: string,
    itemSelectionConfigs: IItemSelectionConfig[] = []
  ) {
    const stockReview = this.state.transferSummary;
    const itemUnitConfigs = itemSelectionConfigs
      .flatMap(iuc => iuc.itemUnits);

    if (stockReview.reviewItems?.length) {
      stockReview.reviewItems = [];
    }

    if (itemSelectionConfigs.length > 0) {
      this.toggleIsLoading(true);

      const itemUnitIds = itemUnitConfigs
        .map(iuc => iuc.itemUnit.id);
      const questionAnswer$ = this.questionAnswerHttpService.get({
        fields: ['id', 'quantityAnswer', 'createdAt'],
        filter: [{
          field: 'itemFieldsAnswerSheet.itemUnitId',
          operator: CondOperator.IN,
          value: itemUnitIds
        }, {
          field: 'question.type',
          operator: CondOperator.EQUALS,
          value: QuestionTypes.quantity
        }],
        join: [
          {field: 'itemFieldsAnswerSheet'},
          {field: 'itemFieldsAnswerSheet.itemUnit'},
          {field: 'itemFieldsAnswerSheet.location'},
          {field: 'question', select: ['type']},
        ]
      });
      const itemUnits$ = this.itemUnitHttpService.get({
        fields: ['quantity', 'createdAt'],
        filter: {
          field: 'id',
          operator: CondOperator.IN,
          value: itemUnitIds
        },
        join: [
          {field: 'item'},
          {field: 'unit'},
          {field: 'location'}
        ]
      });

      forkJoin([
        questionAnswer$,
        itemUnits$
      ]).subscribe((response: IPagination<IQuestionAnswer | IItemUnit>[]) => {
        const questionAnswers = response[0]?.data as IQuestionAnswer[];
        const itemUnits = response[1]?.data as IItemUnit[];
        const quantityQuestionsByItemUnit = groupBy<IItemUnitConfig, IStateManagedQuestion>(
          itemUnitConfigs,
          iuc => iuc.itemUnit.id,
          iuc => iuc.questions
            .filter(q => q.question.type === 'quantity')
        );

        for (const key in quantityQuestionsByItemUnit) {
          const countedQuantity = quantityQuestionsByItemUnit[key]
            .reduce((questionQuantityCount, q) => {
              return questionQuantityCount + q.answers.reduce((answerQuantityCount, a) => {
                return answerQuantityCount + a.quantityAnswer;
              }, 0);
            }, 0);
          const latestItemUnit = itemUnits.find(iu => iu.id === key);
          let stockReviewItem = stockReview.reviewItems
            .find((ri: ITransferSummaryItem) => ri.itemUnitId === latestItemUnit.id);

          if (!stockReviewItem) {
            stockReviewItem = stockReview.addTransferSummaryItem(
              countedQuantity,
              latestItemUnit,
              latestItemUnit.item.name,
              latestItemUnit.unit.label,
            );
          } else {
            stockReviewItem.countedQuantity = countedQuantity;
          }

          for (const questionAnswer of questionAnswers) {
            const itemFieldsAnswerSheet = questionAnswer.itemFieldsAnswerSheet;
            const location = itemFieldsAnswerSheet.location;
            const createdAt = questionAnswer.createdAt;
            const existingTransaction = stockReviewItem.transactions
              .find(t => t.questionAnswerId === questionAnswer.id);

            if (!existingTransaction) {
              stockReviewItem.addTransferSummaryTransaction(
                questionAnswer.id,
                questionAnswer.quantityAnswer,
                location,
                createdAt
              );
            }
          }

          stockReviewItem.transactions
            .sort((a, b) => sortByValue(a.createdAt, b.createdAt));
        }

        stockReview.recalculateTotals();

        this.updateState({transferSummary: stockReview});
        this.toggleIsLoading(false);
      });
    }
  }

  public buildSaveRequest(): ITransferSummaryItem[] {
    return this.state.transferSummary.reviewItems.map((ri: ITransferSummaryItem) => {
      return {
        expectedQuantity: ri.itemUnit.quantity,
        countedQuantity: ri.countedQuantity,
        itemUnitId: ri.itemUnit.id,
        itemName: ri.itemName,
        unitName: ri.unitName,
      };
    });
  }
}
