import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ParseTextWithUrls} from '../../../core/helpers/parse.helper';
import {StateManagedQuestion} from '../services/state-managed-question';

@Component({
  selector: 'app-answer-sheet-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss']
})
export class QuestionAnswerComponent {
  @Input()
  public managedQuestion: StateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<any> = new EventEmitter<any>();

  public isExpanded: boolean = false;

  public getParsedDescription(): string {
    const description = this.managedQuestion?.question?.description ?? '';

    return ParseTextWithUrls(description);
  }
}
