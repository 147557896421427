import {EventEmitter, Injectable} from '@angular/core';
import {QuestionDisplayHelper} from '@BritehouseMobile/field-services-shared';
import {NzTabComponent} from 'ng-zorro-antd/tabs';
import {BehaviorSubject} from 'rxjs';
import {FormVersionHttpService} from 'src/app/jobs/services/form-version-http.service';
import {StateManagedForm} from 'src/app/shared/answer-sheet/services/state-managed-form';
import {StateManagedQuestion} from 'src/app/shared/answer-sheet/services/state-managed-question';
import {StateManagedSection} from 'src/app/shared/answer-sheet/services/state-managed-section';
import {FormVersionAnswerSheetHttpService} from 'src/app/shared/form-answers/form-version-answer-sheet-http.service';
import {ItemUnitHttpService} from '../../../items/services/item-unit-http.service';
import {QuestionTypes} from '../../form-builder/question/question-types';
import {groupBy, sortByValue} from '../../helpers/list.helper';
import {IItemSelectionConfig, IItemUnitConfig} from '../item-fields/services/item-fields-state.interface';
import {ItemFieldsStateService} from '../item-fields/services/item-fields-state.service';

@Injectable({providedIn: 'root'})
export class FormAnswerSheetStateService {
  // Public Properties
  public activeSectionTab: BehaviorSubject<NzTabComponent> = new BehaviorSubject<NzTabComponent>(null);
  public isLoading: boolean = false;
  public isTabLoading: boolean = false;
  public currentFormVersion: IFormVersion;
  public formVersionAnswerSheet: IFormVersionAnswerSheet;
  public managedForm: IStateManagedForm;

  public questionAnswered: EventEmitter<IQuestionAnswer[]> = new EventEmitter();
  public answerSheetUpdated: EventEmitter<IFormVersionAnswerSheet> = new EventEmitter();
  public answerSheetProgressUpdated: EventEmitter<IFormAnswerSheetProgress> = new EventEmitter();
  public sectionChanged: EventEmitter<NzTabComponent> = new EventEmitter<NzTabComponent>();

  // Private Properties
  private managedQuestions: IStateManagedQuestion[];
  private questionToSectionMap: Map<IStateManagedQuestion, IStateManagedSection>;
  private visibilityConditionalsToAffectedQuestionsMap: Map<IStateManagedQuestion, IStateManagedQuestion[]>;
  private _initialized: boolean = false;
  private questionDisplayHelper: QuestionDisplayHelper = new QuestionDisplayHelper();

  constructor(
    private answerSheetHttpService: FormVersionAnswerSheetHttpService,
    private formVersionHttpService: FormVersionHttpService,
    private itemFieldsStateService: ItemFieldsStateService,
    private itemUnitHttpService: ItemUnitHttpService
  ) {
  }

  // Public Methods
  public answerUpdated(question: IStateManagedQuestion) {
    if (this._initialized && question.answers?.length) {
      this.questionAnswered.emit(question.answers);
    }

    this.emitAnswerSheet();
    this.updateQuestionsVisibility(question);
    this.emitProgressUpdate();
  }

  public async initializeService(
    formVersionAndForm: IFormVersion,
    answerSheet: IFormVersionAnswerSheet
  ): Promise<StateManagedForm> {
    const questionsBySection = groupBy(
      answerSheet.questionAnswers,
      (qa: IQuestionAnswer) => qa.sectionId,
      (qa: IQuestionAnswer) => qa
    );

    this._initialized = false;
    this.managedQuestions = [];
    this.questionToSectionMap = new Map();
    this.managedForm = new StateManagedForm(
      answerSheet.formVersionId,
      answerSheet.formVersion.form.title,
      answerSheet.formVersion.version,
      [],
      answerSheet.id,
    );

    for (const sectionId in questionsBySection) {
      const section = formVersionAndForm.sections.find(s => s.id === sectionId);
      const questionAnswers = questionsBySection[sectionId];
      const answersByQuestion = groupBy(
        questionAnswers,
        (qa: IQuestionAnswer) => qa.questionId,
        (qa: IQuestionAnswer) => qa
      );
      const managedQuestions = Object.keys(answersByQuestion).map((questionId: string) => {
        const question = section.questions.find(q => q.id === questionId);

        return this.mapManagedQuestion(section, question, answersByQuestion[questionId]);
      });
      const managedSection = new StateManagedSection(
        section.id,
        section.type,
        section.title,
        section.order,
        managedQuestions.sort((a: IStateManagedQuestion, b: IStateManagedQuestion) => {
          return sortByValue(a.question.order, b.question.order);
        })
      );

      for (const qa of questionAnswers) {
        // Assign item fields data
        if (section.type === 'item_fields') {
          await this.mapSectionToItemSelectionConfigs(qa.itemFieldsAnswerSheet, managedQuestions);
        }
      }

      managedQuestions.forEach((managedQuestion) => {
        this.questionToSectionMap.set(managedQuestion, managedSection);
      });

      this.managedForm.sections.push(managedSection);
      this.managedForm.sections.sort((a, b) => sortByValue(a.order, b.order));
    }

    this.currentFormVersion = formVersionAndForm;
    this.formVersionAnswerSheet = answerSheet;

    this.initializeVisibilityMap();
    this.emitAnswerSheet();
    this.emitProgressUpdate();

    this._initialized = true;

    return this.managedForm;
  }

  // Private Methods
  private mapManagedQuestion(
    section: ISection,
    question: IQuestion,
    questionAnswers: IQuestionAnswer[]
  ): IStateManagedQuestion {
    const isItemFields = section.type === 'item_fields';

    question.section = section;

    const managedQuestion = new StateManagedQuestion(question);

    managedQuestion.answers = questionAnswers;

    managedQuestion.answerUpdated.subscribe(() => {
      this.answerUpdated(managedQuestion);
    });

    if (!isItemFields && this.managedQuestions) {
      const existingQuestionIndex = this.managedQuestions.findIndex(q => q.question.publicId === managedQuestion.question.publicId);

      if (existingQuestionIndex > -1) {
        this.managedQuestions.splice(existingQuestionIndex, 1, managedQuestion);
      } else {
        this.managedQuestions.push(managedQuestion);
      }
    }

    return managedQuestion;
  }

  private async mapSectionToItemSelectionConfigs(
    itemFieldsAnswerSheet: IItemFieldsAnswerSheet,
    managedQuestions: IStateManagedQuestion[] = [],
    itemSelectionConfigs: IItemSelectionConfig[] = []
  ): Promise<void> {
    const item = itemFieldsAnswerSheet.item;
    const location = this.itemFieldsStateService.state?.location ?? null;
    const unitId = itemFieldsAnswerSheet.unitId;
    let itemSelectionConfig = itemSelectionConfigs.find(isc => isc.item?.id === item?.id);
    const itemUnits = await this.getItemUnitsForSectionAnswerSheet(item?.id, unitId);

    if (!itemSelectionConfig) {
      itemSelectionConfig = {
        item,
        location,
        itemUnits: []
      };
    }

    itemSelectionConfig.itemUnits.push({
      unitId,
      itemUnit: itemUnits.find(iu => iu.itemId === item?.id && iu.unitId === unitId),
      formVersionAnswerSheetId: itemFieldsAnswerSheet.formVersionAnswerSheetId,
      itemFieldsAnswerSheetId: itemFieldsAnswerSheet.id,
      questions: managedQuestions,
      subtotal: managedQuestions.filter((mq: IStateManagedQuestion) => mq.question.type === 'quantity')
        .reduce((memo: number, mq: IStateManagedQuestion) => memo += mq.answers?.length ? mq.answers[0].quantityAnswer : 0, 0)
    });
  }

  private async getItemUnitsForSectionAnswerSheet(itemId: string, unitId: string): Promise<IItemUnit[]> {
    if (!itemId || !unitId) {
      return [];
    }

    const itemUnitsResult = await this.itemUnitHttpService
      .get({
        filter: [{
          field: 'itemId',
          operator: '$eq',
          value: itemId
        }, {
          field: 'unitId',
          operator: '$eq',
          value: unitId
        }]
      })
      .toPromise();

    return itemUnitsResult.data ?? [];
  }

  private initializeVisibilityMap(): void {
    this.visibilityConditionalsToAffectedQuestionsMap = new Map();

    for (const managedQuestion of this.managedQuestions) {
      const conditioningQuestionId =
        managedQuestion.question.displayLogicConditionalQuestionId;

      const conditioningManagedQuestion = this.managedQuestions
        .find((q: IStateManagedQuestion) => q.question.id === conditioningQuestionId);

      if (!!conditioningManagedQuestion) {
        if (!this.visibilityConditionalsToAffectedQuestionsMap.has(conditioningManagedQuestion)) {
          this.visibilityConditionalsToAffectedQuestionsMap.set(conditioningManagedQuestion, []);
        }
        this.visibilityConditionalsToAffectedQuestionsMap
          .get(conditioningManagedQuestion)
          .push(managedQuestion);
      }
    }
  }

  private emitAnswerSheet(): void {
    const answerSheet = this.formVersionAnswerSheet;

    this.managedForm.sections.forEach(section => {
      const matchingSection: ISection = this.currentFormVersion.sections?.find(s => s.id === section.sectionId);
      const isItemFields = section.sectionType === 'item_fields';

      if (matchingSection) {
        if (isItemFields) {
          this.itemFieldsStateService.state
            .itemSelectionConfigs
            .forEach((itemSelectionConfig: IItemSelectionConfig) => {
              itemSelectionConfig.itemUnits
                .forEach((itemUnitConfig: IItemUnitConfig) => {
                  if (itemUnitConfig.itemUnit) {
                    const existingItemFieldsAnswerSheet = answerSheet.itemFieldsAnswerSheets
                      .find((ifas: IItemFieldsAnswerSheet) => ifas.itemUnitId === itemUnitConfig.itemUnit.id);

                    if (existingItemFieldsAnswerSheet) {
                      itemUnitConfig.questions
                        .filter((managedQuestion) => managedQuestion.answers.length > 0)
                        .forEach((managedQuestion: IStateManagedQuestion) => {
                          existingItemFieldsAnswerSheet.questionAnswers = [
                            ...existingItemFieldsAnswerSheet.questionAnswers.filter(qa => qa.questionId !== managedQuestion.question.id),
                            ...managedQuestion.answers
                          ];
                        });
                    } else {
                      answerSheet.itemFieldsAnswerSheets.push({
                        id: null,
                        formVersionAnswerSheetId: itemUnitConfig.formVersionAnswerSheetId,
                        itemId: itemUnitConfig.itemUnit.itemId,
                        unitId: itemUnitConfig.itemUnit.unitId,
                        locationId: itemUnitConfig.itemUnit.locationId,
                        sectionId: section.sectionId,
                        itemUnitId: itemUnitConfig.itemUnit.id,
                        itemUnit: itemUnitConfig.itemUnit,
                        itemUnitCode: itemUnitConfig.itemUnit.barcode,
                        itemUnitCost: itemUnitConfig.itemUnit.cost,
                        itemUnitPrice: itemUnitConfig.itemUnit.price,
                        itemUnitQuantity: itemUnitConfig.itemUnit.quantity,
                        locationCode: itemSelectionConfig.location.code,
                        locationName: itemSelectionConfig.location.name,
                        unitLabel: itemUnitConfig.itemUnit.unit.label,
                        itemName: itemSelectionConfig.item.name,
                        itemBarCode: itemSelectionConfig.item.barcode,
                        itemSerialNumber: itemSelectionConfig.item.serialNumber,
                        questionAnswers: itemUnitConfig.questions
                          .filter((managedQuestion) => !!managedQuestion.answers.length)
                          .map((managedQuestion) => managedQuestion.answers)
                          .reduce((allAnswers, singleQuestionAnswers) => {
                            allAnswers.push(...singleQuestionAnswers);
                            return allAnswers;
                          }, [])
                      });
                    }
                  }
                });
            });
        } else {
          section.questions
            .filter((managedQuestion) => managedQuestion.answers.length > 0)
            .forEach((managedQuestion: IStateManagedQuestion) => {
              answerSheet.questionAnswers = [
                ...answerSheet.questionAnswers.filter(qa => qa.questionId !== managedQuestion.question.id),
                ...managedQuestion.answers
              ];
            });
        }
      }
    });

    if (!answerSheet.id && this.managedForm.formVersionAnswerSheetId) {
      answerSheet.id = this.managedForm.formVersionAnswerSheetId;
    }

    this.answerSheetUpdated.emit(answerSheet);
  }

  private updateQuestionsVisibility(questionAnswered: IStateManagedQuestion): void {
    const managedQuestionsAffectedByAnswer =
      this.visibilityConditionalsToAffectedQuestionsMap.get(questionAnswered);

    if (!managedQuestionsAffectedByAnswer) {
      return;
    }

    for (const managedQuestion of managedQuestionsAffectedByAnswer) {
      managedQuestion.isDisplayed = this
        .questionDisplayHelper
        .calculateQuestionVisibilityBasedOnAnswer(
          // @ts-ignore
          managedQuestion.question.displayLogicConfiguration,
          questionAnswered.answers,
          questionAnswered.question.type as QuestionTypes,
        );
    }
  }

  private emitProgressUpdate(): void {

    this.updateFormAnswerSheetProgress();

    const answerSheetProgress: IFormAnswerSheetProgress = {
      jobFormAnswerSheetId: this.managedForm.formVersionAnswerSheetId,
      jobFormVersionId: this.managedForm.formVersionId,
      completion: this.managedForm.completion,

      sectionCompletion: this.managedForm.sections.map((managedSection) => ({
        sectionId: managedSection.sectionId,
        completion: managedSection.completion,
      })),

    };

    this.answerSheetProgressUpdated.emit(answerSheetProgress);
  }

  private updateFormAnswerSheetProgress(): void {
    const calculateCompletion = (managedQuestions: IStateManagedQuestion[]) => {
      let completion = 1;

      const requiredQuestions = managedQuestions.filter(
        (managedQuestion) => managedQuestion.question.configuration.isRequired
      );

      const numberOfRequiredQuestions = requiredQuestions.length;

      if (numberOfRequiredQuestions !== 0) {
        const requiredQuestionsAnswered = requiredQuestions.filter(
          (managedQuestion) => !!managedQuestion.answers.length
        );
        const numberRequiredQuestionsAnswered = requiredQuestionsAnswered.length;

        const numberOfQuestions = managedQuestions.length;

        completion = (
          numberOfQuestions - (numberOfRequiredQuestions - numberRequiredQuestionsAnswered)
        ) / numberOfQuestions;
      }

      return completion;
    };

    this.managedForm.completion =
      calculateCompletion(this.managedQuestions.filter(
        (managedQuestion) => managedQuestion.isDisplayed,
      ));

    this.managedForm.sections.forEach((managedSection) => {
      if (managedSection.sectionType === 'item_fields') {
        let flattenedSectionQuestions = [];

        this.itemFieldsStateService.state
          .itemSelectionConfigs
          .forEach((itemSelectionConfig: IItemSelectionConfig) => {
            itemSelectionConfig.itemUnits
              .forEach((itemUnitConfig: IItemUnitConfig) => {
                flattenedSectionQuestions = flattenedSectionQuestions.concat(itemUnitConfig.questions);
              });
          });

        managedSection.completion =
          calculateCompletion(flattenedSectionQuestions.filter(
            (managedQuestion) => managedQuestion.isDisplayed,
          ));
      } else {
        managedSection.completion =
          calculateCompletion(managedSection.questions.filter(
            (managedQuestion) => managedQuestion.isDisplayed,
          ));
      }
    });
  }
}
