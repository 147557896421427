import { Observable } from 'rxjs';

export function promesify(result: any): Promise<any> {
    if (result instanceof Observable) {
        return new Promise(resolve => {
            result.subscribe(res => resolve(res));
        });
    }

    return new Promise(resolve => resolve(result));
}
