<nz-form-control [nzErrorTip]="errorTpl">
  <div nz-row>
    <h3 nz-col [nzSpan]="8" class="question">Time log:&nbsp;</h3>
    <p nz-col [nzSpan]="8">{{ timeFormatter(globalTimeData.count) }}</p>

    <button
      *ngIf="!timerStart"
      [nzSpan]="8"
      nz-col
      class="fs-timer-btn"
      (click)="startStop()">
      <img
        width="50"
        height="50"
        alt="Start Timer"
        title="Start Timer"
        src="/assets/play-button.svg"/>
    </button>

    <button
      *ngIf="timerStart"
      [nzSpan]="8"
      nz-col
      class="fs-timer-btn"
      (click)="startStop()">
      <img
        width="50"
        height="50"
        alt="Stop Timer"
        title="Stop Timer"
        src="/assets/stop-button.svg"/>
    </button>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="8">
      <p class="u-text--bold">First start timestamp</p>
      <p>{{ globalTimeData.start }}</p>
    </div>
    <div nz-col [nzSpan]="8">
      <p class="u-text--bold">Last stop timestamp</p>
      <p>{{ globalTimeData.end }}</p>
    </div>
    <div nz-col [nzSpan]="4">
      <p class="u-text--bold">Current time</p>
      <p>{{ timeFormatter(timerData.count) }}</p>
    </div>
    <div nz-col [nzSpan]="4">
      <!--<button type="button" class="button fs-timer-input" (click)="startStop()">-->
      <!--  <i nz-icon nzType="play-circle" *ngIf="!timerStart"></i>-->
      <!--  <i nz-icon nzType="stop" *ngIf="timerStart"></i>-->
      <!--</button>-->
    </div>
  </div>
  <div nz-row>
    <h4 nz-col [nzSpan]="24">Registered Sessions</h4>
    <table nz-col [nzSpan]="24" class="c-table--full">
      <thead>
        <tr>
          <th class="u-text--bold" scope="col">Start</th>
          <th class="u-text--bold" scope="col">Stop</th>
          <th class="u-text--bold" scope="col">Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of listOfData">
          <td>{{ data.start }}</td>
          <td>{{ data.end }}</td>
          <td>{{ timeFormatter(data.count) }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #errorTpl>
    <div class="error form-control-msg" *ngIf="answer.hasError('required')">
      This question is required
    </div>
  </ng-template>
</nz-form-control>


