<ng-container *ngIf="isPublicRoute; else privateRoute">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #privateRoute>
  <app-tenant-selector *ngIf="!authenticationService.showForbidden.getValue() && authenticationService.currentB2CUserData | async as user">
  </app-tenant-selector>

  <nz-spin [nzSpinning]="appStateService.state.isPageLoading">
    <nz-layout class="base-layout">
      <app-side-navigation-menu *ngIf="!authenticationService.showForbidden.getValue() && authenticationService.currentB2CUserData | async as user">
      </app-side-navigation-menu>

      <nz-layout class="scrollable-layout">
        <router-outlet></router-outlet>
      </nz-layout>
    </nz-layout>
  </nz-spin>
</ng-template>
