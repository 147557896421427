import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PublicSurveyAuthInterceptor implements HttpInterceptor {

  private get authHeaderValues(): {
    surveyId: string;
    surveyPassword?: string;
  } {
    const authHeaderValues = sessionStorage.getItem('public-auth-header-values');
    return !!authHeaderValues ? JSON.parse(authHeaderValues) : null;
  }

  public constructor() { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authHeaderValues = this.authHeaderValues;
    if (!!authHeaderValues) {
      request = request.clone({ headers: request.headers.set('x-survey-id', authHeaderValues.surveyId) });
      if (!!authHeaderValues.surveyPassword) {
        request = request.clone({ headers: request.headers.set('x-survey-password', authHeaderValues.surveyPassword) });
      }
    }
    return next.handle(request);
  }
}
