<nz-form-control [nzErrorTip]="errorTpl">

  <nz-input-group [nzSuffix]="suffixIconCoordinates" nzSize="large" class="coordinates" (click)="setAnswerAsCurrentCoordinates()">
    <input class="fs-gps-input" [readonly]="true" type="text" nz-input [formControl]="answer"/>
  </nz-input-group>
  <ng-template #suffixIconCoordinates>
    <i nz-icon>
      <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
    </svg>
    </i>
  </ng-template>

  <ng-template #errorTpl>
    <div class="error form-control-msg" *ngIf="answer.hasError('required')">
      This question is required
    </div>
  </ng-template>

</nz-form-control>
