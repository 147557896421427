export class LocalStorageHelper {
  static set<T>(key: string, value: T, preserveFunctions: boolean = false): void {
    if (!localStorage) {
      console.warn('This platform does not support Local Storage. Please reconsider your storage mechanism.');
    }

    if (!key) {
      throw new Error('You must provide a valid key to store your data against in Local Storage.');
    }

    if (typeof value === 'undefined' || value === null) {
      throw new Error('You should not be storing null and undefined values in local storage. ' +
        'To remove an item from Local Storage, call LocalStorageHelper.remove method.');
    }

    try {
      const stringifiedValue = JSON.stringify(value, (objectKey, objectValue) => {
        return (typeof objectValue === 'function') && preserveFunctions ? '' + objectValue : objectValue; // This includes functions
      });

      localStorage.setItem(key, stringifiedValue);
    } catch (error) {
      throw new Error('An error occurred while attempting to store a value in Local Storage.\r\n'
        + error.message || JSON.stringify(error));
    }
  }

  static get<T>(key: string) {
    if (!localStorage) {
      console.warn('This platform does not support Local Storage. Please reconsider your storage mechanism.');
    }

    if (!key) {
      throw new Error('You must provide a valid key to retrieve your data from Local Storage.');
    }

    try {
      const value = localStorage.getItem(key);
      return JSON.parse(value, (objectKey, objectValue) => {
        return objectValue
        && typeof objectValue === 'string'
        && (objectValue.indexOf('function(') > -1 || objectValue.indexOf('function (') > -1 || objectValue.indexOf('=>') > -1)
          ? LocalStorageHelper.parse(objectValue)
          : objectValue;
      }); // This includes functions
    } catch (error) {
      throw new Error('An error occurred while attempting to retrieve a value from Local Storage.\r\n'
        + error.message || JSON.stringify(error));
    }
  }

  static remove(key: string): void {
    if (!localStorage) {
      console.warn('This platform does not support Local Storage. Please reconsider your storage mechanism.');
    }

    if (!key) {
      throw new Error('You must provide a valid key to remove your data from Local Storage.');
    }

    try {
      localStorage.removeItem(key);
    } catch (error) {
      throw new Error('An error occurred while attempting to remove a value from Local Storage.\r\n'
        + error.message || JSON.stringify(error));
    }
  }

  private static parse(fn: string) {
    return Function('"use strict";return (' + fn + ')')();
  }
}
