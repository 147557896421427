<div *ngIf="!!answers.controls;" class="text-inputs-wrapper">
  <div *ngFor="let answer of answerControls; index as index" class="text-input-row">
    <nz-form-control [nzErrorTip]="errorTpl">
      <nz-input-group nzSize="large" [nzAddOnBefore]="getAddOnBefore()" [nzAddOnAfter]="getAddOnAfter()">
        <input class="fs-text-input" nz-input type="text" nzSize="large" [formControl]="answer"/>
      </nz-input-group>

      <ng-template #errorTpl>
        <div class="error form-control-msg" *ngIf="answer.hasError('email')">
          This is not a valid email
        </div>

        <div class="error form-control-msg" *ngIf="answer.hasError('required')">
          {{managedQuestion.question.title}} is required
        </div>
      </ng-template>

      <div class="error form-control-msg" *ngIf="touched && showOneOfMultipleAnswersRequired">
        At least one answer should be provided for {{managedQuestion.question.title}}
      </div>
    </nz-form-control>
    <a
      class="question-action"
      *ngIf="allowMultipleAnswers"
      [class.not-visible]="answers.controls.length < 2"
      (click)="removeAnswer(index)"
      nz-button
      nzType="link">
      <i nz-icon nzType="delete"></i>
    </a>
  </div>
  <button
    *ngIf="allowMultipleAnswers"
    nz-button
    nzType="default"
    nzSize="large"
    (click)="addAnswer()">
    Add another
  </button>
</div>
