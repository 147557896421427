<app-attachment-uploader
  [allowedAttachmentTypes]="allowedAttachmentTypes"
  (attachmentCreated)="addAttachment($event)">
</app-attachment-uploader>

<app-attachment-list
  [deletableAttachments]="true"
  [attachments]="attachments"
  (deleteAttachment)="deleteAttachment($event)">
</app-attachment-list>

<div *ngIf="!isPristineAttachments && !attachments.length" class="error form-control-msg">
  This question is required
</div>
