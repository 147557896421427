import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import {NzNotificationServiceModule} from 'ng-zorro-antd/notification';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import { UnauthorizedRequestRetryInterceptor } from 'src/app/core/authentication/unauthorized-request-retry.interceptor';
import { FieldServicesErrorHandler } from 'src/app/core/errors/error-handler';
import { PublicSurveyAuthInterceptor } from 'src/app/public-survey/services/public-survey-auth.interceptor';
import { InjectorHelper } from 'src/app/shared/helpers/injector.helper';
import { SharedModule } from 'src/app/shared/shared.module';
import { TenantHeaderInjectorInterceptor } from 'src/app/shared/tenant-selector/tenant-header-injector.interceptor';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './core/authentication/authentication.module';
import { oAuthModuleConfig } from './core/authentication/oauth-configuration';
import { ErrorPagesModule } from './core/error-pages/error-pages.module';
import { IconsProviderModule } from './icons-provider.module';
import { SettingsPageModule } from './settings/settings.module';

registerLocaleData(en);

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    AuthenticationModule,
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(oAuthModuleConfig),
    IconsProviderModule,
    NzLayoutModule,
    BrowserAnimationsModule,
    SettingsPageModule,
    CommonModule,
    SharedModule,
    ErrorPagesModule,
    AngularFireModule.initializeApp(environment.firebase),
    NzNotificationServiceModule,
    NzSpinModule,
    NzNotificationServiceModule
  ],
  providers: [
    FieldServicesErrorHandler, // For direct Sentry reporting
    { provide: NZ_I18N, useValue: en_US },
    (!!environment.useSentry ? {
      provide: ErrorHandler, useClass: FieldServicesErrorHandler,
    } : {
      provide: ErrorHandler, useClass: ErrorHandler,
    }),
    PublicSurveyAuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PublicSurveyAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedRequestRetryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantHeaderInjectorInterceptor,
      multi: true,
    },
    {
      provide: OAuthStorage,
      useFactory: storageFactory
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorHelper.injector = this.injector;
  }
}
