import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyTypeStatusService extends BaseCrudService<ISurveyTypeStatus> {

  constructor(public http: HttpClient) {
    super(http, 'survey-type-statuses');
  }

  public reorderSurveys(surveyTypes: ISurveyType[]): Observable<ISurveyTypeStatus[]> {
    const body = surveyTypes.map(s => ({ id: s.id, order: s.order }));
    const httpRequest = new HttpRequest('PATCH', `${this.url}/reorder`, body);

    return this.execute(httpRequest);
  }

  public isDeactivatable(statusId: string): Observable<boolean> {
    return this
      .http
      .get<boolean>(`${this.url}/deactivatable/${statusId}`)
      .pipe(take(1));
  }
}
