<nz-select
  *ngIf="!isReactive"
  [(ngModel)]="model"
  [id]="inputId"
  [ngClass]="{'fs-select-multiple-width': mode === 'multiple'}"
  [nzDisabled]="isDisabled"
  [nzDropdownRender]="renderTemplate"
  [nzPlaceHolder]="placeholder"
  [nzMode]="mode"
  [nzSize]="size"
  nzAllowClear
  nzShowSearch
  (nzScrollToBottom)="loadMore()"
  (nzOnSearch)="setSearchValue($event)"
  (ngModelChange)="modelChange.emit($event)">
  <nz-option
    *ngFor="let jC of options"
    [nzDisabled]="(jC.isDisabled === true) && jC.id !== model"
    [nzLabel]="jC.label"
    [nzValue]="jC.id">
  </nz-option>
</nz-select>

<nz-select
  *ngIf="isReactive"
  [id]="inputId"
  [ngClass]="{'fs-select-multiple-width': mode === 'multiple'}"
  [formControl]="formControl"
  [nzDropdownRender]="renderTemplate"
  [nzPlaceHolder]="placeholder"
  [nzMode]="mode"
  [nzSize]="size"
  nzAllowClear
  nzShowSearch
  (nzScrollToBottom)="loadMore()"
  (nzOnSearch)="setSearchValue($event)"
  (ngModelChange)="modelChange.emit($event)">
  <nz-option
    *ngFor="let jC of options"
    [nzDisabled]="(jC.isDisabled === true) && jC.id !== formControl.value"
    [nzLabel]="jC.label"
    [nzValue]="jC.id">
  </nz-option>
</nz-select>

<ng-template #renderTemplate>
  <div class="fs-select-loader-padding">
    <nz-spin *ngIf="isLoading"></nz-spin>
  </div>
</ng-template>
