import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NzCascaderComponent, NzCascaderOption} from 'ng-zorro-antd/cascader';
import {BehaviorSubject} from 'rxjs';

export type CascadeLoadDataRequest = {
  option: NzCascaderOption;
  callback: (options?: NzCascaderOption[]) => void;
};

@Component({
  selector: 'app-fs-cascader',
  templateUrl: './fs-cascader.component.html',
  styleUrls: ['./fs-cascader.component.scss']
})
export class FsCascaderComponent implements OnInit {
  // Input Params
  @Input() public inputId: string;
  @Input() public options: BehaviorSubject<NzCascaderOption[]> = new BehaviorSubject<NzCascaderOption[]>([]);
  @Input() public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  @Input() public isDisabled: boolean = false;
  @Input() public isRequired: boolean = false;
  @Input() public changeOnSelect: boolean = false;
  @Input() public allowClear: boolean = true;
  @Input() public size?: 'large' | 'default' | 'small' = 'large';
  @Input() public placeholder?: string = 'Select an option';
  @Input() public valueOnChanges: (option: NzCascaderOption, level: number) => boolean;
  @Input() public model: NzCascaderOption | Array<NzCascaderOption>;
  @Input() public form?: FormGroup;
  @Input() public controlName?: string;

  @Output() public valueChange: EventEmitter<{path: NzCascaderOption[], current: NzCascaderOption}>
    = new EventEmitter<{path: NzCascaderOption[], current: NzCascaderOption}>();
  @Output() public modelChange: EventEmitter<NzCascaderOption> = new EventEmitter<NzCascaderOption>();
  @Output() public searchUpdate: EventEmitter<string> = new EventEmitter<string>();
  @Output() public cascadeLoadData: EventEmitter<CascadeLoadDataRequest> = new EventEmitter<CascadeLoadDataRequest>();
  @Output() public searchItemSelected: EventEmitter<NzCascaderOption> = new EventEmitter<NzCascaderOption>();
  @Output() public clear: EventEmitter<void> = new EventEmitter<void>();

  // View Children
  @ViewChild(NzCascaderComponent) control: NzCascaderComponent;

  // Public Properties
  public isReactive: boolean = false;
  public searchConfig = {
    filter(search: string, path: NzCascaderOption[]): boolean {
      return path.some(i => i.label && i.label.toLowerCase().indexOf(search.toLowerCase()) > -1) || path[0].isLoader;
    }
  };

  // Private Properties
  private _control: any;
  private _search: string;
  private _debounce: any;
  private _nodesLoaded: string[] = [];
  private _preventDataLoad: boolean = false;
  private _dirty: boolean = false;

  // Getters / Setters
  get dirty(): boolean {
    return this._dirty;
  }

  get hasRequiredError(): boolean {
    return this.isRequired && !this.model;
  }

  ngOnInit(): void {
    if (this.form && !this.controlName) {
      console.warn('FS Cascader: You have set the "form" (formGroup) for this component but have failed ' +
        'to provide a "controlName" property value (formControlName). Reactive forms requires this attribute value ' +
        'in order to function correctly. Your value binding will likely not work correctly.');
    }

    if (!this.form && this.controlName) {
      console.warn('FS Cascader: You have set the "controlName" to ' + this.controlName + ' for this component ' +
        'but have failed to provide a "form" property value (formControlName). Reactive forms requires this attribute value ' +
        'in order to function correctly. Your value binding will likely not work correctly.');
    }

    if (this.form && this.controlName) {
      this.isReactive = true;
      this._control = this.form.get(this.controlName);
    }
  }

  public onModelChange(value: NzCascaderOption[]) {
    if (!this._dirty) {
      this._dirty = true;
    }

    // Do nothing if this is a pointless emit from ngModelChange on template drive forms (on dropdown click)
    if (!this.isReactive && (!value || !value.length) && (this.model && this.model.length > 0)) {
      return;
    }

    if (!value || !value.length && this.clear) {
      this.clear.emit();
    }

    if (value.length === 1) {
      const options = this.options.getValue();
      const selectedValue = typeof value[0] === 'string'
        ? options.find(o => o.value === value[0])
        : value[0];
      const isLeaf = selectedValue.isLeaf;

      if (isLeaf) {
        this.searchItemSelected.emit(selectedValue);

        this._preventDataLoad = true;

        return;
      }
    }

    if (!this.isReactive) {
      this.modelChange.emit(value);
    }

    this.valueChange.emit({
      path: value,
      current: !!value && Array.isArray(value) ? value[value.length - 1] : value
    });
  }

  public search(element: EventTarget) {
    const search = (element as HTMLInputElement).value;

    if (!this.searchUpdate) {
      return;
    }

    this.options.next([{isLoader: true, isLeaf: true}]);
    this.isLoading.next(true);

    if (search !== this._search && this._debounce) {
      clearTimeout(this._debounce);
    }

    this._debounce = setTimeout(() => {
      this.searchUpdate.emit((element as HTMLInputElement).value);
    }, 500);
  }

  public valueOnChangesProcessor(option: NzCascaderOption, level: number): boolean {
    if (this.valueOnChanges) {
      return this.valueOnChanges(option, level);
    }

    return true;
  }

  public loadData(node: NzCascaderOption): PromiseLike<void> {
    return new Promise((resolve, reject) => {
      if (this._preventDataLoad) {
        setTimeout(() => {
          this.control.cascaderService.syncOptions();
        }, 250);

        this._preventDataLoad = false;

        resolve();
      }

      try {
        const hasData = Array.isArray(node) ? node.length > 0 : !!node;

        if (hasData) {
          const request: CascadeLoadDataRequest = {
            option: node,
            callback: () => {
              this.control.cascaderService.syncOptions();

              resolve();
            }
          };

          if (this._nodesLoaded.indexOf(node.value) < 0 || (!Array.isArray(node) && !node?.children?.length)) {
            this.cascadeLoadData.emit(request);

            this._nodesLoaded.push(node.value);
          }
        }
      } catch (e) {
        const error = Error('An error occurred while trying to collect cascaded data.\r\n' + e);

        reject(error);
      }
    });
  }
}
