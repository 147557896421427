<nz-form-control>

  <div class="loading-placeholder" *ngIf="isLoading; else content">
    <nz-spin></nz-spin>
  </div>

  <ng-template #content>

    <div nz-row>
      <div nz-col nzSpan="24">
        <app-fs-cascader
          controlName="answer"
          [form]="answerFormGroup"
          [isLoading]="itemCascaderService.isLoading"
          [options]="itemCascaderService.options"
          (cascadeLoadData)="itemCascaderService.loadItemsForItemTypeOption($event)"
          (searchUpdate)="itemCascaderService.searchItemTypeOptions($event)"
          (searchItemSelected)="setHierarchyForItem($event)"
          (clear)="itemCascaderService.restoreRootOptionsBackup()">
        </app-fs-cascader>
      </div>
    </div>

    <div class="error form-control-msg" *ngIf="answerFormGroup.controls['answer'].dirty && answerFormGroup.controls['answer'].hasError('required')">
      This question is required
    </div>
  </ng-template>

</nz-form-control>
