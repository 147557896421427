import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import {TenantStateService} from '../tenant-selector/tenant-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output()
  public logoClicked = new EventEmitter();

  public showTenantSwitchMenuItem: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public authenticationService: AuthenticationService,
    public tenantStorageService: TenantStateService,
    public router: Router,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.push(
      this
        .tenantStorageService
        .availableTenants
        .subscribe(tenants => {
          this.showTenantSwitchMenuItem = tenants.length > 1;
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public handleLogOutClick() {
    this.authenticationService.startLogoutFlow();
  }

  public handleMyProfileClick() {
    this.router.navigate(['my-profile']);
  }

  public handleMyOrganisationSettingsClick() {
    this.router.navigate(['organisation/settings']);
  }
}
