import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit, AfterViewInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public answer: FormControl;

  public constructor() {
  }

  public get dateFormat(): string {
    return this
      .managedQuestion
      .question
      .configuration
      .dateInputType === 'date_only' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss';
  }

  public get validDateEvaluator(): (current: Date) => boolean {
    const questionConfig = this.managedQuestion.question.configuration;
    return (current: Date): boolean => {
      if (!questionConfig.allowFutureDates && !questionConfig.allowPastDates) {
        return !moment(current).isSame(moment(), 'day');
      } else if (!questionConfig.allowFutureDates) {
        return moment(current).isAfter(moment(), 'day');
      } else if (!questionConfig.allowPastDates) {
        return moment(current).isBefore(moment(), 'day');
      }
    };
  }

  public ngOnInit(): void {
    this.answer = new FormControl(
      !!this.managedQuestion.answers.length ? this.managedQuestion.answers[0].timestampAnswer : undefined,
      this.managedQuestion.question.configuration.isRequired ? [Validators.required] : [],
    );

    this
      .answer
      .valueChanges
      .subscribe((answer) => {
        const answerArray = [];
        if (!!answer) {
          answerArray.push({
            questionId: this.managedQuestion.question.id,
            questionPublicId: this.managedQuestion.question.publicId,
            sectionPublicId: this.managedQuestion.question.section.publicId,
            sectionId: this.managedQuestion.question.sectionId,
            questionType: this.managedQuestion.question.type,
            timestampAnswer: answer,
          });
        }

        this.managedQuestion.answers = answerArray;

        this.valueChange.emit(answer);
      });
  }

  public ngAfterViewInit() {
    if (this.managedQuestion.question.configuration.dateInputType === 'time_only') {
      this.toggleTimePickerReadOnly();
    }
  }

  private toggleTimePickerReadOnly() {
    // Ant's current time picker component does not yet support nzInputReadOnly like
    // its date picker. We want to avoid users writting time values, so we have no recourse
    // but to add the readonly attribute to the DOM element by hand
    // TODO remove this hack once Ant's time picker support setting nzInputReadOnly
    const timePickerId = `time-picker-question-${this.managedQuestion.question.id}`;
    const querySelectorString = `#${timePickerId} input`;
    const input = document.querySelector(querySelectorString);
    if (!!input) {
      // @ts-ignore
      input.readOnly = true;
    } else {
      setTimeout(this.toggleTimePickerReadOnly.bind(this), 100);
    }
  }
}
