import {CondOperator, CreateQueryParams, QueryFilterArr} from '@nestjsx/crud-request';
import {QueryFilter, QuerySort, QuerySortArr} from '@nestjsx/crud-request/lib/types';
import {isNullOrUndefined} from '../helpers/truthy.helper';
import {FsTableFilter, IFsPaging} from './fs.paging';

export class FsPagingToNestQueryAdaptor implements CreateQueryParams {
  constructor(public adaptee: IFsPaging) {
  }

  get page(): number | null {
    const adaptee = this.adaptee;

    // If adaptee is null or paging is disabled, return null values
    if (!adaptee || !adaptee.isEnabled) {
      return null;
    }

    return adaptee.pageIndex;
  }

  get limit(): number | null {
    const adaptee = this.adaptee;

    // If adaptee is null or paging is disabled, return null values
    if (!adaptee || !adaptee.isEnabled) {
      return null;
    }

    return adaptee.pageSize;
  }

  get sort(): Array<QuerySort> {
    const adaptee = this.adaptee;

    // If adaptee is null or sort field is empty (invalid) or paging is disabled, return null values
    if (!adaptee || !adaptee.sortField || !adaptee.isEnabled) {
      return [];
    }

    return [{
      field: adaptee.sortField,
      order: adaptee.sortOrder === 'descend'
        ? 'DESC'
        : 'ASC'
    }];
  }

  get filter(): Array<QueryFilter> {
    const adaptee = this.adaptee;

    // If adaptee is null or no filters are set or paging is disabled, return null values
    if (!adaptee || !adaptee.filter || !adaptee.filter.length || !adaptee.isEnabled) {
      return [];
    }

    return this.adaptee.filter
      .filter(i => !isNullOrUndefined(i.value))
      .map((filter: FsTableFilter) => {
        return {
          field: filter.key,
          operator: filter.comparison || '$eq',
          value: filter.value
        };
      });
  }

  public query(query: CreateQueryParams = {}): CreateQueryParams {
    const filterList = this.filter;
    const sortList = this.sort;
    const page = this.page;
    const limit = this.limit;

    if (!this.adaptee) {
      return query;
    }

    // Adapt column fields for query field selection
    if (this.adaptee.columns.length) {
      if (!query.fields) {
        query.fields = [];
      }

      this.adaptee.columns.forEach((c: string) => {
        if (typeof c !== 'string') {
          throw new Error('Invalid "field" value passed to the adaptor query');
        }

        if (query.fields.indexOf(c) < 0) {
          query.fields.push(c);
        }
      });
    }

    // Adapt filters for query
    if (filterList.length) {
      if (query.search) {
        if (query.search.$and || query.search.$or) {
          // Make sure it is an empty array instead of undefined
          query.search.$and = query.search.$and || [];

          filterList.forEach(f => {
            const existingItem = query.search.$and.find(i => Object.keys(i).indexOf(f.field) > -1);

            if (existingItem) {
              existingItem[f.field][f.operator] = f.value;
            } else {
              query.search.$and.push({
                [f.field]: {
                  [f.operator]: f.value
                }
              });
            }
          });
        } else {
          filterList.forEach(f => {
            if (query.search[f.field]) {
              query.search[f.field][f.operator] = f.value;
            } else {
              query.search[f.field] = {
                [f.operator]: f.value
              };
            }
          });
        }
      } else {
        query.filter = [
          ...this.getValidFilter(query.filter),
          ...filterList.filter(f => {
            if (Array.isArray(f.value)) {
              return f.value.length > 0;
            }

            return !isNullOrUndefined(f.value);
          })
        ];
      }
    }

    // Adapt sorting for query
    if (sortList.length) {
      const fixedQuerySort = this.getValidSort(query.sort);

      // Only return one sort configuration (table or query)
      if (sortList.length) {
        query.sort = sortList;
      } else {
        query.sort = fixedQuerySort;
      }
    }

    // Adapt page number for query
    if (!isNullOrUndefined(page)) {
      query.page = page;
    }

    // Adapt page limit for query
    if (!isNullOrUndefined(limit)) {
      query.limit = limit;
    }

    return query;
  }

  public update(pageIndex: number, totalItems: number) {
    // Don't try to update the adaptee if paging is disabled
    if (!this.adaptee || !this.adaptee.isEnabled) {
      return;
    }

    this.adaptee.pageIndex = pageIndex;
    this.adaptee.totalItems = totalItems;
  }

  private getValidFilter(filter: QueryFilter | QueryFilterArr | Array<QueryFilter | QueryFilterArr>): Array<QueryFilter> {
    // If format is Array<QueryFilter
    let combinationArray = filter as Array<QueryFilter>;

    if (filter) {
      // If type is QueryFilter
      if ((filter as QueryFilter).field) {
        combinationArray = [(filter as QueryFilter)];
      }
      if (Array.isArray(filter)) {
        // If type is QueryFilterArr
        if ((filter as QueryFilterArr)[1]
          && Object.keys(CondOperator).indexOf((filter as QueryFilterArr)[1]) > -1) {
          const queryFilter = filter as QueryFilterArr;

          combinationArray = [{
            field: queryFilter[0],
            operator: queryFilter[1],
            value: queryFilter[2]
          }];
        } else {
          const array = (filter as Array<QueryFilterArr>);
          const firstItem = array[0];

          if (firstItem
            && firstItem[1]
            && Object.keys(CondOperator).indexOf((firstItem)[1]) > -1) {
            combinationArray = array.map(subArray => {
              return {
                field: subArray[0],
                operator: subArray[1],
                value: subArray[2]
              };
            });
          }
        }
      }
    }

    return combinationArray || [];
  }

  private getValidSort(sort: QuerySort | QuerySortArr | Array<QuerySort | QuerySortArr>): Array<QuerySort> {
    // If format is Array<QuerySort>
    let combinationArray = sort as Array<QuerySort>;

    if (sort) {
      // If type is QueryFilter
      if ((sort as QuerySort).field) {
        combinationArray = [(sort as QuerySort)];
      }
      if (Array.isArray(sort)) {
        // If type is QueryFilterArr
        if ((sort as QuerySortArr)[1]
          && ((sort as QuerySortArr)[1] === 'ASC'
            || (sort as QuerySortArr)[1] === 'DESC')) {
          const querySort = sort as QuerySortArr;

          combinationArray = [{
            field: querySort[0],
            order: querySort[1]
          }];
        } else {
          const array = (sort as Array<QuerySortArr>);
          const firstItem = array[0];

          if (firstItem
            && firstItem[1]
            && (firstItem[1] === 'ASC'
              || firstItem[1] === 'DESC')) {
            combinationArray = array.map(subArray => {
              return {
                field: subArray[0],
                order: subArray[1]
              };
            });
          }
        }
      }
    }

    return combinationArray || [];
  }
}
