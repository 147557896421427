import { Component } from '@angular/core';
import { IsNullOrUndefined } from 'src/app/core/helpers/type.helpers';
import { AppStateService } from '../../app-state.service';
import {APP_VERSION} from '../../core/versioning/version';
import { SideNavigationMenuItemProviderService } from './side-navigation-menu-item-provider.service';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent {
  public appState: IAppState;
  public version: string = APP_VERSION;
  public IsNullOrUndefined = IsNullOrUndefined;

  constructor(
    public appStateService: AppStateService,
    public sideNavigationMenuItemProviderService: SideNavigationMenuItemProviderService,
  ) {
    this.appStateService.listener.subscribe((state: IAppState) => this.appState = state);
  }
}
