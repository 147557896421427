import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzHighlightModule } from 'ng-zorro-antd/core/highlight';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxTimerModule } from 'ngx-timer';
import { AttachmentListComponent } from 'src/app/shared/attachments/attachment-list/attachment-list.component';
import { AttachmentUploaderComponent } from 'src/app/shared/attachments/attachment-uploader/attachment-uploader.component';
import { AttachmentComponent } from 'src/app/shared/attachments/attachment/attachment.component';
import { ColumnSelectionModalComponent } from 'src/app/shared/fs-table/column-selection-modal/column-selection-modal.component';
import { ArbitraryCustomerSelectorComponent } from './arbitrary-customer-selector/arbitrary-customer-selector.component';
import { ArbitraryItemSelectorComponent } from './arbitrary-item-selector/arbitrary-item-selector.component';
import { ArbitraryLocationSelectorComponent } from './arbitrary-location-selector/arbitrary-location-selector.component';
import { DetailedAttachmentsComponent } from './attachments/detailed-attachments/detailed-attachments.component';
import { CustomFieldsComponent } from './form-builder/custom-fields/custom-fields.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormSettingsComponent } from './form-builder/form-settings/form-settings.component';
import {
  EntitySelectionMockAnswerComponent
} from './form-builder/question/entity-selection-mock-answer/entity-selection-mock-answer.component';
import { QuestionComponent } from './form-builder/question/question.component';
import { ApiCallComponent } from './form-builder/question/settings/api-call/api-call.component';
import { ItemSelectionSettingsComponent } from './form-builder/question/settings/item-selection-settings/item-selection-settings.component';
import {
  ItemTypeConfigComponent
} from './form-builder/question/settings/item-selection-settings/item-type-config/item-type-config.component';
import {
  LocationSelectionSettingsComponent
} from './form-builder/question/settings/location-selection-settings/location-selection-settings.component';
import { QuestionSettingsComponent } from './form-builder/question/settings/question-settings.component';
import {
  SectionsSettingsItemComponent
} from './form-builder/sections-settings-modal/sections-settings-item/sections-settings-item.component';
import { SectionsSettingsModalComponent } from './form-builder/sections-settings-modal/sections-settings-modal.component';
import { TimerDemoComponent } from './form-builder/timer-demo/timer-demo.component';
import { FsCascaderComponent } from './fs-cascader/fs-cascader.component';
import { FsCountdownComponent } from './fs-countdown/fs-countdown.component';
import { FsJsonViewerModalComponent } from './fs-json-viewer-modal/fs-json-viewer-modal.component';
import { FsSelectComponent } from './fs-select/fs-select.component';
import { FsStringFormatterPipe } from './fs-table/fs-string-formatter.pipe';
import { FsTableComponent } from './fs-table/fs-table.component';
import { FsTimezoneSelectorComponent } from './fs-timezone-selector/fs-timezone-selector.component';
import { FsTreeComponent } from './fs-tree/fs-tree.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { HeaderComponent } from './header/header.component';
import { HttpStatusSelectorComponent } from './http-status-selector/http-status-selector.component';
import { LinkedCustomerListComponent } from './linked-customer-list/linked-customer-list.component';
import { LinkedItemListComponent } from './linked-item-list/linked-item-list.component';
import { LinkedJobListComponent } from './linked-job-list/linked-job-list.component';
import { LinkedLocationListComponent } from './linked-location-list/linked-location-list.component';
import { LinkedPaymentTransactionListComponent } from './linked-payment-transaction-list/linked-payment-transaction-list.component';
import { LinkedSurveyListComponent } from './linked-survey-list/linked-survey-list.component';
import { ExcludePipe } from './pipes/exclude.pipe';
import { FilterByArrayPipe } from './pipes/filter-by-array.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { LinebreaksPipe } from './pipes/line-break.pipe';
import { RruleTextPipe } from './pipes/rrule-text.pipe';
import { SortByPropPipe } from './pipes/sort-by-prop.pipe';
import { StatusColorPipe } from './pipes/status-color.pipe';
import { SideNavigationMenuComponent } from './side-navigation-menu/side-navigation-menu.component';
import { SideNavigationSubmenuComponent } from './side-navigation-submenu/side-navigation-submenu.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { TenantSelectorComponent } from './tenant-selector/tenant-selector.component';
import { UnitInputComponent } from './unit-input/unit-input.component';

@NgModule({
  declarations: [
    AttachmentComponent,
    AttachmentListComponent,
    AttachmentUploaderComponent,
    HeaderComponent,
    SideNavigationMenuComponent,
    SideNavigationSubmenuComponent,
    TagInputComponent,
    UnitInputComponent,
    ArbitraryItemSelectorComponent,
    ArbitraryLocationSelectorComponent,
    ArbitraryCustomerSelectorComponent,
    GenericModalComponent,
    FsTableComponent,
    ColumnSelectionModalComponent,
    FsStringFormatterPipe,
    TenantSelectorComponent,
    FsCascaderComponent,
    StatusColorPipe,
    RruleTextPipe,
    SortByPropPipe,
    FilterPipe,
    ExcludePipe,
    LinebreaksPipe,
    FilterByArrayPipe,
    DetailedAttachmentsComponent,
    FsSelectComponent,
    FsTimezoneSelectorComponent,
    HttpStatusSelectorComponent,
    FsTreeComponent,
    LinkedSurveyListComponent,
    LinkedJobListComponent,
    LinkedItemListComponent,
    LinkedLocationListComponent,
    LinkedCustomerListComponent,
    LinkedPaymentTransactionListComponent,
    FsCountdownComponent,
    FsJsonViewerModalComponent,

    FormBuilderComponent,
    FormSettingsComponent,
    QuestionComponent,
    SectionsSettingsModalComponent,
    QuestionSettingsComponent,
    EntitySelectionMockAnswerComponent,
    TimerDemoComponent,
    ItemSelectionSettingsComponent,
    LocationSelectionSettingsComponent,
    ApiCallComponent,
    ItemTypeConfigComponent,
    SectionsSettingsItemComponent,
    CustomFieldsComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    NgxFilesizeModule,
    NzButtonModule,
    NzCascaderModule,
    NzDividerModule,
    NzDropDownModule,
    NzIconModule,
    NzInputModule,
    NzGridModule,
    NzLayoutModule,
    NzSelectModule,
    NzMessageModule,
    NzModalModule,
    NzUploadModule,
    ReactiveFormsModule,
    NzTableModule,
    ClipboardModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzPopoverModule,
    NzCheckboxModule,
    NzSpinModule,
    NzFormModule,
    NzRadioModule,
    NzSkeletonModule,
    NzTabsModule,
    NzCollapseModule,
    NzDrawerModule,
    NzTreeModule,
    NzHighlightModule,
    NgxTimerModule,
    NgxJsonViewerModule,
    NzPageHeaderModule,
    NzTagModule,
    NzCardModule,
    DragDropModule,
    NzSwitchModule,
    NzTimePickerModule,
    NzTreeSelectModule
  ],
  exports: [
    ArbitraryCustomerSelectorComponent,
    ArbitraryItemSelectorComponent,
    ArbitraryLocationSelectorComponent,
    AttachmentComponent,
    AttachmentListComponent,
    AttachmentUploaderComponent,
    HeaderComponent,
    SideNavigationMenuComponent,
    SideNavigationSubmenuComponent,
    TagInputComponent,
    UnitInputComponent,
    FilterPipe,
    LinebreaksPipe,
    ExcludePipe,
    FsTableComponent,
    ColumnSelectionModalComponent,
    TenantSelectorComponent,
    FsCascaderComponent,
    StatusColorPipe,
    RruleTextPipe,
    SortByPropPipe,
    DetailedAttachmentsComponent,
    FsSelectComponent,
    FsTimezoneSelectorComponent,
    HttpStatusSelectorComponent,
    FsTreeComponent,
    FilterByArrayPipe,
    LinkedSurveyListComponent,
    LinkedJobListComponent,
    LinkedItemListComponent,
    LinkedLocationListComponent,
    LinkedCustomerListComponent,
    LinkedPaymentTransactionListComponent,
    FsJsonViewerModalComponent,
    FormBuilderComponent
  ]
})
export class SharedModule {
}
