import {HttpClient, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseCrudService} from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class LocationTypeHttpService extends BaseCrudService<ILocationType> {
  constructor(public http: HttpClient) {
    super(http, 'location-types');
  }

  public reorder(body: ReorderingPayload) {
    const url = `${this.url}/reorder`;

    return this.execute(new HttpRequest('PATCH', url, body));
  }
}
