<nz-layout class="wrapper">
  <app-side-navigation-submenu title="Settings">
    <app-settings-side-navigation-menu></app-settings-side-navigation-menu>
  </app-side-navigation-submenu>

  <div class="main-content">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</nz-layout>
