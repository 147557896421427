import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseCrudService} from '../../../../core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class WebhookEventHttpService extends BaseCrudService<IWebhookEvent> {
  constructor(public http: HttpClient) {
    super(http, 'webhook-events');
  }
}
