import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentHttpService extends BaseCrudService<IAttachment> {

  constructor(
    public http: HttpClient,
  ) {
    super(http, 'attachments');
  }

  public assembleCreationHTTPRequest(
    dto: IAttachmentCreationDto,
  ): Observable<HttpEvent<IAttachment>> {
    const formData = new FormData();
    for (const field in dto) {
      formData.append(field, dto[field]);
    }

    const httpRequest = new HttpRequest('POST', this.url, formData, {
      reportProgress: true,
    });

    return this.http.request(httpRequest);
  }

  public createAttachmentUsingSimpleRequest(dto: IAttachmentCreationDto): Observable<IAttachment> {
    const formData = new FormData();
    for (const field in dto) {
      formData.append(field, dto[field]);
    }
    return this
      .http
      .post<IAttachment>(this.url, formData);
  }

}
