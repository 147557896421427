import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent {

  @Input()
  public attachment: IAttachment;

  @Input()
  public deletable: boolean = false;

  @Input()
  public allowDeletion: boolean = false;

  @Output()
  public deleteAttachment: EventEmitter<IAttachment> = new EventEmitter<IAttachment>();

  public get attachmentIcon(): {isIcon: boolean; url: string} {
    const response = {
      isIcon: true,
      url: 'assets/attachment-types/'
    };

    if (this.attachment.mimeType.indexOf('image') > -1) {
      if (this.attachment.thumbnail) {
        return {
          isIcon: false,
          url: this.attachment.thumbnail
        };
      }

      if (this.attachment.url) {
        return {
          isIcon: false,
          url: this.attachment.url
        };
      }
    }

    switch (this.attachment.mimeType) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        response.url += 'icon-docx.svg';
        break;
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        response.url += 'icon-pptx.svg';
        break;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        response.url += 'icon-xlsx.svg';
        break;
      case 'application/pdf':
        response.url += 'icon-pdf.svg';
        break;
      case 'image/jpeg':
        response.url += 'icon-jpeg.svg';
        break;
      case 'image/png':
        response.url += 'icon-png.svg';
        break;
      default:
        response.url += 'icon-default.svg';
    }

    return response;
  }

  public constructor(
    private modalService: NzModalService,
  ) {}

  public confirmDeletion(): void {
    this.modalService.confirm({
      nzTitle: 'Delete attachment?',
      nzContent: `Are you sure you want to delete "${this.attachment.name}"?`,
      nzOkText: 'Yes',
      nzOnOk: () => {
        this.deleteAttachment.emit(this.attachment);
      },
      nzCancelText: 'No'
    });
  }

  public get previewUrl(): string {
    if (this.attachment.mimeType.indexOf('image') > -1) {
      if (!this.attachment.url) {
        return `${environment.fieldServicesAPI}/attachment-download/${this.attachment.tenantId}/${this.attachment.id}/preview`;
      } else {
        return this.attachment.url + '/preview';
      }
    } else {
      return `${environment.fieldServicesAPI}/attachment-download/${this.attachment.tenantId}/${this.attachment.id}`;
    }
  }
}
