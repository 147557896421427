<nz-form-control [nzErrorTip]="errorTpl">

  <nz-time-picker
    [id]="'time-picker-question-' + managedQuestion.question.id"
    class="fs-time-input"
    nzSize="large"
    [formControl]="answer"
    nzFormat="HH:mm:ss">
  </nz-time-picker>

  <ng-template #errorTpl>
    <div class="error form-control-msg" *ngIf="answer.hasError('required')">
      This question is required
    </div>
  </ng-template>

</nz-form-control>
