<div class="attachment-uploader">
  <nz-upload
    #uploader
    nzType="drag"
    [nzMultiple]="true"
    [nzCustomRequest]="antUploadRequest"
    [nzAccept]="allowedFileTypes"
    class="fs-attachment-input">

    <p class="ant-upload-drag-icon">
      <i nz-icon nzType="inbox"></i>
    </p>
    <p class="ant-upload-text large-screen">Click here or drag & drop to upload files</p>
    <p class="ant-upload-text mobile-screen">Tap here to upload files</p>

  </nz-upload>
</div>
