import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from 'src/app/core/http/base.service';

@Injectable({
  providedIn: 'root'
})
export class ItemHttpService extends BaseCrudService<IItem> {

  constructor(public http: HttpClient) {
    super(http, 'items');
  }

}
