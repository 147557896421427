<div class="header">
  <div class="logo">
    <a href="/">
      <img src="/assets/atajo-logo.svg" width="70"/>
    </a>
  </div>

  <button
    class="contact-support"
    nz-button
    nzSize="large"
    nzType="primary"
    onclick="location.href='mailto:support@atajo.io'">

    <i nz-icon nzType="mail"></i>

    Contact Support
  </button>

  <button
    class="contact-support"
    nz-button
    nzSize="large"
    nzType="default"
    (click)="handleLogOutClick()">

    <i nz-icon nzType="logout"></i>

    Logout
  </button>
</div>

<div class="content">
  <h1>Access Denied</h1>

  <p>{{forbiddenMessage}}</p>

  <p>support@atajo.io</p>
</div>
