import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {isNullOrUndefined} from '../helpers/truthy.helper';

@Injectable({
  providedIn: 'root'
})
export class TenantStateService {
  // Public properties
  public availableTenants: BehaviorSubject<ITenant[]> = new BehaviorSubject([]);


  // Private properties
  private _tenant: BehaviorSubject<ITenant> = new BehaviorSubject(null);
  private _shouldDisplayTenantSelector: BehaviorSubject<boolean> = new BehaviorSubject(true);


  // Getters
  public get tenantAsObservable(): Observable<ITenant> {
    return this._tenant.asObservable();
  }
  public get tenantValue(): ITenant {
    return this._tenant.getValue();
  }
  public get shouldDisplayTenantSelector(): Observable<boolean> {
    return this._shouldDisplayTenantSelector.asObservable();
  }


  // Public methods
  public setTenant(tenant: ITenant) {
    const previousTenant = this.tenantValue;

    this._tenant.next(tenant);
    this.setShouldDisplayTenantSelector(false);

    localStorage.setItem('user_tenant_id', tenant.id);

    if (!!previousTenant && previousTenant.id !== tenant.id) {
      window.location.href = `${environment.fieldServicesWebBaseUrl}/${window.location.pathname.split('/')[1]}`;
    }
  }

  public setAvailableTenants(tenants: ITenant[]) {
    if (tenants) {
      this.availableTenants.next(tenants);
    }
  }

  public setShouldDisplayTenantSelector(value: boolean) {
    if (!isNullOrUndefined(value)) {
      this._shouldDisplayTenantSelector.next(value);
    }
  }

  public displayTenantSelector(): void {
    this.setShouldDisplayTenantSelector(true);
  }

  public clearTenantData(): void {
    localStorage.removeItem('user_tenant_id');
    this._tenant.next(null);
    this.setAvailableTenants([]);
    setTimeout(() => {
      this.setShouldDisplayTenantSelector(true);
    }, 2500);
  }
}
