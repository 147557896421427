import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { get } from 'lodash';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ItemHttpService } from 'src/app/items/services/item-http.service';
import { ItemTypeHttpService } from 'src/app/items/services/item-type-http.service';
import {ItemCascaderService} from '../../../../items/services/item-cascader.service';

@Component({
  selector: 'app-item-selection',
  templateUrl: './item-selection.component.html',
  styleUrls: ['./item-selection.component.scss'],
  providers: [
    ItemCascaderService
  ]
})
export class ItemSelectionComponent implements OnInit, OnDestroy {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();

  public answerFormGroup: FormGroup;

  public isLoading: boolean = true;

  public itemOptions: NzTreeNodeOptions[];

  public subscriptions: Subscription[] = [];

  public constructor(
    private itemHttpService: ItemHttpService,
    private itemTypeHttpService: ItemTypeHttpService,
    private messageService: NzMessageService,
    private formBuilder: FormBuilder,
    public itemCascaderService: ItemCascaderService
  ) {
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public async ngOnInit(): Promise<void> {
    await this.itemCascaderService.loadItemTypeOptions();

    await this.initializeFormGroup();
    this.monitorFormControl();
    this.loadComponentOptions();
  }

  public setHierarchyForItem(option: NzCascaderOption) {
    this.itemCascaderService.setHierarchy(option)
      .then(selected => {
        this.answerFormGroup.get('answer').patchValue(selected);
      });
  }

  private async initializeFormGroup(): Promise<void> {
    this.answerFormGroup = this.formBuilder.group({
      answer: [null]
    });

    const control = this.answerFormGroup.get('answer');

    if (this.managedQuestion.question.configuration.isRequired) {
      control.setValidators([Validators.required]);
    }

    if (this.managedQuestion.answers && this.managedQuestion.answers.length) {
      // TODO: Chris - When implementing multiple select, extend this (Shouldn't reference index 0)
      const itemId = this.managedQuestion.answers[0].itemIdAnswer;

      if (!!itemId) {
        const itemPath = await this.itemCascaderService.getItemIdPathArray(itemId);
  
        // TODO: Chris - Build in multiple answer support later
        if (this.managedQuestion.question.configuration.allowMultipleAnswers) {
          control.setValue(this.managedQuestion.answers.map(answer => answer.itemIdAnswer));
        }
  
        // TODO: Chris - For now just override the value
        control.setValue(itemPath);
      }
    }
  }

  private monitorFormControl(): void {
    const control = this.answerFormGroup.get('answer');

    this.subscriptions.push(
      control
        .valueChanges
        .subscribe(answer => {
          if (Array.isArray(answer)) {
            const question = this.managedQuestion.question;

            this.managedQuestion.answers = answer.length
              ? answer.map(itemId => ({
                questionId: question.id,
                questionType: question.type,
                questionPublicId: question.publicId,
                sectionPublicId: question.section.publicId,
                sectionId: question.sectionId,
                itemIdAnswer: itemId,
              }))
              : [];
          } else {
            const question = this.managedQuestion.question;

            this.managedQuestion.answers = !!answer
              ? [{
                questionId: question.id,
                questionType: question.type,
                questionPublicId: question.publicId,
                sectionPublicId: question.section.publicId,
                sectionId: question.sectionId,
                itemIdAnswer: answer,
              }]
              : [];
          }

          this.valueChange.emit(answer);
        })
    );
  }

  private loadComponentOptions(): void {
    this.isLoading = true;
    let itemTypeWithCategories: IItemType[];
    this
      .loadItemTypesWithCategories()
      .pipe(
        tap(types => itemTypeWithCategories = types),
        switchMap(this.loadItems.bind(this))
      )
      .subscribe(
        (items) => {
          this.itemOptions = this.generateOptions(itemTypeWithCategories, items);
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.messageService.error('Unable to load item selection question data, please try again later');
          this.isLoading = false;
        },
      );
  }

  private loadItemTypesWithCategories(): Observable<IItemType[]> {
    const allowedItemTypeConfs: IItemSelectionItemTypeConfig[] = get(
      this.managedQuestion,
      'question.configuration.itemTypesAllowed',
      [],
    );
    const allowedItemTypeIds = allowedItemTypeConfs.map(conf => conf.itemTypeId).join(',');
    const queryParams = allowedItemTypeConfs.length ? {typeIds: allowedItemTypeIds} : null;
    return this
      .itemTypeHttpService
      .fetchAllCategoryHierarchies(queryParams);
  }

  private loadItems(itemTypesWithCategories: IItemType[]): Observable<IItem[]> {
    const categoryIdToChildrenIdMap = new Map();
    const mapCategories = (categories: IItemTypeCategory[], parentIdArray?: string[]) => {
      for (const category of categories) {
        const curCatArray = [category.id];
        if (category.children && category.children.length) {
          mapCategories(category.children, curCatArray);
        }
        categoryIdToChildrenIdMap.set(category.id, curCatArray);
        if (parentIdArray) {
          parentIdArray.push(...curCatArray);
        }
      }
    };
    itemTypesWithCategories
      .filter(type => type.children && !!type.children.length)
      .forEach(type => mapCategories(type.children));

    const allowedItemTypeConfs: IItemSelectionItemTypeConfig[] = get(
      this.managedQuestion,
      'question.configuration.itemTypesAllowed',
      [],
    );

    const categoryIds = [];
    const itemTypeIds = [];

    for (const conf of allowedItemTypeConfs) {
      if (conf.itemTypeCategoryIds && conf.itemTypeCategoryIds.length) {
        for (const categoryId of conf.itemTypeCategoryIds) {
          categoryIds.push(categoryId);
          if (categoryIdToChildrenIdMap.has(categoryId)) {
            categoryIds.push(...categoryIdToChildrenIdMap.get(categoryId));
          }
        }
      } else {
        itemTypeIds.push(conf.itemTypeId);
      }
    }

    const query: CreateQueryParams = {
      sort: [{
        field: 'name',
        order: 'ASC',
      }],
      join: [{
        field: 'tags',
      }]
    };
    if (categoryIds.length || itemTypeIds) {
      query.search = {
        $or: [],
      };
      if (itemTypeIds.length) {
        query.search.$or.push({
          itemTypeId: {
            $in: itemTypeIds
          }
        });
      }
      if (categoryIds.length) {
        query.search.$or.push({
          itemTypeCategoryId: {
            $in: categoryIds
          }
        });
      }
    }

    return this
      .itemHttpService
      .get(query)
      .pipe(
        map(resp => {
          const items = resp.data;
          const tagIds: string[] = get(
            this.managedQuestion,
            'question.configuration.itemSelectionTagIds',
            [],
          );
          return !!tagIds.length ? items.filter(item =>
            item.tags.some(tag => tagIds.includes(tag.id))
          ) : items;
        }),
      );
  }

  private generateOptions(itemTypesWithCategories: IItemType[], items: IItem[]): NzTreeNodeOptions[] {
    const categoryIdMap = new Map();
    const mapCategories = (categories: IItemTypeCategory[]) => {
      for (const category of categories) {
        categoryIdMap.set(category.id, category);
        if (category.children && category.children.length) {
          mapCategories(category.children);
        }
      }
    };
    itemTypesWithCategories
      .filter(type => type.children && !!type.children.length)
      .forEach(type => mapCategories(type.children));
    const itemTypeIdMap = new Map(itemTypesWithCategories.map(type => [type.id, type]));

    items.forEach(item => {
      if (item.itemTypeCategoryId) {
        const category = categoryIdMap.get(item.itemTypeCategoryId);
        if (!category.children) {
          category.children = [];
        }
        category.children.push(item);
      } else {
        const type = itemTypeIdMap.get(item.itemTypeId);
        if (!type.children) {
          type.children = [];
        }
        type.children.push(item);
      }
    });

    return this.convertEntitiesToOptions(itemTypesWithCategories);
  }

  private convertEntitiesToOptions(
    entities: any[],
  ): NzTreeNodeOptions[] {
    return entities.map(entity => ({
      key: entity.id,
      title: entity.name,
      ...(!!entity.children && !!entity.children.length ? {
        children: this.convertEntitiesToOptions(entity.children)
      } : {
        isLeaf: true
      }),
      disabled: entity.children || !entity.hasOwnProperty('itemTypeCategoryId'),
    }));
  }

}
