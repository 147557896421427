<nz-modal
  nzClassName="service-modal"
  nzWidth="600px"
  [(nzVisible)]="isVisible"
  nzTitle="Survey Password"
  [nzFooter]="modalFooter"
  [nzClosable]="false">

  <ng-container *nzModalContent>
    <form class="popup-form" nz-form>
      <nz-form-item>
        <nz-form-label [nzXs]="10" nzNoColon>
          <div>Password</div>
          <div class="hint">
            Leave empty if not required
          </div>
        </nz-form-label>
        <nz-form-control>
          <input nz-input type="password" nzSize="large" [formControl]="passwordFormControl" autofocus />
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>

  <ng-template #modalFooter>
    <button
      nz-button
      nzSize="large"
      nzType="primary"
      [nzLoading]="isAuthenticating"
      (click)="handleAuthentication.emit(passwordFormControl.value)">Open</button>
  </ng-template>
</nz-modal>
