import {Injectable} from '@angular/core';
import {CondOperator, CreateQueryParams} from '@nestjsx/crud-request';
import {QueryFilter} from '@nestjsx/crud-request/lib/types';
import {ComparisonOperator} from '@nestjsx/crud-request/lib/types/request-query.types';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import {map, tap} from 'rxjs/operators';
import {FsCascaderAbstractService} from '../../shared/fs-cascader/fs-cascader-abstract.service';
import {CascadeLoadDataRequest} from '../../shared/fs-cascader/fs-cascader.component';
import {ItemHttpService} from './item-http.service';
import {ItemTypeHttpService} from './item-type-http.service';

@Injectable()
export class ItemCascaderService extends FsCascaderAbstractService<IItemType, IItem> {
  // key = itemTypeId, value = itemCategoryIds
  protected itemCategoriesFilters: Map<string, string[]> = new Map<string, string[]>();

  constructor(
    private itemTypeHttpService: ItemTypeHttpService,
    private itemHttpService: ItemHttpService,
  ) {
    super('itemTypeId');
  }

  public addItemCategoriesFilter(itemTypeId: string, itemCategoriesIds: string[]) {
    this.itemCategoriesFilters.set(itemTypeId, itemCategoriesIds);
  }

  public removeItemCategoriesFilter(itemTypeId: string) {
    this.itemCategoriesFilters.delete(itemTypeId);
  }

  public loadItemTypeOptions(): Promise<NzCascaderOption[]> {
    const query: CreateQueryParams = {
      filter: [{
        field: 'isActive',
        operator: CondOperator.EQUALS,
        value: true,
      }],
      sort: {field: 'name', order: 'ASC'},
    };
    const extraParams: ISimpleParamWrapper = {
      injectStatistics: 'true'
    };

    if (this.entityTypeFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityTypeFilters
      ];
    }

    this.isLoading.next(true);

    return this.itemTypeHttpService
      .get(query, extraParams)
      .pipe(
        map((response: IPagination<IItemType>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          // Set the location type options
          this.replaceLocationTypeOptions(options);

          // Backup options for reverting after search
          this.backupLocationTypeOptions();

          this.isLoading.next(false);
        }))
      .toPromise();
  }

  public loadItemsForItemTypeOption(request: CascadeLoadDataRequest): Promise<NzCascaderOption[]> {
    const option = request.option;
    const query: CreateQueryParams = {
      filter: [
        {
          field: 'itemTypeId',
          operator: CondOperator.EQUALS,
          value: option.value,
        }, {
          field: 'isActive',
          operator: CondOperator.EQUALS,
          value: true,
        }
      ],

      sort: {field: 'name', order: 'ASC'},
      join: [
        {field: 'tags'}
      ]
    };

    if (!option || !option.value) {
      if (option.loading) {
        requestAnimationFrame(() => {
          option.loading = false;
          request.callback();
        });
      }

      return;
    }

    if (this.itemCategoriesFilters.size > 0) {
      const matchingCategoriesFilter = this.itemCategoriesFilters.get(option.value);

      if (matchingCategoriesFilter && matchingCategoriesFilter.length) {
        query.filter = [
          ...(query.filter as QueryFilter[]),
          ...[{
            field: 'itemTypeCategoryId',
            operator: '$in' as ComparisonOperator,
            value: matchingCategoriesFilter
          }]
        ];
      }
    }

    if (this.entityFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityFilters
      ];
    }

    return this.itemHttpService
      .get(query)
      .pipe(
        map((response: IPagination<IItem>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          option.children = options;

          if (!options.length) {
            option.disabled = true;
          } else if (option.disabled) {
            option.disabled = false;
          }

          if (request.callback) {
            request.callback(options);
          }
        }))
      .toPromise();
  }

  public searchItemTypeOptions(search: string): Promise<NzCascaderOption[]> {
    // Private flag _showingSearchResults used to prevent item types trees from being overwritten unnecessarily
    if (!search && this._showingSearchResults.getValue()) {
      this.restoreRootOptionsBackup();

      this.isLoading.next(false);
      this._showingSearchResults.next(false);

      return;
    }

    const query: CreateQueryParams = {
      filter: [{
        field: 'name',
        operator: CondOperator.CONTAINS_LOW,
        value: search
      }, {
        field: 'isActive',
        operator: CondOperator.EQUALS,
        value: true,
      }],
      sort: {
        field: 'name',
        order: 'ASC'
      },
      join: [
        {field: 'tags'}
      ]
    };

    this._showingSearchResults.next(true);

    if (this.entityTypeFilters.length) {
      const entityTypeIdsFilter = this.entityTypeFilters.map(f => f.value);
      const fixedIdsFilterArray = Array.isArray(entityTypeIdsFilter[0])
        ? entityTypeIdsFilter[0]
        : entityTypeIdsFilter;
      const itemCategoryIds = [];

      this.itemCategoriesFilters.forEach(((ids, key) => {
        ids.forEach(id => {
          if (itemCategoryIds.indexOf(id) < 0) {
            itemCategoryIds.push(id);
          }
        });
      }));

      if (itemCategoryIds.length) {
        query.filter = [
          ...(query.filter as QueryFilter[]),
          ...[{
            field: 'itemTypeCategoryId',
            operator: '$in' as ComparisonOperator,
            value: itemCategoryIds
          }]
        ];
      }

      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...[{
          field: this._parentIdSelector,
          operator: '$in' as ComparisonOperator,
          value: fixedIdsFilterArray
        }]
      ];
    }

    if (this.entityFilters.length) {
      query.filter = [
        ...(query.filter as QueryFilter[]),
        ...this.entityFilters
      ];
    }

    return this.itemHttpService
      .get(query)
      .pipe(
        map((response: IPagination<IItem>) => {
          if (response) {
            return this.convertHierarchicalModelArrayToCascaderOptions(response.data);
          }
        }),
        tap((options: NzCascaderOption[]) => {
          this.isLoading.next(false);

          // Replace location type options with the search results (temporary)
          this.replaceLocationTypeOptions(options);
        }))
      .toPromise();
  }

  public async getItemIdPathArray(itemId: string) {
    const item = await this.itemHttpService
      .getOne(itemId)
      .toPromise();

    return [item.itemTypeId, item.id];
  }
}
