import {EventEmitter, Injectable} from '@angular/core';
import {cloneDeep} from 'lodash';
import {AbstractStateService} from '../../../../core/state-management/abstract-state.service';
import {FsPaging} from '../../../fs-table/fs.paging';
import {IItemFieldsState, IItemSelectionConfig, IItemUnitConfig, IPagedSearchDto, StatusType} from './item-fields-state.interface';

@Injectable({providedIn: 'root'})
export class ItemFieldsStateService extends AbstractStateService<IItemFieldsState> {
  public loadItems: EventEmitter<IPagedSearchDto> = new EventEmitter<IPagedSearchDto>();

  public get searchTypeValidationValue(): boolean | null {
    const searchType = this.state.status;
    let value; // The initial state is undefined - So this should not be initialized

    if (searchType === StatusType.partiallyComplete) {
      value = false;
    }

    if (searchType === StatusType.selected) {
      value = true;
    }

    return value;
  }

  public get availableUnits(): ISelectOption[] {
    const itemUnits = this.state.selectedItemSelectionConfig?.itemUnits ?? [];
    const consumedUnitIds = itemUnits
      .map(iu => iu.unitId ?? null)
      .filter(u => !!u);

    return this.state.unitOptions.map(u => {
      u.isDisabled = consumedUnitIds.indexOf(u.id) > -1;

      return u;
    });
  }

  constructor() {
    super();
  }

  public initializeState(): IItemFieldsState {
    return {
      statuses: [
        {id: 'all', label: 'All items'},
        {id: 'selected', label: 'Selected'},
        {id: 'partiallyComplete', label: 'Partially complete'},
        {id: 'incomplete', label: 'Incomplete'}
      ],
      items: [],
      filteredItems: [],
      unitOptions: [],
      questionsBlueprint: [],
      itemSelectionConfigs: [],
      selectedItemSelectionConfig: null,
      itemsPaging: new FsPaging(true),
      status: StatusType.all,
      isLoadingItems: true,
      isLoadingItemUnits: false,
      selectedItem: null,
      selectedItemUnit: null,
      itemsTotal: 0,
      itemSubtotal: 0,
      itemFieldsConfig: {
        showLineItemSubtotal: true,
        showTotal: true,
        showCostInfo: true,
        showPriceInfo: true,
        showAvailableInfo: true
      }
    };
  }

  public setSelectedItemUnitWithQuestions(itemUnitConfig: IItemUnitConfig, itemUnit: IItemUnit): IItemSelectionConfig {
    const itemSelectionConfig = this.state.selectedItemSelectionConfig;

    if (itemSelectionConfig) {
      let unitSelectionConfig = itemSelectionConfig.itemUnits
        .find(uq => uq.itemUnit.id === itemUnit.id);

      if (!unitSelectionConfig) {
        unitSelectionConfig = {
          formVersionAnswerSheetId: itemUnitConfig.formVersionAnswerSheetId,
          unitId: itemUnit.id,
          itemUnit,
          questions: [],
          subtotal: 0
        };

        itemSelectionConfig.itemUnits.push(unitSelectionConfig);
      }

      if (!unitSelectionConfig.questions.length && this.state.questionsBlueprint.length) {
        unitSelectionConfig.questions = cloneDeep(this.state.questionsBlueprint);
        unitSelectionConfig.questions.forEach(q => {
          if (q.answers?.length) {
            q.answers = [];
          }
        });
      }

      this.updateState({selectedItemSelectionConfig: itemSelectionConfig});

      return itemSelectionConfig;
    }
  }

  public clearSelectedUnitQuestions(): void {
    this.updateState({selectedItemSelectionConfig: null});
  }

  public toggleIsLoadingItems(state: boolean) {
    this.updateState({isLoadingItems: state});
  }

  public setItems(items: IItem[] = []) {
    this.updateState({items, filteredItems: items});

    if (items.length) {
      requestAnimationFrame(() => {
        this.setSelectedItem(items[0]);
        this.checkIfDetailsShouldDisplay(this.state.status);
      });
    }
  }

  public setSelectedLocation(location: ILocation) {
    this.updateState({location});
  }

  public setSelectedItem(item: IItem): void {
    if (item) {
      this.state.unitOptions = [];

      const units = this.state.unitOptions;

      item.itemUnits.forEach(iu => {
        const unit = iu.unit;
        const unitIndex = units.findIndex(u => u.id === unit.id);

        if (unitIndex < 0) {
          units.push({
            id: unit.id,
            label: unit.label,
            data: unit
          });
        }
      });

      this.updateState({
        selectedItem: item
      });

      this.setItemSelectionConfig(item);
    }
  }

  public clearSelectedItem(): void {
    this.updateState({
      selectedItem: null
    });
  }

  public checkIfDetailsShouldDisplay(searchType: string) {
    let filteredItems = this.state.items;

    if (searchType !== 'all') {
      filteredItems = filteredItems.filter((item: IItem) => {
        const itemValidity = this.getItemValidity(item);

        return itemValidity === this.searchTypeValidationValue;
      });
    }

    this.updateState({filteredItems});

    filteredItems = this.state.filteredItems; // Assign local variable reference instead of constant dot notation referencing below

    if (this.state.selectedItemSelectionConfig?.item?.id) {
      const matchingItem = filteredItems.find(i => i.id === this.state.selectedItemSelectionConfig.item.id) ?? null;

      if (matchingItem) {
        this.setSelectedItem(matchingItem);

        return;
      }
    }

    if (filteredItems.length) {
      this.setSelectedItem(filteredItems[0]);
    } else if (!filteredItems.length) {
      const matchingItem = filteredItems.find(i => i.id === this.state.selectedItem?.id) ?? null;

      if (!matchingItem) {
        this.clearSelectedItem();
      }
    }
  }

  public getItemValidity(item: IItem) {
    const matchingItemSelectionConfig = this.state.itemSelectionConfigs.find(isc => isc.item?.id === item.id);

    return matchingItemSelectionConfig?.valid ?? undefined;
  }

  public addNewItemUnitConfig() {
    // Add an empty Item Unit for selection
    this.state.selectedItemSelectionConfig.itemUnits.push({
      formVersionAnswerSheetId: '',
      unitId: null,
      itemUnit: null,
      questions: [],
      subtotal: 0
    });
  }

  public removeItemUnitConfig(itemUnitConfig: IItemUnitConfig) {
    const existingIndex = this.state.selectedItemSelectionConfig
      .itemUnits
      .findIndex(iu => iu.unitId === itemUnitConfig.unitId);

    if (existingIndex > -1) {
      this.state.selectedItemSelectionConfig
        .itemUnits
        .splice(existingIndex, 1);
    }
  }

  private setItemSelectionConfig(item: IItem) {
    const location = this.state.location ?? null;
    let itemSelectionConfig = this.state.itemSelectionConfigs.find(isc => isc.item?.id === item.id);

    if (!itemSelectionConfig) {
      itemSelectionConfig = {
        item,
        location,
        itemUnits: [{
          formVersionAnswerSheetId: '',
          unitId: null,
          itemUnit: null,
          questions: [],
          subtotal: 0
        }]
      };

      this.state.itemSelectionConfigs.push(itemSelectionConfig);
    }

    this.updateState({selectedItemSelectionConfig: itemSelectionConfig});
  }
}
