import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Observable, Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<GeolocationPosition> = new EventEmitter<GeolocationPosition>();

  public answer: FormControl;

  counterSubscription: Subscription;
  counterObservable$: Observable<number> = timer(0, 1000);
  timerStart = false;
  timerData = {
    start: '',
    end: '',
    count: 0,
  };
  globalTimeData = {
    start: '',
    end: '',
    count: 0,
  };
  listOfData: any[] = [];

  public constructor(private messageService: NzMessageService) {
  }

  public ngOnInit(): void {
    this.answer = new FormControl(
      !!this.managedQuestion.answers.length
        ? this.managedQuestion.answers[0].timerAnswer
        : {},
      this.managedQuestion.question.configuration.isRequired
        ? [Validators.required]
        : []
    );
  }


  startStop(): void {
    this.timerStart = !this.timerStart;

    const answers = this.managedQuestion.answers;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (this.timerStart) {
          const question = this.managedQuestion.question;

          this.timerData.start = this.dateFormatter(new Date());

          answers.push({
            questionId: question.id,
            questionType: question.type,
            questionPublicId: question.publicId,
            sectionPublicId: question.section.publicId,
            sectionId: question.sectionId,
            timerAnswer: {startDate: new Date(), latitudeAtStart: position.coords.latitude, longitudeAtStart: position.coords.longitude}
          });

          this.startCounter();
        } else {
          this.timerData.end = this.dateFormatter(new Date());
          answers[answers.length - 1].timerAnswer.stopDate = new Date();
          answers[answers.length - 1].timerAnswer.latitudeAtStop = position.coords.latitude;
          answers[answers.length - 1].timerAnswer.longitudeAtStop = position.coords.longitude;
          this.stopCounter();
          this.pushEntryToList();
          this.answer.setValue(this.listOfData, {emitEvent: true});
        }
        this.managedQuestion.answers = answers;

        this.valueChange.emit(position);

        this.getFirstAndLastValues();
      },

      (error) => {
        this.answer.setValue(null);
        this.managedQuestion.answers = [];

        switch (error.code) {
          case error.PERMISSION_DENIED: {
            this.messageService.error('Please enable location access');
            break;
          }
          default: {
            this.messageService.error(
              'The application is not able to access your location, please try a different browser'
            );
            break;
          }
        }
      }
    );
  }

  dateFormatter(date): string {
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };
    const dateOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const timeString = date.toLocaleTimeString('en-US', timeOptions);
    const dateString = date.toLocaleDateString('en-US', dateOptions);
    return `${timeString}, ${dateString}`;
  }

  timeFormatter(data) {
    const hours = Math.floor(data / 3600);
    const minutes = Math.floor(data / 60) - hours * 60;
    const seconds = data - minutes * 60;
    const hourString = hours.toString().padStart(2, '0');
    const minuteString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');

    return `${hourString}:${minuteString}:${secondsString}`;
  }

  pushEntryToList() {
    this.listOfData.push(Object.assign(this.timerData, {}));
    this.resetTimerData();
  }

  resetTimerData() {
    this.timerData = {
      start: '',
      end: '',
      count: 0,
    };
  }

  getFirstAndLastValues() {
    const length = this.listOfData.length;
    if (length > 0) {
      this.globalTimeData.start = this.listOfData[0].start;
      this.globalTimeData.end = this.listOfData[length - 1].end;
      this.globalTimeData.count += this.listOfData[length - 1].count;
    }
  }

  stopCounter() {
    this.counterSubscription.unsubscribe();
  }

  startCounter() {
    this.counterSubscription = this.counterObservable$.subscribe((res) => {
      this.timerData.count = res;
    });
  }
}
