import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss']
})
export class MultipleChoiceComponent implements OnInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public radioAndSelectFormControl: FormControl;

  public checkboxFormArray: FormArray;

  public constructor() {
  }

  public get checkboxFormControls(): FormControl[] {
    return this.checkboxFormArray?.controls as FormControl[] ?? [];
  }

  public get shouldWrapOptions(): boolean {
    return this.managedQuestion.question.options.every(
      (option) => option.title.length < 9
    );
  }

  ngOnInit(): void {
    const isCheckboxQuestion =
      this.managedQuestion.question.configuration.allowMultipleAnswers
      && this.managedQuestion.question.configuration.displayMultipleChoiceOptionsAs === 'buttons';

    if (isCheckboxQuestion) {
      this.initCheckboxFormArray();
    } else {
      this.initRadioAndSelectFormControl();
    }
  }

  private initCheckboxFormArray(): void {
    const preSelectedOptions = this.managedQuestion.answers.map(
      (answer) => answer.questionOptionIdAnswer,
    );

    this.checkboxFormArray = new FormArray(
      this.managedQuestion.question.options.map(
        (option) => new FormControl(preSelectedOptions.includes(option.id)),
      ),
      this.managedQuestion.question.configuration.isRequired ? [Validators.required] : [],
    );

    this
      .checkboxFormArray
      .valueChanges
      .subscribe((checkboxAnswers) => {
        const optionsSelectedAnswer = checkboxAnswers.reduce((questionAnswers, checkboxAnswer, index) => {
          if (!!checkboxAnswer) {
            const question = this.managedQuestion.question;

            questionAnswers.push({
              questionId: question.id,
              questionType: question.type,
              questionPublicId: question.publicId,
              sectionPublicId: question.section.publicId,
              sectionId: question.sectionId,
              questionOptionIdAnswer: question.options[index].id,
            });
          }

          return questionAnswers;
        }, []);

        this.managedQuestion.answers = optionsSelectedAnswer;

        this.valueChange.emit(optionsSelectedAnswer);
      });
  }

  private initRadioAndSelectFormControl(): void {
    const initialValue =
      this.managedQuestion.question.configuration.allowMultipleAnswers ?
        this.managedQuestion.answers.map((answer) => answer.questionOptionIdAnswer)
        : !!this.managedQuestion.answers.length ? this.managedQuestion.answers[0].questionOptionIdAnswer
        : undefined;

    this.radioAndSelectFormControl = new FormControl(
      initialValue,
      this.managedQuestion.question.configuration.isRequired ? [Validators.required] : [],
    );

    this
      .radioAndSelectFormControl
      .valueChanges
      .subscribe((answer) => {
        if (Array.isArray(answer)) {
          const question = this.managedQuestion.question;

          this.managedQuestion.answers = answer.map((questionOptionId) => ({
            questionId: question.id,
            questionType: question.type,
            questionPublicId: question.publicId,
            sectionPublicId: question.section.publicId,
            sectionId: question.sectionId,
            questionOptionIdAnswer: questionOptionId,
          }));
        } else {
          const question = this.managedQuestion.question;

          this.managedQuestion.answers = [{
            questionId: question.id,
            questionType: question.type,
            questionPublicId: question.publicId,
            sectionPublicId: question.section.publicId,
            sectionId: question.sectionId,
            questionOptionIdAnswer: answer,
          }];
        }
      });
  }

}
