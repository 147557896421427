import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import {fromDurationObject} from './shared/duration-converter';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss']
})
export class DurationComponent implements OnInit, AfterViewInit {

  @Input()
  public managedQuestion: IStateManagedQuestion;

  @Output()
  public valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public answer: FormControl;

  public constructor() {
  }

  public ngOnInit(): void {
    const hasInitialAnswer =
      !!this.managedQuestion.answers.length
      && this.managedQuestion.answers[0].durationAnswer;

    let initialAnswer;
    if (hasInitialAnswer) {
      const answer = this.managedQuestion.answers[0].durationAnswer;
      const initialAnswerMoment = moment(answer, 'HH:mm:ss');
      initialAnswer = initialAnswerMoment.toDate();
    }

    this.answer = new FormControl(
      initialAnswer,
      this.managedQuestion.question.configuration.isRequired ? [Validators.required] : [],
    );

    this
      .answer
      .valueChanges
      .subscribe((answer) => {
        const answerArray = [];
        if (!!answer) {
          const momentAnswer = moment(answer as Date);
          const duration = {
            hours: momentAnswer.hours(),
            minutes: momentAnswer.minutes(),
            seconds: momentAnswer.seconds(),
          };
          answerArray.push({
            questionId: this.managedQuestion.question.id,
            formVersionAnswerSheetId: this.managedQuestion.formVersionAnswerSheetId,
            durationAnswer: fromDurationObject(duration),
          });
        }

        this.managedQuestion.answers = answerArray;

        this.valueChange.emit(answer);
      });
  }

  public ngAfterViewInit() {
    this.toggleTimePickerReadOnly();
  }

  private toggleTimePickerReadOnly() {
    // Ant's current time picker component does not yet support nzInputReadOnly like
    // its date picker. We want to avoid users writting time values, so we have no recourse
    // but to add the readonly attribute to the DOM element by hand
    // TODO remove this hack once Ant's time picker support setting nzInputReadOnly
    const timePickerId = `time-picker-question-${this.managedQuestion.question.id}`;
    const querySelectorString = `#${timePickerId} input`;
    const input = document.querySelector(querySelectorString);
    if (!!input) {
      // @ts-ignore
      input.readOnly = true;
    } else {
      setTimeout(this.toggleTimePickerReadOnly.bind(this), 100);
    }
  }

}
