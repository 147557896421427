import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import {ItemCascaderService} from '../../items/services/item-cascader.service';
import {FsCascaderComponent} from '../../shared/fs-cascader/fs-cascader.component';

@Component({
  selector: 'app-item-hard-link-selector',
  templateUrl: './item-hard-link-selector.component.html',
  styleUrls: ['./item-hard-link-selector.component.scss'],
  providers: [
    ItemCascaderService
  ]
})
export class ItemHardLinkSelectorComponent implements OnInit {
  // Input Params
  @Input()
  public configuration: IItemHardLinkConfiguration;

  @Input()
  public initialValues?: string[];

  // Output Events
  @Output()
  public itemsSelected: EventEmitter<string[]> = new EventEmitter();

  // View Children
  @ViewChild(FsCascaderComponent) cascader: FsCascaderComponent;

  get isValid(): boolean {
    return !this.cascader?.hasRequiredError
    && this.selectedItem
    && Array.isArray(this.selectedItem)
      ? !this.selectedItem[0].isLeaf
      : !(this.selectedItem as NzCascaderOption).isLeaf;
  }

  // Public Properties
  public selectedItem: NzCascaderOption | Array<NzCascaderOption>;

  public constructor(public itemCascaderService: ItemCascaderService) {
  }

  public async ngOnInit(): Promise<void> {
    if (this.configuration.itemTypesAllowed?.length) {
      this.itemCascaderService.addEntityTypeFilter({
        field: 'id',
        operator: '$in',
        value: this.configuration.itemTypesAllowed.map(ita => {
          if (ita.itemTypeCategoryIds.length) {
            this.itemCascaderService.addItemCategoriesFilter(ita.itemTypeId, ita.itemTypeCategoryIds);
          }

          return ita.itemTypeId;
        })
      });
    }

    if (this.configuration.itemSelectionTagIds?.length) {
      this.itemCascaderService.addEntityFilter({
        field: 'tags.id',
        operator: '$in',
        value: this.configuration.itemSelectionTagIds
      });
    }

    await this.itemCascaderService.loadItemTypeOptions();

    if (this.initialValues) {
      this.selectedItem = [...this.initialValues];
    }
  }

  // Public Methods
  public handleItemUpdate(item: NzCascaderOption | string | Array<NzCascaderOption | string>): void {
    if (Array.isArray(item)) {
      this.itemsSelected.emit(item.map(i => {
        if (typeof i === 'string') {
          return i;
        } else {
          return i.value;
        }
      }));
    } else {
      this.itemsSelected.emit([typeof item === 'string' ? item : item.value]);
    }
  }

  public setHierarchyForItem(option: NzCascaderOption) {
    this.itemCascaderService.setHierarchy(option)
      .then(selected => {
        this.selectedItem = selected;

        this.handleItemUpdate(option);
      });
  }
}
