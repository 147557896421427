<nz-spin [nzSpinning]="isLoading">
  <div class="content">
    <div *ngIf="hasAnswer" class="cover-signature">
      Click "Clear" button to update the signature
    </div>

    <canvas
      class="signature-pad-canvas fs-signature-input"
      [ngStyle]="{'max-width': canvasWidth}"
      [width]="canvasWidth"
      height="175px"
      #signaturePadHost>
    </canvas>

    <div class="controls">
      <button nz-button nzType="primary" (click)="clearSignatureCanvas()">
        <i nz-icon nzType="close"></i>
        Clear
      </button>

      <button nz-button nzType="primary" (click)="undoLastLine()">
        <i nz-icon nzType="undo"></i>
        Undo
      </button>

      <button
        *ngIf="this.displayRetryUploadButton"
        nz-button
        nzType="primary"
        (click)="updateSignatureAttachmentAndEmitAnswer()">
        <i nz-icon nzType="upload"></i>
        Retry Saving
      </button>
    </div>

    <div class="error form-control-msg" *ngIf="displayQuestionRequiredError">
      This question is required
    </div>
  </div>
</nz-spin>
