export function fromDurationObject(duration: IDurationObject): string {
    const { hours, minutes, seconds } = duration;
    return `${hours ?? '00'}:${minutes ?? '00'}:${seconds ?? '00'}`;
}

export function toDurationObject(duration: any): IDurationObject {
    if (typeof duration === 'string') {
        const durationSplit = duration.split(':');
        return {
            hours: +(durationSplit[0] ?? '00'),
            minutes: +(durationSplit[1] ?? '00'),
            seconds: +(durationSplit[2] ?? '00'),
        };
    }

    return {
        hours: duration.hours ?? 0,
        minutes: duration.minutes ?? 0,
        seconds: duration.seconds ?? 0
    };
}
