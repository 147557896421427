import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {AppStateService} from '../../app-state.service';
import {WebhookEventHttpService} from './webhooks/services/webhook-event-http.service';
import {WebhookStateService} from './webhooks/services/webhook-state.service';

@Injectable({providedIn: 'root'})
export class OrganizationSettingsResolver implements Resolve<IWebhookEvent[]> {
  constructor(
    private appStateService: AppStateService,
    private webhookEventHttpService: WebhookEventHttpService,
    private webhookStateService: WebhookStateService
  ) {
  }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IWebhookEvent[]> {
    this.appStateService.togglePageLoader(true);

    return this.getAllWebhookEvents();
  }

  private getAllWebhookEvents(): Observable<IWebhookEvent[]> {
    return this.webhookEventHttpService.get()
      .pipe(
        map((response: IPagination<IWebhookEvent>) => response.data ?? []),
        tap((webhookEvents: IWebhookEvent[]) => {
          this.webhookStateService.setWebhookEvents(webhookEvents);
        }),
        finalize(() => this.appStateService.togglePageLoader(false)),
        catchError((error) => {
          throw error;
        })
      );
  }
}
