import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStateService {
  private _forbiddenState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _unauthorizedState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public get unauthorizedState(): BehaviorSubject<boolean> {
    return this._unauthorizedState;
  }

  public get forbiddenState(): BehaviorSubject<boolean> {
    return this._forbiddenState;
  }

  public updateState(state: AuthenticationState) {
    this._unauthorizedState.next(state && state.unauthorizedState);
    this._forbiddenState.next(state && state.forbiddenState);
  }
}

interface AuthenticationState {
  forbiddenState?: boolean;
  unauthorizedState?: boolean;
}
