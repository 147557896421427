<div *ngIf="showLoading || showPasswordModal" class="spin">
  <nz-spin nzSize="large"></nz-spin>
</div>

<div *ngIf="showNotPublished" class="survey-not-published">
  <nz-result nzStatus="404" nzTitle="No Survey" nzSubTitle="This survey is not yet published.">
  </nz-result>
</div>

<div *ngIf="!showLoading && survey && !surveySubmitted" class="wrapper">
  <header #header class="header">
    <span class="survey-name">{{ survey.name | uppercase }}</span>
    <button
      *ngIf="showSubmitBtn"
      (click)="handleSaveSurveyAnswer()"
      class="button-lg"
      nz-button
      nzType="primary"
      [disabled]="!isValidSurvey"
      [nzLoading]="isSavingSurvey"
      nzSize="large">
      <i nz-icon nzType="rocket"></i>
      Submit
    </button>
  </header>

  <div class="survey" [ngClass]="{'stick-tabs': stickTabs}">
    <div class="hard-links" *ngIf="allowHardLinks">
      <div class="label">Linked Entities</div>

      <app-location-hard-link-selector
        *ngIf="allowLocationHardLink"
        [configuration]="survey.hardLinkConfiguration.locations"
        [initialValues]="locationsSelected"
        (locationsSelected)="locationsSelected = $event; updateSurveyAnswerCache()">
      </app-location-hard-link-selector>

      <app-item-hard-link-selector
        *ngIf="allowItemHardLink"
        [configuration]="survey.hardLinkConfiguration.items"
        [initialValues]="itemsSelected"
        (itemsSelected)="itemsSelected = $event; updateSurveyAnswerCache()">
      </app-item-hard-link-selector>

      <app-customer-hard-link-selector
        *ngIf="allowCustomerHardLink"
        [configuration]="survey.hardLinkConfiguration.customers"
        [initialValues]="customersSelected"
        (customersSelected)="customersSelected = $event; updateSurveyAnswerCache()">
      </app-customer-hard-link-selector>
    </div>

    <app-answer-sheet
      class="answer-sheet creation-form"
      [formVersionAnswerSheet]="answerSheet"
      [formVersion]="survey.form.currentPublishedFormVersion"
      (answerSheetProgressUpdated)="this.isValidQuestionnaire = $event.completion === 1"
      (answerSheetUpdated)="this.answerSheet = $event; updateSurveyAnswerCache()"
      (tabsetCreated)="assignTabsetElementRef()"
      (sectionChanged)="setScrollTopToTabset()">
    </app-answer-sheet>
  </div>
</div>

<div *ngIf="surveySubmitted" class="survey-submitted">
  <nz-result
    nzStatus="success"
    nzTitle="Survey Submitted"
    nzSubTitle="You may close this window."
  ></nz-result>
</div>

<app-password-modal
  [isVisible]="showPasswordModal"
  [isAuthenticating]="isAuthenticating"
  (handleAuthentication)="performAuthAndFetchSurvey($event)">
</app-password-modal>
