<nz-form-control [nzErrorTip]="errorTpl">

  <nz-input-number
    class="input fs-number-input"
    [nzSize]="'large'"
    [formControl]="answer">
  </nz-input-number>

  <ng-template #errorTpl>
    <div class="error form-control-msg" *ngIf="answer.hasError('required')">
      This question is required
    </div>

    <div class="error form-control-msg" *ngIf="answer.hasError('min')">
      The lowest value allowed is {{ this.min }}
    </div>

    <div class="error form-control-msg" *ngIf="answer.hasError('max')">
      The highest value allowed is {{ this.max }}
    </div>

  </ng-template>

</nz-form-control>
