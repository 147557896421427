import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CreateQueryParams} from '@nestjsx/crud-request';
import {QueryFilter} from '@nestjsx/crud-request/lib/types';
import {BaseCrudService} from '../../core/http/base.service';
import {sortByValue} from '../helpers/list.helper';
import {LocalStorageHelper} from '../helpers/local-storage.helper';
import {IFsTableColumnConfig, IFsTableConfig} from './fs-table.config.interface';
import {IFsPaging} from './fs.paging';
import {IFsTableConfigDto} from './table-config.dto';

@Injectable({
  providedIn: 'root'
})
export class TableConfigService extends BaseCrudService<IFsTableConfigDto> {
  public constructor(public http: HttpClient) {
    super(http, 'table-configs');
  }

  /***
   * Fetches the table columns for a given entity type (e.g. 'job', 'survey', 'customer', etc.).
   *
   * This fetches the data from the table_config table, and it is tightly coupled to the @nestjs/crud-request library.
   *
   * @param entity - The domain entity to load the column configuration for
   * @param queryParams - The @nesjs/crud-request query parameters used to enrich the SQL query
   */
  public getTableColumnsForEntity(entity: string, queryParams?: CreateQueryParams) {
    let query: CreateQueryParams = queryParams;

    // If there is no query passed in then specify a base query
    if (!query) {
      query = {
        filter: {
          field: 'entity',
          operator: '$eq',
          value: entity
        },
        sort: {
          field: 'order',
          order: 'ASC'
        }
      };
    } else {
      // If there is a filter property already specified then join the data objects together
      if (query.filter) {
        // Change format from object to array of objects
        if (!Array.isArray(query.filter)) {
          query.filter = [query.filter];
        }

        (query.filter as QueryFilter[]).push({
          field: 'entity',
          operator: '$eq',
          value: entity
        });
      } else {
        // If there is no query filter object then just add one
        query.filter = {
          field: 'entity',
          operator: '$eq',
          value: entity
        };
      }
    }

    return this.get(query);
  }

  /***
   * Fetches the last known table configuration for a given entity type (e.g. 'job', 'survey', 'customer', etc.)
   * from the local storage engine in the browser.
   *
   * The local storage only supports up to 5Mb, and the table configs can compound quickly.
   *
   * NOTE: It is recommended that the stored data structure is simplified (maybe to IDs) and the entire
   * table config data set stored in memory on app load (then just use a filtering algorithm).
   *
   * Otherwise, it's also possible to move the implementation to a different engine (E.g. WebSQL or IndexedDb)
   *
   * @param entity - The domain entity to load the column configuration for
   */
  public loadTableColumnConfigurationFromLocalStorage(entity: string): ILocalStorageTableConfigColumnItem[] {
    const key = `${entity}-table-column-config`;

    return LocalStorageHelper.get<ILocalStorageTableConfigColumnItem[]>(key);
  }

  /***
   * Stores the current table configuration for a given entity type (e.g. 'job', 'survey', 'customer', etc.)
   * in the local storage engine in the browser.
   *
   * The local storage only supports up to 5Mb, and the table configs can compound quickly.
   *
   * NOTE: It is recommended that the stored data structure is simplified (maybe to IDs) and the entire
   * table config data set stored in memory on app load (then just use a filtering algorithm).
   *
   * Otherwise, it's also possible to move the implementation to a different engine (E.g. WebSQL or IndexedDb)
   *
   * @param entity - The domain entity to set the column configuration for
   * @param columns - The FS table column configuration interface collection to store
   */
  public setTableColumnConfigurationInLocalStorage(entity: string, columns: IFsTableColumnConfig[]) {
    const key = `${entity}-table-column-config`;
    // Simplify the data structure TODO: This can be improved further
    const items = columns.map(c => {
      return {
        id: c.id,
        valueHandling: c.valueHandling,
        valueColumn: c.valueColumn,
        heading: c.heading,
        isCustomFieldsColumn: c.isCustomFieldsColumn,
        createdAt: c.createdAt,
        updatedAt: c.updatedAt
      };
    });

    return LocalStorageHelper.set(key, items, true);
  }

  /***
   * Removes a table configuration for a domain entity (e.g. 'job', 'survey', 'customer', etc.).
   *
   * The local storage only supports up to 5Mb, and the table configs can compound quickly.
   *
   * NOTE: It is recommended that the stored data structure is simplified (maybe to IDs) and the entire
   * table config data set stored in memory on app load (then just use a filtering algorithm).
   *
   * Otherwise, it's also possible to move the implementation to a different engine (E.g. WebSQL or IndexedDb)
   *
   * @param entity - The domain entity to remove the column configuration for
   */
  public clearTableColumnConfigurationFromLocalStorage(entity: string) {
    const key = `${entity}-table-column-config`;

    return LocalStorageHelper.remove(key);
  }

  /***
   * Update/Merge the selected columns to display with the currently displayed columns in the table config.
   *
   * This will consolidate the new configuration and old configuration and update the configuration
   * in the local storage engine.
   *
   * @param config - The FS table config to consolidate and update
   * @param paging - The FS paging object to apply the filters to
   * @param entity - The domain entity to update the local storage configuration for
   */
  public confirmTableColumnSelection(config: IFsTableConfig, paging: IFsPaging, entity: string) {
    const availableColumns = config.availableColumns.getValue();
    const currentConfig = config.columns.getValue();

    availableColumns.forEach(c => {
      // We currently use the valueColumn and heading to lookup unique values, but TODO: this can be improved.
      const existingIndex = currentConfig.findIndex(e => {
        if (e.valueColumn) {
          return e.valueColumn === c.valueColumn && e.heading === c.heading;
        }

        return e.heading === c.heading;
      });

      // Consolidate the existing and new table column selection
      if (c._selected && existingIndex < 0) {
        currentConfig.push(c);
      } else if (!c._selected && existingIndex > -1) {
        // We currently use the valueColumn and heading to lookup unique values, but TODO: this can be improved.
        const existingPagingFilterIndex = paging.filter.findIndex(f => f.key === c.valueColumn && f.meta === c.heading);

        if (existingPagingFilterIndex) {
          paging.filter.splice(existingPagingFilterIndex, 1);
        }

        currentConfig.splice(existingIndex, 1);
      }
    });

    // Sort it in-place by column order
    currentConfig.sort((a, b) => sortByValue(a.order, b.order));

    // Notify subscribers
    config.columns.next(currentConfig);

    // Update the table configuration in local storage
    this.setTableColumnConfigurationInLocalStorage(entity, currentConfig);
  }

  /***
   * Get the @nestjs/crud-request query join array from the FS Table Configuration interface.
   *
   * @param config - The FS Table Configuration to fetch the table joins from
   */
  public getColumnJoins(config: IFsTableConfig): { field: string }[] {
    const columns = config.columns.getValue();
    const columnsWithJoins = columns.filter(c => c.joinEntities && c.joinEntities.length);
    let joins = [];

    // Only process columns with joinEntities values set
    columnsWithJoins.forEach(c => {
      joins = [
        ...joins,
        ...c.joinEntities.filter((elem) => {
          return joins.indexOf(elem) < 0;
        })
      ];
    });

    // Sort in-place
    joins.sort((a, b) => sortByValue(a.length, b.length));

    // Map the data structure back
    return joins.map(e => ({field: e}));
  }

  /***
   * This is used mainly as a patch to re-align any outdated or broken table configuration data
   * in local storage.
   *
   * The local storage only supports up to 5Mb, and the table configs can compound quickly.
   *
   * NOTE: It is recommended that the stored data structure is simplified (maybe to IDs) and the entire
   * table config data set stored in memory on app load (then just use a filtering algorithm).
   *
   * Otherwise, it's also possible to move the implementation to a different engine (E.g. WebSQL or IndexedDb)
   *
   * @param config - The configuration to use for re-alignment
   * @param entity - The domain entity to set the column configuration for
   * @param columns - The FS table column configuration interface collection to store
   */
  public setCachedTableConfigurationColumns(config: IFsTableConfig, entity: string, columns: IFsTableColumnConfig[]) {
    // Load all available columns for selection
    config.availableColumns.next(columns);

    // Load either the stored columns config, or default to default columns
    const storedColumnsConfig = this.loadTableColumnConfigurationFromLocalStorage(entity) || [];
    const columnsToLoad = columns.filter(c => {
      return this.getMatchingColumnIndex(storedColumnsConfig, c) > -1;
    });
    const columnsUsingOldTableConfigStructure = storedColumnsConfig.filter(c => !c.id || !c.updatedAt);

    // Invalid/No columns stored in localStorage (reload default columns)
    if (!storedColumnsConfig || !columnsToLoad.length || columnsUsingOldTableConfigStructure.length > 0) {
      this.applyColumnsAndSetConfigInLocalStorage(config, entity, columns);
    } else {
      this.applyColumnsAndSetConfigInLocalStorage(config, entity, columnsToLoad, false);
    }

    // Update the selected values
    this.updateSelectedAvailableColumns(config);
  }

  /***
   * This is used with the patch method above to apply the re-aligned columns to the table configuration and
   * apply the re-aligned table configuration in local storage.
   *
   * @param config - The configuration to use for re-alignment
   * @param entity - The domain entity to apply the column configuration for
   * @param columns - The FS table column configuration interface collection to apply
   * @param loadDefaultColumns - Whether to apply the default columns or not (where the column setting displayByDefault is true)
   * @private
   */
  private applyColumnsAndSetConfigInLocalStorage(
    config: IFsTableConfig,
    entity: string,
    columns: IFsTableColumnConfig[],
    loadDefaultColumns: boolean = true
  ) {
    const initialDefaultColumns = columns
      .filter(c => !!c.displayByDefault);
    const columnsToLoad = (loadDefaultColumns ? initialDefaultColumns : columns)
      .sort((a, b) => sortByValue(a.order, b.order));

    if (!columnsToLoad.length) {
      throw new Error('Could not find any default columns to load for the initial table column configuration.');
    }

    config.columns.next(columnsToLoad);

    // Just in case
    this.clearTableColumnConfigurationFromLocalStorage(entity);
    // Apply new configuration
    this.setTableColumnConfigurationInLocalStorage(entity, columnsToLoad);
  }

  private updateSelectedAvailableColumns(config: IFsTableConfig) {
    const availableColumns = config.availableColumns.getValue();
    const selectedColumns = config.columns.getValue();

    availableColumns.forEach(c => {
      c._selected = this.getMatchingColumnIndex(selectedColumns, c) > -1;
    });
  }

  private getMatchingColumnIndex(
    columns: IFsTableColumnConfig[] | ILocalStorageTableConfigColumnItem[] = [],
    targetColumn: IFsTableColumnConfig | ILocalStorageTableConfigColumnItem
  ) {
    if (!columns) {
      return -1;
    }

    return columns.findIndex(col => {
      if (col.id && targetColumn.id) {
        return col.id === targetColumn.id;
      }

      if (col.valueColumn) {
        return col.valueColumn === targetColumn.valueColumn && col.heading === targetColumn.heading;
      }

      return col.heading === targetColumn.heading;
    });
  }
}
