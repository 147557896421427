export class StateManagedSection implements IStateManagedSection {
  public completion: number = 0;

  public constructor(
    public readonly sectionId: string,
    public readonly sectionType: SectionType,
    public readonly sectionTitle: string,
    public readonly order: number,
    public questions: IStateManagedQuestion[],
    public readonly itemId?: string,
    public readonly unitId?: string
  ) {
  }

  public get isComplete() {
    return this.completion >= 1;
  }
}
