import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITransferSummaryState} from './transfer-summary-state.interface';
import {TransferSummaryStateService} from './transfer-summary-state.service';

@Component({
  selector: 'app-transfer-summary',
  templateUrl: './transfer-summary.component.html',
  styleUrls: ['./transfer-summary.component.scss']
})
export class TransferSummaryComponent implements OnInit {
  @Input()
  public managedSection: IStateManagedSection = null;

  @Input()
  public readonlyMode: boolean = false;

  @Input()
  public enableTransactionsButtons: boolean = true;

  @Output()
  public questionAnswered: EventEmitter<any> = new EventEmitter<any>();

  public state: ITransferSummaryState;

  constructor(private stateService: TransferSummaryStateService) {
    this.stateService
      .listener
      .subscribe((state: ITransferSummaryState) => this.state = state);
  }

  public get transactionsTitle(): string {
    return `Transactions for ${this.state.activeTransactionItem?.itemUnit.item?.name}`;
  }

  ngOnInit(): void {
  }

  showTransactionsForItem(item: ITransferSummaryItem) {
    if (item) {
      this.stateService.updateState({
        showTransactions: true,
        activeTransactionItem: item
      });
    }
  }

  dismissModal() {
    this.stateService.updateState({
      showTransactions: false,
      activeTransactionItem: null
    });
  }
}
