export const environment = {
  imageFileTypes: [
    '.bmp',
    '.gif',
    '.jpeg',
    '.jpg',
    '.png',
    '.svg',
    '.tif',
    '.tiff',
    '.ico',
    '.webp',
  ],
  audioFileTypes: [
    '.aac',
    '.mp3',
    '.weba',
  ],
  videoFileTypes: [
    '.avi',
    '.mp4',
    '.mpeg',
    '.ogv',
    '.webm',
  ],
  documentFileTypes: [
    '.csv',
    '.doc',
    '.docx',
    '.json',
    '.odp',
    '.ods',
    '.odt',
    '.pdf',
    '.ppt',
    '.pptx',
    '.txt',
    '.xls',
    '.xlsx',
    '.xml',
  ],
  archiveFileTypes: [
    '.7z',
    '.rar',
    '.zip',
  ],
  azureB2CAuthorizationEndpoint: 'https://4iotclients.b2clogin.com/4iotclients.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_signup_signin_ardillas_custom_DEV',
  azureB2CClientId: '57936b69-b1fc-4d34-8fd4-eea616b4abdd',
  azureB2CDiscoveryDocument: 'https://4iotclients.b2clogin.com/4iotclients.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signin_ardillas_custom_DEV',
  azureB2CFieldServicesAPIScope: 'https://4iotclients.onmicrosoft.com/57936b69-b1fc-4d34-8fd4-eea616b4abdd/field-services-api',
  azureB2CIssuer: 'https://4iotclients.b2clogin.com/19c6ac6f-393b-4119-a40f-b632a8f03dec/v2.0/',
  azureB2CRedirectUri: 'https://dev-app.atajo.io',
  azureB2CTokenEndpoint: 'https://4iotclients.b2clogin.com/4iotclients.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1A_signup_signin_ardillas_custom_DEV',
  fieldServicesAPI: 'https://dev-app-api.atajo.io',
  fieldServicesWebBaseUrl: 'https://dev-app.atajo.io',
  production: true,
  sentryDsn: 'https://0dd71ca0e30b4c279dd3ac042df19ac6@o430286.ingest.sentry.io/5378869',
  useSentry: true,
  sentryEnvironment: 'dev',
  firebase: {
    apiKey: 'AIzaSyD4vW232ZFf7abYHzBthpxEXAMZmq7pC1Q',
    authDomain: 'field-services-mobile-e0dfe.firebaseapp.com',
    databaseURL: 'https://field-services-mobile-e0dfe.firebaseio.com',
    projectId: 'field-services-mobile-e0dfe',
    storageBucket: 'field-services-mobile-e0dfe.appspot.com',
    messagingSenderId: '938145642774',
    appId: '1:938145642774:web:dbb7b52298ebe5a8a77837',
    measurementId: 'G-2D7FT4G9LT'
  },
  paymentModuleEnabled: true,
  webhooksModuleEnabled: true,
  checklistQuestionTypeEnabled: true,
};
