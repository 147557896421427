import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { InjectorHelper } from 'src/app/shared/helpers/injector.helper';
import { environment } from 'src/environments/environment';
import { AuthenticationStateService } from '../authentication/authentication-state.service';

export abstract class BaseService {

    protected url: string;
    protected message: NzMessageService;
    protected router: Router;
    private authenticationStateService: AuthenticationStateService;

    constructor(public http: HttpClient, endpoint: string) {
        this.url = `${environment.fieldServicesAPI}/${endpoint}`;
        this.message = InjectorHelper.injector.get(NzMessageService);
        this.router = InjectorHelper.injector.get(Router);
        this.authenticationStateService = InjectorHelper.injector.get(AuthenticationStateService);
    }

    protected execute(request: HttpRequest<any>) {
        return this.http.request(request)
            .pipe(
                filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
                take(1),
                map((res: any) => res.body),
                catchError(err => {
                    if (err.status === 401) {
                        this.authenticationStateService.updateState({unauthorizedState: true});
                        return EMPTY;
                    } else {
                        return this.handleError(err);
                    }
                })
            );
    }

    protected handleError(err: HttpErrorResponse): Observable<HttpErrorResponse> {
        const message = (err.error && err.error.message)
          || 'Something went wrong';

        console.error(err);

        this.message.error(message, {nzDuration: 10000});
        return throwError(err);
    }

    protected urlWithId(id: string): string {
        return `${this.url}/${id}`;
    }

    protected parseQueryParameters(
        params?: CreateQueryParams,
        extraParams?: ISimpleParamWrapper,
    ): string {
        if (!params && !extraParams) {
            return '';
        }

        let queryString = '?';
        if (params) {
          const queryBuilder: RequestQueryBuilder = RequestQueryBuilder.create(params);

          if (params.page && params.limit) {
            queryBuilder
              .setPage(params.page)
              .setLimit(params.limit);
          }

          queryString += queryBuilder.query();
        }
        if (extraParams) {
            if (queryString.length > 1) {
                queryString += '&';
            }
            const httpParams = new HttpParams({ fromObject: extraParams });
            queryString += httpParams.toString();
        }
        return queryString;
    }

}

export abstract class BaseCrudService<T extends BaseDAO> extends BaseService {

    constructor(public http: HttpClient, endpoint: string) {
        super(http, endpoint);
    }

    public get(params?: CreateQueryParams, extraParams?: ISimpleParamWrapper): Observable<IPagination<T>> {
        return this.execute(new HttpRequest('GET', this.url + this.parseQueryParameters(params, extraParams)));
    }

    public getOne(id: string, params?: CreateQueryParams, extraParams?: ISimpleParamWrapper): Observable<T> {
        return this.execute(new HttpRequest('GET', this.urlWithId(id) + this.parseQueryParameters(params, extraParams)));
    }

    public put(body: Partial<T>, params?: CreateQueryParams): Observable<T> {
        return this.execute(new HttpRequest('PUT', this.urlWithId(body.id) + this.parseQueryParameters(params), body));
    }

    public patch(body: Partial<T>, params?: CreateQueryParams): Observable<T> {
        return this.execute(new HttpRequest('PATCH', this.urlWithId(body.id) + this.parseQueryParameters(params), body));
    }

    public post(body: T, params?: CreateQueryParams): Observable<T> {
        return this.execute(new HttpRequest('POST', this.url + this.parseQueryParameters(params), body));
    }

    public delete(body: T, params?: CreateQueryParams): Observable<T> {
        return this.execute(new HttpRequest('DELETE', this.urlWithId(body.id) + this.parseQueryParameters(params), body));
    }
}
