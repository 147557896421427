import {TransferSummaryTransaction} from './transfer-summary-transaction.model';

export class TransferSummaryItem implements ITransferSummaryItem {
  countedQuantity: number;
  itemUnit: IItemUnit;
  transactions: ITransferSummaryTransaction[];
  itemName: string;
  unitName: string;

  constructor(
    countedQuantity: number = 0,
    itemUnit: IItemUnit = null,
    itemName: string,
    unitName: string,
    transactions: ITransferSummaryTransaction[] = []
  ) {
    this.countedQuantity = countedQuantity;
    this.itemUnit = itemUnit;
    this.transactions = transactions;
    this.itemName = itemName;
    this.unitName = unitName;
  }

  addTransferSummaryTransaction(
    questionAnswerId: string,
    quantity: number,
    location: ILocation,
    createdAt: Date
  ): ITransferSummaryTransaction {
    const transaction = new TransferSummaryTransaction(questionAnswerId, quantity, location, createdAt);

    this.transactions.push(transaction);

    return this.transactions[this.transactions.indexOf(transaction)];
  }
}
