import {Component, Injector, OnInit} from '@angular/core';
import {AppStateService} from './app-state.service';
import {AuthenticationService} from './core/authentication/authentication.service';
import {InjectorHelper} from './shared/helpers/injector.helper';
import {TenantStateService} from './shared/tenant-selector/tenant-state.service';
import {TenantService} from './shared/tenant-selector/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isPublicRoute = window.location.pathname.startsWith('/public');

  constructor(
    private injector: Injector,
    private tenantStorageService: TenantStateService,
    public appStateService: AppStateService,
    public authenticationService: AuthenticationService,
    public tenantService: TenantService,
  ) {
    InjectorHelper.injector = this.injector;
  }

  public ngOnInit() {
    this.tenantService.tryInitializingDefaultTenant()
      .subscribe(async () => {
        this.setTenantLogo(this.tenantStorageService.tenantValue);

        this.tenantStorageService.tenantAsObservable
          .subscribe((newTenant: ITenant) => {
            this.setTenantLogo(newTenant);
          });
      });
  }

  private setTenantLogo(tenant: ITenant) {
    if (tenant) {
      this.appStateService.setLogo(tenant.logoAttachment);
    }
  }
}
